import React, { Component, } from 'react';
import { Link, Redirect } from "react-router-dom";
import axios from 'axios';
import Menu from '../Menu/Menu';
import { MDBBadge } from 'mdbreact';
import './PPIS.scss';
import TopPPIS from '../TopBars/TopPPIS';
import TopPPISOld from '../TopBars/TopPPISOld';
import { Modal } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
    faExclamationTriangle,
} from '@fortawesome/free-solid-svg-icons';
import $ from 'jquery';
import { Datatable } from './Datatable.net';


class PPIS extends Component {
    _isMounted = false;
    constructor(props) {
        super(props);
        this.api = window.$domain;
        this.data = {};
        this.state = {
            user: '',
            avatar: '',
            color_aleatorio: '',
            data: false,
            url: '',
            userRole: 0,
            redirect: false,
            status: null,
            showModal: false,
            title: '',
            message: '',
            datatable: {}
        };
    }
    onChangehandler = (e) => {
        let name = e.target.name;
        let value = e.target.value;
        let data = {};
        data[name] = value;
        this.setState(data);
    };

    handleCheckboxChange = (e) => {

    }

    reactivate = (e) => {
        axios.put(`${this.api}/ppi/reactivate/` + e)
            .then((response) => {
                if (response.data.result.toLowerCase() === 'success') {
                    window.location.reload();
                }
            }).catch((err) => {
                console.error(err)
            });
    }

    handleClick = (datos, status) => {
        var element = datos[11];

        if (element === undefined) {
            element = datos[10];
        }

        let path;
        let user = JSON.parse(localStorage.getItem('user'));
        console.log("element.fechaInicio", element.fechaInicio);
        let bandera = element.fechaInicio > "2024-02-26" ? false : true;


        // if((element.pk_status === 1 || element.pk_status === 3) && user.fk_role === 1){
        if ((element.pk_status === 1 || element.pk_status === 3 || element.pk_status === 10 || element.pk_status === 9)) {
            path = `/ppi/${element.folio}`;
        }
        // if(element.pk_status === 2 && user.fk_role === 2){
        if (element.pk_status === 2) {
            path = `/ppi/edit-solicitude/${element.folio}`;
        }
        // if(element.pk_status === 4 && user.fk_role === 2){
        if (element.pk_status === 4) {
            path = `/ppi/choose-type/${element.folio}`;
        }
        // if(element.pk_status === 5 && user.fk_role === 2){

        //Formato anterior
        if (element.pk_status === 5 && bandera == true || (user.fk_role === 1 && bandera == true && element.pk_status === 6 && bandera == true)) {
            console.log("bandera", element);
            path = `/ppi/format/${element.fk_format}/${element.folio}`;
        }

        //Formato nuevo homologación

        if (element.pk_status === 5 && bandera == false || (user.fk_role === 1 && bandera == false && element.pk_status === 6 && bandera == false)) {
            console.log("bandera", element);
            path = `/ppi/format/Homologacion/${element.folio}`;
        }
        if (element.pk_status === 6 && user.fk_role === 2) {

            this.setState({
                showModal: true,
                title: 'Acceso no disponible temporalmente',
                message: 'El PPI se encuentra en Revisión, por lo que por el momento no está disponible, una vez que el administrador responsable haya concluido con la revisión estará disponible para su acceso nuevamente.'
            });
        }

        if (element.pk_status === 8 && bandera == false) {
            path = `/ppi/approved_format/Homologacion_Aprobados/${element.folio}`;
            console.log("bandera", bandera);

        }

        if (element.pk_status === 8 && bandera) {
            path = `/ppi/approved_format/${element.fk_format}/${element.folio}`;
            console.log("bandera", element);

        }


        if (element.pk_status === 13 && user.fk_role === 2) {
            this.setState({
                showModal: true,
                title: 'Acceso no disponible temporalmente',
                message: 'El PPI se encuentra Cancelado por clave cartera, por lo que por el momento no está disponible.'
            });
        } else if (element.pk_status === 13 && user.fk_role === 1) {

            path = `/ppi/approved_format/Homologacion_Aprobados/${element.folio}`;

        }
        if (element.pk_status === 11) {
            path = `/ppi/choose-type/${element.folio}`;
        }
        if (element.pk_status === 7 || element.pk_status === 9 || element.pk_status === 10) {
            this.setState({
                showModal: true,
                title: 'Acceso no disponible',
                message: 'El PPI se encuentra en estado de No Aprobado, Cancelado o Eliminado, por lo que por el acceso a este PPI ya no está disponible.'
            });
            return false;
        }

        if (path === undefined) {
            return false;
        }

        // Quitar validación en producción

        if (element.approved === null) {
            status = true;
        }

        this.setState({
            url: path,
            redirect: true,
            status: status,
            userRole: user.fk_role
        });
    }

    linkOficio = (dates, status) => {
        var element = dates[11];

        if (element === undefined) {
            element = dates[10];
        }

        let path;
        let user = JSON.parse(localStorage.getItem('user'));

        // if (element.pk_status === 8) {


            path = `/oficios/ppi/${element.folio}`;
 
        if (path === undefined) {
            return false;
        }

        // Quitar validación en producción

        if (element.approved === null) {
            status = true;
        }

        this.setState({
            url: path,
            redirect: true,
            status: status,
            userRole: user.fk_role
        });
    }

    handleClose = () => {
        this.setState({
            showModal: false,
            title: '',
            message: ''

        });
    }

    componentDidMount() {
        this._isMounted = true;
        axios.get(`${this.api}/ppis`).then((response) => {
            if (response.data.length > 0) {
                this.setState({
                    data: true
                });
            }

            let data = [];
            response.data.forEach(element => {
                let tmp = {
                    check: '',
                    folio: element.folio,
                    name: element.name,
                    evaluation: element.evaluation !== null ? element.evaluation : 'N/D',
                    amount: element.amount,
                    dependency: element.dependency,
                    created_at: element.created_at,
                    fechaInicio: element.fechaInicio,
                    status: element.status,
                    executor: element.executor != null && element.executor.length > 0 ? element.executor : 'N/D',
                    comments: element.comments != null && element.comments > 0 ? <MDBBadge color={'warning-custom'} className="comments"><div className="icon material-icons text-grey">question_answer</div></MDBBadge> : '',
                    element: element,
                    color: element.badge,
                    button: '',
                    //clave: element.clave,
                };
                console.log('TTPM', tmp);
                data.push(tmp)
            });
            this.data = data;
            this.setState({
                datatable: data
            });
            $(".checkbox-round").on('click', () => {
            });
        });
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    render() {
        const login = localStorage.getItem("isLoggedIn");
        if (!login) {
            return <Redirect to="/" />;
        }

        if (this.state.status !== null && this.state.redirect) {
            return <Redirect to={{
                pathname: this.state.url,
                state: { approval: true, userRole: this.state.userRole }
            }} />
        } else if (this.state.redirect) {
            return <Redirect to={this.state.url} />
        }


        const user = JSON.parse(localStorage.getItem('user'));

        let user_name = user.name;
        let bandera = false;
        let view;
        if (!this.state.data) {
            view = (
                <>
                    {bandera && (
                        <div className="data-content">
                            <TopPPIS />
                            <div className="center">
                                <div className="bg-white border rounded window-block">
                                    <div className="py-4 m-4">
                                        <div className="size-24 bold mb-4 mt-4">{user_name}</div>
                                        {
                                            user.fk_role === 1 ?
                                                <div className="size-16 mt-4 pb-4">No han solicitado ningún proyecto aún. <br />Espera a que algún usuario solicitante genere un PPI.</div>
                                                :
                                                <div className="size-16 mt-4 pb-4">No has solicitado ningún proyecto aún. <br />Llena una solicitud pulsando en nuevo proyecto.</div>
                                        }
                                        {
                                            user.fk_role !== 1 &&
                                            <Link to="/ppis/nuevo">
                                                <div className="btn btn-primary h-30">
                                                    <span className="size-12">+</span> Nuevo PPI
                                                </div>
                                            </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}

                    {!bandera && (
                        <div className="data-content">
                            <TopPPISOld />
                            <div className="center">
                                <div className="bg-white border rounded window-block">
                                    <div className="py-4 m-4">
                                        <div className="size-24 bold mb-4 mt-4">{user_name}</div>
                                        {
                                            user.fk_role === 1 ?
                                                <div className="size-16 mt-4 pb-4">No han solicitado ningún proyecto aún. <br />Espera a que algún usuario solicitante genere un PPI.</div>
                                                :
                                                <div className="size-16 mt-4 pb-4">No has solicitado ningún proyecto aún. <br />Llena una solicitud pulsando en nuevo proyecto.</div>
                                        }
                                        {
                                            user.fk_role !== 1 &&
                                            <Link to="/ppis/nuevo">
                                                <div className="btn btn-primary h-30">
                                                    <span className="size-12">+</span> Nuevo PPI
                                                </div>
                                            </Link>
                                        }
                                    </div>
                                </div>
                            </div>
                        </div>
                    )}
                </>


            );
        } else {
            if (this.state.datatable.length > 0) {
                view = (

                    <>
                        {bandera && (
                            <div className="data-content">
                                <TopPPIS table={true} />
                                <div id="datatable">
                                    <Datatable data={this.state.datatable} handleClick={this.handleClick} linkOficio={this.linkOficio} handleCheckboxChange={this.handleCheckboxChange} Reactivate={this.reactivate}></Datatable>
                                </div>
                                {/* <MDBDataTableV5 hover responsive data={this.state.datatable} filter='name' searchTop entrieslabel={'Registros por página'} /> */}

                            </div>
                        )}

                        {bandera == false && (
                            <div className="data-content">
                                <TopPPISOld table={true} />
                                <div id="datatable">
                                    <Datatable data={this.state.datatable} handleClick={this.handleClick} linkOficio={this.linkOficio} handleCheckboxChange={this.handleCheckboxChange} Reactivate={this.reactivate}></Datatable>
                                </div>
                                {/* <MDBDataTableV5 hover responsive data={this.state.datatable} filter='name' searchTop entrieslabel={'Registros por página'} /> */}

                            </div>
                        )}
                    </>

                );
            }
        }

        return (
            <div className="main-wrap PPIS">
                <Menu />
                <div className="wrapper col col-10">
                    {view}

                    <Modal show={this.state.showModal} onHide={this.handleClose}>
                        <Modal.Body>
                            <FontAwesomeIcon style={{ 'color': '#E79B00' }} icon={faExclamationTriangle} size="2x" />
                            <div className="size-24 bold pb-2">{this.state.title}</div>
                            <p className="size-16 pb-4 col col-10 offset-1">{this.state.message}</p>

                            <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                                <div className="d-block btn btn-small btn-primary h-30 col col-12" onClick={this.handleClose}>Aceptar</div>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
            </div>
        );
    }

}
export default PPIS;
