import React, { Component } from "react";
import "./assets/NotaTecnica.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperclip,
  faPlusCircle,
  faTimesCircle,
  faDownload,
  faChevronUp,
  faExclamationTriangle,
  faCheck,
  faUndo,
  faCheckCircle,
  faFileAlt,
  faTrashAlt,
  WarningAmber,
} from "@fortawesome/free-solid-svg-icons";
import { FormatMoney } from "format-money-js";
import NumberFormat from "react-number-format";
import $ from "jquery";
import MyComponent from "../Map/MapOnlyRead";
import PlacesWithStandaloneSearchBox from "../Map/SearchBox";
import { Form, Table, Modal, OverlayTrigger, Tooltip } from "react-bootstrap";
import axios from "axios";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import SideBarComments from "../Comments/SideBarComments";

// const mapUrl = `https://maps.googleapis.com/maps/api/js?v=3.exp&key=${credentials.mapsKey}`;
export default class Homologacion extends Component {
  constructor(props) {
    super(props);
    this.sideBarRef = React.createRef();

    this.state = {
      total_IX_amount: 0,
      rowSourcesProyects: [0],
      tipoFormato: [],
      tasa: 0,
      prueba: "",

      VerTablaIndicadorABC: "none",
      VerTablaIndicadorACE: "none",
      fecha: "",

      cost_quantification: [
        {
          year: 0,
          inversion: "",
          operation: "",
          maintenance: "",
          externalities: "",
          total: 0,
          total_actualizado: 0,
        },
      ],
      cost_quantification_alt2: [
        {
          year: 0,
          inversion: "",
          operation: "",
          maintenance: "",
          externalities: "",
          total: 0,
          total_actualizado: 0,
        },
      ],
      benefits_quantification: [
        {
          year: 0,
          benefit_1: "",
          benefit_2: "",
          benefit_3: "",
          benefit_4: "",
          benefit_5: "",
          benefit_6: "",
          externalities: "",
          total: 0,
          total_beneficios: 0,
          total_neto: 0,
          tri: 0,
          blocked: false,
        },
      ],
      cost_quantification_index: 0,
      benefits_quantification_index: 0,
      total_inversion: 0,
      total_operation: 0,
      total_maintenance: 0,
      total_externalitie: 0,
      total_absolute: 0,
      total_absolute_actualizado: 0,
      data_sheet_extra_pre_investments: [
        {
          study_name: "",
          type_of_study: "",
          estimated_date_completion: "",
          second_estimated_date_completion: "",
          justification_realization: "",
          description: "",
          estimated_cost_amount: "",
        },
      ],
      formato: 0,
      adquisiciones: false,
      cantidadDecimales: [
        {
          cantidadInversion: [{ cantidad: [], type: [] }],
          cantidadInversionALT: [{ cantidad: [], type: [] }],
          cantidadOperation: [{ cantidad: [], type: [] }],
          cantidadOperationALT: [{ cantidad: [], type: [] }],
          cantidadMaintenance: [{ cantidad: [], type: [] }],
          cantidadMaintenanceALT: [{ cantidad: [], type: [] }],
          cantidadexternalities: [{ cantidad: [], type: [] }],
          cantidadexternalitiesALT: [{ cantidad: [], type: [] }],
          total_cantidad: [{ cantidad: [], type: [] }],
          total_cantidadALT: [{ cantidad: [], type: [] }],
          total_cantidad2: [{ cantidad: [], type: [] }],
          total_cantidad2ALT: [{ cantidad: [], type: [] }],
        },
      ],

      total_inversion: 0,
      total_operation: 0,
      total_maintenance: 0,
      total_externalitie: 0,
      total_absolute: 0,

      total_inversion_ppi: 0,
      total_operation_ppi: 0,
      total_maintenance_ppi: 0,
      total_externalitie_ppi: 0,
      total_absolute_ppi: 0,

      total_inversion_alt1: 0,
      total_operation_alt1: 0,
      total_maintenance_alt1: 0,
      total_externalitie_alt1: 0,
      total_absolute_alt1: 0,

      total_inversion_alt2: 0,
      total_operation_alt2: 0,
      total_maintenance_alt2: 0,
      total_externalitie_alt2: 0,
      total_absolute_alt2: 0,

      total_physical: 0,
      total_financial: 0,
      total_financial_currency_format: 0,
      vpn: 0,

      tir: 0,
      tri: 0,
      date: "",
      optimal_tri: 0,
      ppi_evaluado: 0,
      alternativa: 0,

      amount: 0,
      place: "",
      applicant: "",
      rowsSources: [0],
      rowsCalendar: [0],
      rowsComponent: [0],
      rowsAlineacion: [0],
      rowsAlternativa: [0],
      rowOptimizationMeasures: [0],
      rowSourceIndex: 0,
      rowCalendarIndex: 0,
      rowComponentIndex: 0,
      limitRowsCalendar: 1,
      limitRowsComponent: 1,
      users: [],
      roles: [],
      cities: [],
      classifications: [],
      dependencies: [],
      funding_sources: [],
      funding_subsources: [],
      measurement_units: [],
      citySelected: [{ city: 0, delete: false }],
      classificationSelected: 0,
      name_ppi: "",
      alternative_justification: "",

      type_ppi: "",
      concepts: [],
      items: [],
      dependency_applicant: "",
      dependency_executor: 0,
      optimization_measures: [
        { nom_alt: "", alternatives: "", totalCostVAT: 0 },
      ],

      founding_sources_origin: [
        {
          origin: 0,
          percentage: "",
          amount: "",
          subsource: 0,
          other: "",
          subsourceFlag: false,
          otherFlag: false,
        },
      ],

      // numero_catastro: 0,
      // catastro: "",
      // tipo_bien: 0,
      // tipo_bien_descripcion:0,
      // otro: "",

      calendar_investment: [{ advance: "", physical: "", financial: "" }],
      calendar_index: 0,
      components: [
        {
          name_concept: "",
          description_concept: "",
          unit_of_measure: 0,
          quantity: "",
          unit_price: "",
          total_amount: "",
          number_concept: 0,
          number_item: 0,
          description_item: "",
        },
      ],
      alineacion_estrategica: [
        {
          plan: "",
          eje: "",
          tema: "",
          objetivo: "",
          estrategia: "",
          linea: "",
        },
      ],
      alternativas_solucion: [
        { alternativa: "", ventajas: "", desventajas: "" },
      ],

      goals: [{ name_concept: "", unit_of_measure: "", quantity: "" }],
      total_physical: 0,
      total_financial: 0,
      total_financial_currency_format: 0,
      relationated_programs: "",
      objectives: "",
      action_lines: "",
      problem_description: "",
      description: "",
      current_situation_offer: "",
      demand_current_situation: "",
      intereaction_current_situation: "",
      general_description: "",
      objective_general: "",
      offer_ppi: "",
      //sal_disabled: '',
      demand_ppi: "",
      interaction_ppi: "",
      execution_result: "",
      beneficiaries: 0,
      vida_util: 0,
      executive_project: 0,
      executive_project_description: "",
      permissions: 0,
      permissions_description: "",
      enviromental_studies: 0,
      enviromental_studies_description: "",
      property_rights: 0,
      property_rights_description: "",
      numero_catastro: 0,
      showNumeroCatastro: false,
      catastro: "",
      tipo_bien: 0,
      tipo_bien_descripcion: 0,
      otro: "",
      final_comments: "",
      name_applicant: "",
      applicant_title: "",
      applicant_pin: "",
      executor_name: "",
      executor_title: "",
      pictures: "",
      executor_document: "",
      coordinatesData: [],
      observations_location: "",
      PicFile: null,
      pic_file_name: "",
      plans_name: "",
      showModalImagesConfirm: false,
      showModalDeleteImagen: false,
      responsable_document: null,
      responsable_document_name: "",
      attachments: [],
      attachments_names: [],
      archivos: [],
      archivos_nombre: [],
      subtotal_components: 0,
      iva_components: 0,
      total_components: 0,
      total_percentage_funding: 0,
      total_amount_funding: 0,
      permission_admin: true,
      showImagesFile: false,
      imagesFile: null,
      showOficioFile: false,
      showPlanFile: false,
      showCatastro: false,
      showOtro: false,
      showPropio: false,
      planFile: null,
      oficioFile: null,
      showAttachment: false,
      attachment: null,
      scroll: true,
      showModal: false,
      showModalConfirm: false,
      ShowModalArchivoConfirm: false,
      showModalDeleteImage: false,

      ShowModalOficioConfirm: false,
      ShowModalPlansConfirm: false,

      selectedFile: "",
      selectedArchivo: "",
      titleModal: "",
      messageModal: "",
      updaterState: "",
      puesto: "",
      asp_relevantlegal: "",
      clicked: false,
      showModalApproveFormat: false,

      comments_sections: {
        general_information_approved: "Informaciódel PPI",
        problem_description_approved: "Descripción de la problemática",
        general_description_approved: "Descripción general del PPI",
        objective_approved: "Objetivo",
        exesution_results_approved:
          "Resultados de la ejecución y beneficios económicos y/o sociales",
        feasibilities_approved: "Factibilidades del PPI",
        general_considerations_approved: "Consideraciones Generales",
        attachments_approved: "Documentos adjuntos",
      },

      general_information_approved: false,
      problem_description_approved: false,
      general_description_approved: false,
      objective_approved: false,
      exesution_results_approved: false,
      feasibilities_approved: false,
      general_considerations_approved: false,
      attachments_approved: false,
      project_situation_approved: false,
      situation_wppi_approved: false,

      commentsSWPFlag: false, //Situación con PPI
      commentsSWP: "", //Situación con PPI

      commentsGIPFlag: false, //general_information
      commentsGIP: "", //general_information

      commentsPDPFlag: false, //problem_description
      commentsPDP: "", //problem_description

      commentsGDPFlag: false, //general_description
      commentsGDP: "", //general_description

      commentsObjectiveFlag: false, // Objective
      commentsObjective: "", // Objective

      commentsExecutionResultsFlag: false, // Execution_results
      commentsExecutionResults: "", // Execution_results

      commentsFPFlag: false, //feasibilities
      commentsFP: "", //feasibilities

      commentsGCPFlag: false, //general_considerations
      commentsGCP: "", //general_considerations

      commentsAPFlag: false, //attachments
      commentsAP: "", //attachments

      commentsPSPFlag: false, //project_situation
      commentsPSP: "", //project_situation

      MontoFlag: false,

      asa_offer_analysus: "",
      asa_offer_analysis: "",
      asa_interaction: "",

      formatReadyToApprove: [
        {
          seccion1: false,
          seccion2: false,
          seccion3: false,
          seccion4: false,
          seccion5: false,
          seccion6: false,
          seccion7: false,
          seccion8: false,
          seccion9: false,
        },
      ],
      formatoAprobado_: false,
    };
    this.api = window.$domain;
    this.environment = window.$environment;
    this.addRowSources = this.addRowSources.bind(this);
    this.addRowOptimizationMeasures =
      this.addRowOptimizationMeasures.bind(this);
    this.removeRowOptimizationMeasures =
      this.removeRowOptimizationMeasures.bind(this);

    this.removeRowSources = this.removeRowSources.bind(this);
    this.addRowCalendar = this.addRowCalendar.bind(this);
    this.removeRowCalendar = this.removeRowCalendar.bind(this);
    this.addRowComponent = this.addRowComponent.bind(this);
    this.removeRowComponent = this.removeRowComponent.bind(this);
    this.addRowComponentAdq = this.addRowComponentAdq.bind(this);
    this.removeRowComponentAdq = this.removeRowComponentAdq.bind(this);
    this.addRowGoal = this.addRowGoal.bind(this);
    this.removeRowGoal = this.removeRowGoal.bind(this);
    this.forceUpdateHandler = this.forceUpdateHandler.bind(this);
    this.handleDeleteAttachmentsClick =
      this.handleDeleteAttachmentsClick.bind(this);
    this.handleDeleteArchivoClick = this.handleDeleteArchivoClick.bind(this);
    this.handleDeletePicFile = this.handleDeletePicFile.bind(this);
    this.handleDeleteOficio = this.handleDeleteOficio.bind(this);

    this.handleDeletePlansClick = this.handleDeletePlansClick.bind(this);
    this.addRowExtraComp = this.addRowExtraComp.bind(this);

    this.addRowCity = this.addRowCity.bind(this);
    this.removeRowCity = this.removeRowCity.bind(this);
    this.addRowAlineacion = this.addRowAlineacion.bind(this);
    this.removeRowAlineacion = this.removeRowAlineacion.bind(this);
    this.addRowAlternativa = this.addRowAlternativa.bind(this);
    this.removeRowAlternativa = this.removeRowAlternativa.bind(this);

    this.addRowCostQuantification = this.addRowCostQuantification.bind(this);
    this.removeRowCostQuantification =
      this.removeRowCostQuantification.bind(this);
    this.addRowCostQuantificationAlt2 =
      this.addRowCostQuantificationAlt2.bind(this);
    this.removeRowCostQuantificationAlt2 =
      this.removeRowCostQuantificationAlt2.bind(this);
    this.addRowBenefitQuantification =
      this.addRowBenefitQuantification.bind(this);
    this.removeRowBenefitQuantification =
      this.removeRowBenefitQuantification.bind(this);
  }

  handleShowFormat = () => {
    this.setState({
      showModalApproveFormat: true,
    });
  };

  rerenderComponent() {
    this.forceUpdate(); // Esto forzará una nueva renderización del componente
  }

  async loadTipoFormato(url) {
    const { formato_PPI, id } = this.props;
    let Nombre = "";
    let adquisiciones = this.props.adquisiciones;
    let VerTablaIndicadorABC = "";
    let VerTablaIndicadorACE = "";

    if (this.state.formato === 1 || this.state.formato === 2) {
      Nombre = "Cedula";
    }
    if (this.state.formato === 2) {
      Nombre = "Cedula";
      adquisiciones = true;
    }
    if (this.state.formato === 3) {
      Nombre = "Nota Técnica";
    }
    if (this.state.formato === 4) {
      Nombre = "Nota Tecnica Adquisiciones";
      adquisiciones = true;
    }
    if (this.state.formato === 5) {
      Nombre = "Ficha Técnica";
    }
    if (
      this.state.formato === 5 &&
      Number(this.props.monto) >= 30000000 &&
      this.props.tipo_ppi === 2
    ) {
      Nombre = " Ficha Técnica Indicadores";
      VerTablaIndicadorABC = "";
      VerTablaIndicadorACE = "none";
    }

    if (this.state.formato === 7) Nombre = " Ficha Técnica Pre-inversión";
    adquisiciones = false;

    if (this.state.formato === 6) {
      Nombre = "Ficha Tecnica";
      adquisiciones = true;
    }
    if (this.state.formato === 8) {
      Nombre = "ACB";
      VerTablaIndicadorABC = "";
      VerTablaIndicadorACE = "none";
    }
    if (this.state.formato === 9) {
      Nombre = "ACB";
      adquisiciones = true;
      VerTablaIndicadorABC = "";
      VerTablaIndicadorACE = "none";
    }
    if (this.state.formato === 10) {
      Nombre = "ACE";
      VerTablaIndicadorABC = "none";
      VerTablaIndicadorACE = "";
    }
    if (this.state.formato === 11) {
      Nombre = "ACE";
      adquisiciones = true;
      VerTablaIndicadorABC = "none";
      VerTablaIndicadorACE = "";
    }

    this.setState({
      adquisiciones: adquisiciones,
      VerTablaIndicadorABC: VerTablaIndicadorABC,
      VerTablaIndicadorACE: VerTablaIndicadorACE,
    });

    const path = `${url}/ppi/preview_format/${Nombre}`;

    try {
      const response = await axios.get(path, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      });

      const jsonArray = JSON.parse(response.data[0].id_sesion);
      const array = Object.values(jsonArray);

      return array;
    } catch (error) {
      console.error(error);
      return [];
    }
  }

  forceUpdateHandler(subtotal) {
    const fm = new FormatMoney({
      decimals: 2,
    });
    this.setState({
      subtotal_components: fm.from(subtotal),
      iva_components: fm.from(subtotal * 0.16),
      total_components: fm.from(subtotal * 1.16),
    });
    this.forceUpdate();
  }

  handleDeleteAttachmentsClick = (file) => {
    this.setState({
      showModalConfirm: true,
      selectedFile: file,
    });
  };

  handleDeleteArchivoClick = (file) => {
    this.setState({
      ShowModalArchivoConfirm: true,
      selectedArchivo: file,
    });
  };

  handleDeletePlansClick = () => {
    this.setState({
      ShowModalPlansConfirm: true,
    });
  };

  handleDeleteOficio = () => {
    this.setState({
      ShowModalOficioConfirm: true,
    });
  };

  handleDeleteImagesClick = (image) => {
    this.setState({
      showModalImagesConfirm: true,
    });
  };

  handleDeletePicFile = () => {
    let id = this.props.id;
    var imagen = this.state.pic_file_name;

    if (id !== undefined) {
      axios
        .delete(`${this.api}/ppi/images_archivo/${id}`)
        .then((response) => {
          this.setState({
            showModalDeleteImagen: true,
            showModalDeleteImage: false,
            ...(this.state.pic_file_name = null),
          });
        })
        .catch((error) => {
          console.error(error);
        });
      this.setState({
        imagen,
        showModalImagesConfirm: false,
      });
    }
  };

  ConfirmDeleteOficio = () => {
    let id = this.props.id;
    var oficio = this.state.responsable_document_name;

    if (id !== undefined) {
      axios
        .delete(`${this.api}/ppi/oficio/${id}`)
        .then((response) => {
          this.setState({
            showModalDeleteImagen: true,
            showModalDeleteImage: false,
            ...(this.state.responsable_document_name = null),
          });
        })
        .catch((error) => {
          console.error(error);
        });
      this.setState({
        oficio,

        ShowModalOficioConfirm: false,
      });
    }
  };
  ConfirmDeletePlans = () => {
    let id = this.props.id;
    var plans = this.state.plans_name;

    if (id !== undefined) {
      axios
        .delete(`${this.api}/ppi/plans/${id}`)
        .then((response) => {
          this.setState({
            showModalDeleteImagen: true,
            showModalDeleteImage: false,
            ...(this.state.plans_name = null),
          });
        })
        .catch((error) => {
          console.error(error);
        });
      this.setState({
        plans,

        ShowModalPlansConfirm: false,
      });
    }
  };

  ConfirmDeleteAttachment = () => {
    let id = this.props.id;
    var file = this.state.selectedFile;
    if (file.id !== undefined) {
      axios
        .delete(`${this.api}/ppi/attachments/${id}/${file.id}`)
        .then((response) => {
          this.setState({
            showModalDeleteImage: true,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }
    var attachments_names = [...this.state.attachments_names];
    const findRealIdx = (element) => element === file;
    var realIndex = attachments_names.findIndex(findRealIdx);
    attachments_names.splice(realIndex, 1);
    this.setState({
      attachments_names,
      showModalConfirm: false,
      selectedFile: "",
    });
  };
  onTrigger = (event) => {
    this.setState(
      {
        updaterState: "",
      },
      () => {
        this.forceUpdate();
        this.props.parentCallback(this.state);
      }
    );
  };

  ConfirmDeleteArchivo = () => {
    let id = this.props.id;
    var file = this.state.selectedArchivo;
    if (file.id !== undefined) {
      axios
        .delete(`${this.api}/ppi/estudio/${id}/${file.id}`)
        .then((response) => {
          this.setState({
            showModalDeleteImage: true,
          });
        })
        .catch((err) => {
          console.error(err);
        });
    }

    var archivos_nombre = [...this.state.archivos_nombre];
    const findRealIdx = (element) => element === file;
    var realIndex = archivos_nombre.findIndex(findRealIdx);
    archivos_nombre.splice(realIndex, 1);
    this.setState({
      archivos_nombre,
      ShowModalArchivoConfirm: false,
      selectedArchivo: "",
    });
  };
  onTrigger = (event) => {
    this.setState(
      {
        updaterState: "",
      },
      () => {
        this.forceUpdate();
        this.props.parentCallback(this.state);
      }
    );
  };

  componentDidMount = async () => {
    let id = this.props.id;
    var user = JSON.parse(localStorage.getItem("user"));
    let formatoAprobado_ = false;

    if (Number(user.fk_role) !== 1) {
      this.setState({
        permission_admin: false,
      });
    }
    const fm = new FormatMoney({
      decimals: 2,
    });

    if (this.state.scroll) {
      this.scrollToBottom();
    }
    this.scrollToBottom();

    try {
      //await axios.get(`${this.api}/ppi/info/${id}`)
      const response = await axios.get(`${this.api}/ppi/info/${id}`);

      //.then((response) => {
      let data_sheet_extra_pre_investments = [];
      let cities = [];
      let classifications = [];
      let dependencies = [];
      let funding_sources = [];
      let funding_subsources = [];
      let funding_sources_ppi = [];
      let calendar_investment = [];
      let optimization_measures = [];
      let components = [];
      let alternativas_solucion = [];
      let archivos_File = [];
      let goals = [];
      let measurement_units = [];
      let coordinates = [];
      let users = [];
      let roles = [];
      let attachmentsFiles = [];
      let concepts = [];
      let items = [];
      let cities_per_analysis_cost = [];
      let puesto = "";
      let alineacion_estrategica = [];
      let formato = await response.data.info.fk_format;
      let data_sheet_extra_annex_benefits = [];
      let data_sheet_extra_annex_costs = [];
      let costQuantificationAlt2 = [];
      let cantidadDecimales = [
        {
          cantidadInversion: [{ cantidad: [], type: [] }],
          cantidadInversionALT: [{ cantidad: [], type: [] }],
          cantidadOperation: [{ cantidad: [], type: [] }],
          cantidadOperationALT: [{ cantidad: [], type: [] }],
          cantidadMaintenance: [{ cantidad: [], type: [] }],
          cantidadMaintenanceALT: [{ cantidad: [], type: [] }],
          cantidadexternalities: [{ cantidad: [], type: [] }],
          cantidadexternalitiesALT: [{ cantidad: [], type: [] }],
          total_cantidad: [{ cantidad: [], type: [] }],
          total_cantidadALT: [{ cantidad: [], type: [] }],
          total_cantidad2: [{ cantidad: [], type: [] }],
          total_cantidad2ALT: [{ cantidad: [], type: [] }],
        },
      ];
      let formatReadyToApprove = [
        {
          seccion1: false,
          seccion2: false,
          seccion3: false,
          seccion4: false,
          seccion5: false,
          seccion6: false,
        },
      ];
      this.setState({ formato: formato });
      response.data.users.map((us, idx) => {
        users.push({
          name: us.name,
          value: us.pk_user,
          nip: us.pin,
        });
        return true;
      });

      response.data.roles.map((rl, idx) => {
        roles.push({
          name: rl.role_name,
          value: rl.pk_role,
        });
        return true;
      });

      if (response.data.attachments.length > 0) {
        response.data.attachments.map((attachment) => {
          attachmentsFiles.push({
            name: attachment.name,
            id: attachment.pk_attachments,
            size: attachment.file_size,
            path: attachment.file,
          });
          return true;
        });
      }

      if (response.data.estudio_socioeconomico.length > 0) {
        response.data.estudio_socioeconomico.map((archivo) => {
          archivos_File.push({
            name: archivo.name,
            id: archivo.pk_attachments,
            size: archivo.file_size,
            path: archivo.file,
          });
          return true;
        });
      }

      if (response.data.coordinates.length > 0) {
        response.data.coordinates.map((coordinate, idx) => {
          coordinates.push({
            coords: {
              name: coordinate.name,
              address: coordinate.address,
              lat: parseFloat(coordinate.latitude),
              lng: parseFloat(coordinate.longitude),
              comment: coordinate.annotation,
              amount: response.data.info.amount,
              visible: true,
            },
            id: "place_" + coordinate.latitude,
          });
          return true;
        });
        setTimeout(() => {
          this.setState({
            coordinatesData: coordinates,
          });
        }, 300);
      }

      response.data.concepts.map((concept, idx) => {
        concepts.push({
          name: concept.description,
          value: concept.pk_concept,
        });
        return true;
      });
      response.data.items.map((item, idx) => {
        items.push({
          name: item.description,
          value: item.pk_item,
        });
        return true;
      });

      response.data.cities.map((city, idx) => {
        cities.push({
          name: city.name,
          value: city.pk_city,
        });
        return true;
      });

      if (
        response.data.cost_quantification_alt2 &&
        response.data.cost_quantification_alt2.length > 0
      ) {
        response.data.cost_quantification_alt2.map((row, idx) => {
          costQuantificationAlt2.push({
            year: idx,
            inversion: row.investment,
            operation: row.operation,
            maintenance: row.maintenance,
            externalities: row.externalities,
            total: 0,
          });
          return true;
        });

        setTimeout(async () => {
          this.calculateCostQuantificationAlt2();
          this.onTrigger();
        }, 1000);
      }

      if (response.data.data_sheet_extra_annex_benefits.length > 0) {
        response.data.data_sheet_extra_annex_benefits.map((row, idx) => {
          data_sheet_extra_annex_benefits.push({
            year: idx,
            benefit_1: row.ben1,
            benefit_2: row.ben2,
            benefit_3: row.ben3,
            benefit_4: row.ben4,
            benefit_5: row.ben5,
            benefit_6: row.ben6,
            externalities: row.ext,
            total: 0,
          });
          return true;
        });

        setTimeout(() => {
          this.calculateBenefitQuantification();
          this.onTrigger();
        }, 100);
      }

      if (response.data.cities_per_analysis_cost.length > 0) {
        var count = 0;
        response.data.cities_per_analysis_cost.map((city, idx) => {
          if (count === 0) {
            cities_per_analysis_cost.push({
              city: city.fk_city,
              delete: false,
            });
          } else {
            cities_per_analysis_cost.push({
              city: city.fk_city,
              delete: true,
            });
          }
          count++;
          return true;
        });
      } else {
        cities_per_analysis_cost.push({ city: 0, delete: false });
      }

      response.data.classifications.map((classification, idx) => {
        classifications.push({
          name: classification.name,
          value: classification.pk_classification,
        });
        return true;
      });
      response.data.dependencies.map((dependency, idx) => {
        dependencies.push({
          name: dependency.name,
          value: dependency.pk_dependency,
        });
        return true;
      });
      response.data.funding_sources.map((funding_source, idx) => {
        funding_sources.push({
          name: funding_source.name,
          value: funding_source.pk_funding_source,
        });
        return true;
      });
      response.data.funding_subsources.map((subsource) => {
        funding_subsources.push({
          name: subsource.name,
          value: subsource.pk_funding_subsources,
        });
        return true;
      });
      response.data.measurement_units.map((unit) => {
        measurement_units.push({
          name: unit.name,
          value: unit.pk_measurement_unit,
        });
        return true;
      });
      if (response.data.funding_sources_ppi.length > 0) {
        response.data.funding_sources_ppi.map((funding_source, idx) => {
          funding_sources_ppi.push({
            origin: funding_source.fk_funding_sources,
            percentage: funding_source.percentage,
            amount: Number.parseFloat(funding_source.amount).toFixed(2),
            subsourceFlag:
              funding_source.fk_funding_sources === 2 ? true : false,
            subsource: funding_source.fk_funding_subsource,
            otherFlag: funding_source.fk_funding_sources === 5 ? true : false,
            other: funding_source.other,
          });
          return true;
        });
        setTimeout(() => {
          this.calculateTotalFunding();
          this.onTrigger();
        }, 200);
      }
      if (response.data.components.length > 0) {
        response.data.components.map((component, idx) => {
          components.push({
            name_concept: component.name,
            description: component.description,
            unit_of_measure: component.fk_measurement_unit,
            quantity: component.quantity,
            unit_price: Number.parseFloat(component.unit_price).toFixed(2),
            total_amount: component.total_amount,
            number_concept: component.concept_number,
            description_concept: component.concept_description,
            number_item: component.item_number,
            description_item: component.item_description,
          });
          return true;
        });

        setTimeout(() => {
          this.calculateComponents();
          this.onTrigger();
        }, 200);
      }
      if (response.data.optimization_measures.length > 0) {
        response.data.optimization_measures.map((programOrProyect, idx) => {
          optimization_measures.push({
            nom_alt: programOrProyect.nom_alt,
            alternatives: programOrProyect.alternatives,
            totalCostVAT: programOrProyect.totalCostVAT,
          });
          return true;
        });
        setTimeout(() => {
          this.onTrigger();
        }, 200);
      }

      if (response.data.data_sheet_extra_annex_costs.length > 0) {
        response.data.data_sheet_extra_annex_costs.map((row, idx) => {
          data_sheet_extra_annex_costs.push({
            year: idx,
            inversion: row.investment,
            operation: row.operation,
            maintenance: row.maintenance,
            externalities: row.externalities,
            total: 0,
            total_actualizado: 0,
          });
          return true;
        });

        setTimeout(async () => {
          await this.calculateCostQuantification();
          this.onTrigger();
        }, 1000);
      }

      //Estudio pre

      let total_IX_amount = 0;
      if (response.data.data_sheet_extra_pre_investments.length > 0) {
        response.data.data_sheet_extra_pre_investments.map(
          (programOrProyect, idx) => {
            data_sheet_extra_pre_investments.push({
              study_name: programOrProyect.study_name,
              type_of_study: programOrProyect.type_of_study,
              estimated_date_completion:
                programOrProyect.estimated_date_completion,
              second_estimated_date_completion:
                programOrProyect.second_estimated_date_completion,
              justification_realization:
                programOrProyect.justification_realization,
              description: programOrProyect.description,
              estimated_cost_amount: programOrProyect.estimated_cost_amount,
            });
            total_IX_amount += Number(
              programOrProyect.estimated_cost_amount
                .toString()
                .replaceAll(",", "")
            );
            return true;
          }
        );
        setTimeout(() => {
          this.calculateDataSheet();
          this.onTrigger();
        }, 200);
      }
      total_IX_amount = fm.from(total_IX_amount);

      if (response.data.goals.length > 0) {
        response.data.goals.map((goal, idx) => {
          goals.push({
            name_concept: goal.concept,
            unit_of_measure: goal.fk_measurement_unit,
            quantity: goal.quantity,
          });
          return true;
        });

        setTimeout(() => {
          this.calculateComponents();
          this.onTrigger();
        }, 100);
      }
      response.data.calendar_investment.map((monthItem, idx) => {
        let newTotal = this.state.total_physical + Number(monthItem.physical);
        let newTotalFinancial =
          this.state.total_financial + Number(monthItem.financial);

        this.setState({
          total_physical: newTotal,
          total_financial: newTotalFinancial,
          total_financial_currency_format: fm.from(newTotalFinancial),
        });
        calendar_investment.push({
          advance: monthItem.advance,
          physical: monthItem.physical !== null ? monthItem.physical : 0,
          financial: monthItem.financial !== null ? monthItem.financial : 0,
        });
        return true;
      });

      if (response.data.alineacion_estrategica.length > 0) {
        response.data.alineacion_estrategica.map((alineacion, idx) => {
          alineacion_estrategica.push({
            plan: alineacion.plan,
            eje: alineacion.eje,
            tema: alineacion.tema,
            objetivo: alineacion.objetivo,
            estrategia: alineacion.estrategia,
            linea: alineacion.linea,
          });
          return true;
        });
        setTimeout(() => {
          this.calculateTotalFunding();
          this.onTrigger();
        }, 200);
      }

      if (response.data.alternativas_solucion.length > 0) {
        response.data.alternativas_solucion.map((solucion, idx) => {
          alternativas_solucion.push({
            alternativa: solucion.alternativa,
            ventajas: solucion.ventajas,
            desventajas: solucion.desventajas,
          });
          return true;
        });

        setTimeout(() => {
          this.calculateTotalFunding();
          this.onTrigger();
        }, 200);
      }

      let fecha = response.data.info.created_at;
      fecha = fecha.split(" ")[0];

      setTimeout(() => {
        this.setState({
          data_sheet_extra_pre_investments: data_sheet_extra_pre_investments,

          cost_quantification: data_sheet_extra_annex_costs,
          cost_quantification_alt2: costQuantificationAlt2,
          benefits_quantification: data_sheet_extra_annex_benefits,

          vpn: response.data.info.annexed_vpn,
          tir: response.data.info.annexed_tir,
          tri: response.data.info.annexed_tri,
          ppi_evaluado: response.data.info.annexed_cae_pe,
          alternativa: response.data.info.annexed_cae_alternative,
          optimal_tri: response.data.info.annexed_optimal_tri,

          tasa: response.data.tasa,
          amount: response.data.info.amount,
          cities: cities,
          users: users,
          roles: roles,
          classifications: classifications,
          dependencies: dependencies,
          funding_sources: funding_sources,
          funding_subsources: funding_subsources,
          founding_sources_origin: funding_sources_ppi,
          components: components,
          alineacion_estrategica: alineacion_estrategica,
          alternativas_solucion: alternativas_solucion,
          goals: goals,
          measurement_units: measurement_units,
          calendar_investment: calendar_investment,
          id_responsable: response.data.info.fk_responsable,
          name_ppi: response.data.info.name_ppi || "",
          type_ppi: response.data.info.type_ppi || "",
          showImagesFile:
            response.data.info.pictures_url !== null ? true : false,
          imagesFile: response.data.info.pictures_url,
          pic_file_name: response.data.info?.pictures_url?.split("_").pop(),

          showOficioFile: response.data.info.document !== null ? true : false,
          oficioFile: response.data.info.document,
          responsable_document_name: response.data.info?.document
            ?.split("_")
            .pop(),
          showAttachment: response.data.info.file !== null ? true : false,
          concepts: concepts,
          items: items,
          attachment: null,
          attachments_names: attachmentsFiles,
          archivos_nombre: archivos_File,
          dependency_applicant: response.data.info.dependency_applicant || "",
          dependency_executor: response.data.info.dependency_executor,
          classificationSelected: response.data.info.classification,
          citySelected: cities_per_analysis_cost,
          observations_location: response.data.info.observations_location,
          relationated_program: response.data.info.relationated_programs || "",
          objectives: response.data.info.objectives || "",
          action_lines: response.data.info.action_lines || "",
          total_IX_amount,

          description: response.data.info.description || "",
          current_situation_offer: response.data.info.offer_analysis || "",
          demand_current_situation: response.data.info.analysis_of_demand || "",
          intereaction_current_situation: response.data.info.interaction || "",
          general_description: response.data.info.general_description || "",
          objective_general: response.data.info.general_objective || "",
          offer_ppi: response.data.info.offer_analysis_ppi || "",
          demand_ppi: response.data.info.analysis_of_demand_ppi || "",
          interaction_ppi: response.data.info.interaction_ppi || "",
          execution_result: response.data.info.execution_results || "",
          beneficiaries: response.data.info.ppi_beneficiaries || "",
          executive_project: response.data.info.executive_project,
          executive_project_description:
            response.data.info.executive_project_description || "",
          permissions: response.data.info.permissions,
          permissions_description:
            response.data.info.permissions_description || "",
          enviromental_studies: response.data.info.enviromental_studies,
          enviromental_studies_description:
            response.data.info.enviromental_studies_description || "",
          property_rights: response.data.info.property_rights,
          property_rights_description:
            response.data.info.property_rights_description || "",
          numero_catastro: response.data.info.numero_catastro || "",
          catastro: response.data.info.catastro || "",
          tipo_bien: response.data.info.tipo_bien || "",
          tipo_bien_descripcion: response.data.info.tipo_bien_descripcion || "",
          otro: response.data.info.otro || "",

          final_comments: response.data.info.final_comments || "",
          name_applicant: response.data.info.fk_user,
          applicant_title: response.data.info.fk_role,
          applicant_pin: response.data.info.approval_pin || "",
          executor_name: response.data.info.name_executor || "",
          executor_title: response.data.info.executor_title || "",
          showPlanFile:
            response.data.info.architecturalPlan_file !== null ? true : false,
          showNumeroCatastro: response.data.info.tipo_bien !== 0 ? true : false,
          planFile: response.data.info.architecturalPlan_file,
          plans_name: response.data.info?.architecturalPlan_file
            ?.split("_")
            .pop(),

          asa_offer_analysus: response.data.info.asa_offer_analysus || "",
          asa_offer_analysis: response.data.info.asa_offer_analysis || "",
          asa_interaction: response.data.info.asa_interaction || "",
          alternative_justification:
            response.data.info.alternative_justification || "",

          general_information_approved:
            response.data.info.status_gip === 1 ? true : false,
          problem_description_approved:
            response.data.info.status_pd === 1 ? true : false,
          general_description_approved:
            response.data.info.status_gdp === 1 ? true : false,
          objective_approved:
            response.data.info.objective_status === 1 ? true : false,
          exesution_results_approved:
            response.data.info.execution_results_status === 1 ? true : false,
          feasibilities_approved:
            response.data.info.status_fp === 1 ? true : false,
          general_considerations_approved:
            response.data.info.status_gc === 1 ? true : false,
          attachments_approved:
            response.data.info.status_a === 1 ? true : false,
          project_situation_approved:
            response.data.info.project_situation_status === 1 ? true : false,
          situation_wppi_approved:
            response.data.info.project_situation_status === 1 ? true : false,

          //formatReadyToApprove: response.data.sections,
          puesto: response.data.info.cargopuesto,
          vida_util: response.data.info.vida_util || "",
          optimization_measures: optimization_measures,
          asp_relevantlegal: response.data.info.asp_relevantlegal || "",

          fecha: fecha < "2024-08-28" ? false : true,
        });
      }, 100);

      $(".loader").hide("fast", () => {});

      if (response.data.data_sheet_extra_annex_benefits.length > 0) {
        setTimeout(() => {
          ///////
          this.calculateBenefitQuantification(false);
          this.onTrigger();
        }, 1000);
      }
    } catch (error) {
      console.log(error);
    }

    let tipoFormato = await this.loadTipoFormato(this.api);

    this.setState({ tipoFormato: tipoFormato });

    const {
      formatReadyToApprove,
      general_information_approved,
      problem_description_approved,
      general_description_approved,
      objective_approved,
      exesution_results_approved,
      feasibilities_approved,
      attachments_approved,
      project_situation_approved,
      situation_wppi_approved,
    } = this.state;

    formatReadyToApprove[0].seccion1 = general_information_approved;
    formatReadyToApprove[0].seccion2 = problem_description_approved;
    formatReadyToApprove[0].seccion3 = general_description_approved;
    formatReadyToApprove[0].seccion4 = objective_approved;
    formatReadyToApprove[0].seccion5 = exesution_results_approved;
    formatReadyToApprove[0].seccion6 = feasibilities_approved;
    formatReadyToApprove[0].seccion7 = attachments_approved;
    formatReadyToApprove[0].seccion8 = project_situation_approved;
    formatReadyToApprove[0].seccion9 = situation_wppi_approved;

    // console.log("Seccion1", formatReadyToApprove[0].seccion1);
    // console.log("Seccion2", formatReadyToApprove[0].seccion2);
    // console.log("Seccion3", formatReadyToApprove[0].seccion3);
    // console.log("Seccion4", formatReadyToApprove[0].seccion4);
    // console.log("Seccion5", formatReadyToApprove[0].seccion5);
    // console.log("Seccion6", formatReadyToApprove[0].seccion6);
    // console.log("Seccion7", formatReadyToApprove[0].seccion7);
    // console.log("Seccion8", formatReadyToApprove[0].seccion8);
    // console.log("Seccion9", formatReadyToApprove[0].seccion9);

    const Numero = 0;
    const CountTipoFormato = tipoFormato.filter((key, index) => {
      if (Boolean(key) == formatReadyToApprove[0][`seccion${index + 1}`]) {
        return 1;
      } else {
        return 0;
      }
    });

    const filteredFormats = tipoFormato.filter(
      (tipo, index) => Number(tipo) === 1
    );

    // console.log("CountTipoFormato", CountTipoFormato);

    if (filteredFormats.length === 0) {
      this.setState(
        {
          formatoAprobado_: false,
        },
        () => {
          this.rerenderComponent();
        }
      );
    } else if (filteredFormats.length == CountTipoFormato.length) {
      this.setState(
        {
          formatoAprobado_: true,
        },
        () => {
          this.rerenderComponent();
        }
      );
    }

    if (this.state.alineacion_estrategica.length === 0) {
      this.addRowAlineacion();
    }
  };

  handleCallback = (childData) => {
    this.setState({
      coordinatesData: childData,
    });
    this.onTrigger();
  };
  scrollToBottom() {
    //Scroll to the bottom of a div
    $(document).scrollTop($(document)[0].scrollHeight);
  }
  handleScroll() {
    //Handle every scroll event
    let elem = $(document);
    if (elem !== undefined) {
      if (elem[0].scrollHeight - elem.scrollTop() > 1300) {
        this.setState({
          scroll: false,
        });
      } else {
        this.setState({
          scroll: true,
        });
      }
    }
  }
  handleClickUp = () => {
    $([document.documentElement, document.body]).animate(
      {
        scrollTop: $(".NotaTecnica").offset().top - 180,
      },
      1000
    );
  };

  handleChangePlansFileInput = (e) => {
    const nextState = { ...this.state };
    const value = e.target.files[0];
    // console.log("value", value);

    if (!this.isValidPlansFileType(value)) {
      // Mostrar un mensaje de error aquí
      NotificationManager.error(
        "SOLO SE PERMITE WORD",
        "¡Error al cargar archivo!"
      );
      setTimeout(() => {
        $(".loader").hide("slow", () => {});
      }, 1000);
      console.error(
        "Tipo de archivo no admitido. Por favor, seleccione un archivo válido."
      );
      return;
    }

    $(".loader").show("fast", () => {});
    nextState.plans = value;
    nextState.plans_name = value.name;
    this.setState(nextState);
    this.onTrigger();

    const plansData = new FormData();
    if (nextState.plans) {
      plansData.append("plans", nextState.plans);

      axios
        .post(`${this.api}/ppi/plans/${this.props.id}`, plansData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          NotificationManager.success(
            "Plantilla de imágenes situación con PPI",
            "¡Archivo guardado!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => {});
          }, 1000);
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          setTimeout(() => {
            $(".loader").hide("slow", () => {});
          }, 1000);
        });
    }
  };

  isValidPlansFileType = (file) => {
    const allowedTypes = [".pdf", ".doc", ".docx", "application/msword"];
    const fileType = file.type || "";
    const fileName = file.name || "";

    return allowedTypes.some(
      (type) => fileType.includes(type) || fileName.endsWith(type)
    );
  };

  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};

    data[name] = value;

    console.log("Nombre del campo:", name);
    console.log("Valor ingresado:", value);
    console.log("Objeto data actualizado:", data);

    this.setState(data, () => {
      console.log("Nuevo estado:", this.state);
    });

    this.onTrigger();
  };

  // onChangehandlerTipoBien = (event) => {
  //   const { name, value } = event.target;
  //   this.setState((prevState) => {
  //       let newState = { ...prevState };

  //       // Actualiza el valor del campo
  //       newState[name] = value;

  //       // Condición 1: Si el tipo_bien es 0, ocultar número de catastro
  //       if (name === "tipo_bien" && value === "0") {
  //           newState.mostrarCatastro = false;
  //       } else {
  //           newState.mostrarCatastro = true;
  //       }

  //       // Condición 2: Si tipo_bien es 1, mostrar opciones de estatal, municipal y otro
  //       if (name === "tipo_bien" && value === "1") {
  //           newState.mostrarOpcionesTipoBien = true;
  //       } else {
  //           newState.mostrarOpcionesTipoBien = false;
  //       }

  //       // Condición 3: Si tipo_bien_descripcion es 2, mostrar campo adicional
  //       if (name === "tipo_bien_descripcion" && value === "2") {
  //           newState.mostrarCampoOtro = true;
  //       } else {
  //           newState.mostrarCampoOtro = false;
  //       }

  //       return newState;
  //   });
  // };

  onChangehandlerCity = (e) => {
    const idx = e.target.dataset.idx;
    const citySelected = this.state.citySelected;
    citySelected[idx].city = e.target.value;
    this.setState({ citySelected });
    this.onTrigger();
  };

  GetCantidadesPunto(params) {
    if (params !== "" && params !== undefined) {
      let letterCount = params.toString().split(/\W/).join("").length;
      let cantidad = params.toString().indexOf(".");
      let CantidadCaracter = 0;

      if (cantidad == 1) cantidad = 2;

      if (cantidad > 0) {
        CantidadCaracter = parseInt(
          parseInt(letterCount) - parseInt(cantidad) + 1
        );
      }

      return CantidadCaracter;
    }

    return 0;
  }

  onChangehandlerOrigin = (e) => {
    const idx = Number(e.target.dataset.idx);
    const founding_sources_origin = this.state.founding_sources_origin;
    founding_sources_origin[idx].origin = e.target.value;
    if (Number(e.target.value) === 2) {
      founding_sources_origin[idx].subsourceFlag = true;
      founding_sources_origin[idx].subsource = 0;
    } else {
      founding_sources_origin[idx].subsourceFlag = false;
      founding_sources_origin[idx].subsource = 0;
    }

    if (Number(e.target.value) === 5) {
      founding_sources_origin[idx].otherFlag = true;
      founding_sources_origin[idx].other = "";
    } else {
      founding_sources_origin[idx].otherFlag = false;
      founding_sources_origin[idx].other = "";
    }
    setTimeout(() => {
      this.setState({ founding_sources_origin });
    }, 100);
    this.onTrigger();
  };

  // onChangehandlerFactibilidades = (e) => {

  //   const idx = Number(e.target.dataset.idx);
  //   const factibilidades = this.state.factibilidades;

  //   factibilidades[idx].tipo_bien = e.target.value;
  //   if (Number(e.target.value) === 2) {
  //     factibilidades[idx].catastro
  //     factibilidades[idx].numero.numero_catastro

  //     // founding_sources_origin[idx].subsource = 0;
  //   } else {
  //     // founding_sources_origin[idx].subsourceFlag = false;
  //     // founding_sources_origin[idx].subsource = 0;
  //   }

  //   // if (Number(e.target.value) === 5) {
  //   //   founding_sources_origin[idx].otherFlag = true;
  //   //   founding_sources_origin[idx].other = "";
  //   // } else {
  //   //   founding_sources_origin[idx].otherFlag = false;
  //   //   founding_sources_origin[idx].other = "";
  //   // }

  //   this.onTrigger();
  // };

  onChangehandlerSubsource = (e) => {
    const idx = Number(e.target.dataset.idx);
    const founding_sources_origin = this.state.founding_sources_origin;
    founding_sources_origin[idx].subsource = e.target.value;
    setTimeout(() => {
      this.setState({ founding_sources_origin });
    }, 100);
    this.onTrigger();
  };
  onChangehandlerOther = (e) => {
    const idx = Number(e.target.dataset.idx);
    const founding_sources_origin = this.state.founding_sources_origin;
    founding_sources_origin[idx].other = e.target.value;
    setTimeout(() => {
      this.setState({ founding_sources_origin });
    }, 100);
    this.onTrigger();
  };
  onChangehandlerPercentage = (e) => {
    const idx = e.target.dataset.idx;
    const founding_sources_origin = this.state.founding_sources_origin;
    var regexp = /^[0-9,.\b]+$/;

    if (e.target.value === "" || regexp.test(e.target.value)) {
      let percentage = Number(e.target.value) / 100;
      let amount = percentage * this.state.amount;

      founding_sources_origin[idx].percentage = e.target.value;
      founding_sources_origin[idx].amount =
        Number.parseFloat(amount).toFixed(2);

      this.setState({ founding_sources_origin });
      this.calculateTotalFunding();
      this.onTrigger();
    }
  };
  onChangehandlerAmount = (e) => {
    const idx = e.target.dataset.idx;
    const founding_sources_origin = this.state.founding_sources_origin;
    founding_sources_origin[idx].amount = e.target.value;
    this.setState({ founding_sources_origin });
    this.calculateTotalFunding();
    this.onTrigger();
  };
  onChangehandlerAdvance = (e) => {
    const idx = e.target.dataset.idx;
    const calendar_investment = this.state.calendar_investment;
    calendar_investment[idx].advance = e.target.value;
    this.setState({
      calendar_investment,
    });
    this.onTrigger();
  };
  onChangehandlerPhysical = (e) => {
    const idx = e.target.dataset.idx;
    const calendar_investment = this.state.calendar_investment;
    var regexp = /^[0-9.\b]+$/;
    if (e.target.value === "" || regexp.test(e.target.value)) {
      calendar_investment[idx].physical = e.target.value;
      this.setState({
        calendar_investment,
      });
      this.calculateInvestment();
      this.onTrigger();
    }
  };
  onChangehandlerFinancial = (e) => {
    const idx = e.target.dataset.idx;
    const calendar_investment = this.state.calendar_investment;
    calendar_investment[idx].financial = e.target.value;

    setTimeout(() => {
      this.setState({
        calendar_investment,
      });
      this.calculateInvestment();
      this.onTrigger();
    }, 100);
  };
  onChangehandlerNameConcept = (e) => {
    const idx = e.target.dataset.idx;
    const components = this.state.components;
    components[idx].name_concept = e.target.value;
    this.setState({
      components,
    });
    this.onTrigger();
  };
  onChangehandlerAlineacion = (e) => {
    const idx = e.target.dataset.idx;
    const alineacion = this.state.alineacion;
    alineacion[idx].plan = e.target.value;
    this.setState({
      alineacion,
    });
    this.onTrigger();
  };

  /* onChangehandlerAlineacionEje = (e) => {
         const idx = e.target.dataset.idx;
         const { alineacion } = this.state;
         const updatedAlineacion = [...alineacion];  // Crear una copia del arreglo
         updatedAlineacion[idx] = {
             ...updatedAlineacion[idx],  // Crear una copia del objeto en el índice
             eje: e.target.value
         };
         
         this.setState({
             alineacion: updatedAlineacion
         }, () => {
             this.onTrigger();  // Llamar a onTrigger después de que el estado se haya actualizado
         });
     }*/

  onChangehandlerAlineacion_ = (e) => {
    const idx = e.target.dataset.idx;
    const property = e.target.name;
    const value = e.target.value;

    this.setState(
      (prevState) => {
        const updatedAlineacion = [...prevState.alineacion_estrategica];
        const updatedItem = { ...updatedAlineacion[idx], [property]: value };
        updatedAlineacion[idx] = updatedItem;

        return {
          alineacion_estrategica: updatedAlineacion,
        };
      },
      () => {
        this.onTrigger();
      }
    );
  };

  onChangehandlerAlternativas_ = (e) => {
    const idx = e.target.dataset.idx;
    const property = e.target.name;
    const value = e.target.value;

    this.setState(
      (prevState) => {
        const updatedAlternativa = [...prevState.alternativas_solucion];
        const updatedItems = { ...updatedAlternativa[idx], [property]: value };
        updatedAlternativa[idx] = updatedItems;

        return {
          alternativas_solucion: updatedAlternativa,
        };
      },
      () => {
        this.onTrigger();
      }
    );
  };

  onChangehandlerNumberConcept = (e) => {
    const idx = e.target.dataset.idx;
    const components = this.state.components;
    components[idx].number_concept = e.target.value;

    this.state.concepts.map((concept, idxT) => {
      if (Number(e.target.value) === concept.value) {
        components[idx].description_concept = concept.name;
      }
      return true;
    });
    this.setState({
      components,
    });
    // this.calculateInvestment();
    this.onTrigger();
  };
  onChangehandlerDescConcept = (e) => {
    const idx = e.target.dataset.idx;
    const components = this.state.components;
    components[idx].description_concept = e.target.value;
    this.setState({
      components,
    });
    // this.calculateInvestment();
    this.onTrigger();
  };
  onChangehandlerNumberItem = (e) => {
    const idx = e.target.dataset.idx;
    const components = this.state.components;
    components[idx].number_item = e.target.value;
    this.state.items.map((item, idxT) => {
      if (Number(e.target.value) === item.value) {
        components[idx].description_item = item.name;
      }
      return true;
    });
    this.setState({
      components,
    });
    this.onTrigger();
  };
  onChangehandlerDescItem = (e) => {
    const idx = e.target.dataset.idx;
    const components = this.state.components;
    components[idx].description_item = e.target.value;
    this.setState({
      components,
    });
    // this.calculateInvestment();
    this.onTrigger();
  };
  onChangehandlerDescriptionConcept = (e) => {
    const idx = e.target.dataset.idx;
    const components = this.state.components;
    components[idx].description = e.target.value;
    this.setState({
      components,
    });
    // this.calculateInvestment();
    this.onTrigger();
  };
  onChangehandlerUnitOfMeasure = (e) => {
    const idx = e.target.dataset.idx;
    const components = this.state.components;
    components[idx].unit_of_measure = e.target.value;
    this.setState({
      components,
    });
    // this.calculateInvestment();
    this.onTrigger();
  };
  onChangehandlerQuantityComponent = (e) => {
    const idx = e.target.dataset.idx;
    const components = this.state.components;
    var regexp = /^[0-9,.\b]+$/;

    if (e.target.value === "" || regexp.test(e.target.value)) {
      components[idx].quantity = e.target.value;
      this.setState({
        components,
      });
      this.calculateTotalComponent(idx);
      this.calculateComponents();
      this.onTrigger();
    }
  };
  onChangehandlerUnitPrice = (e) => {
    const idx = e.target.dataset.idx;
    const components = this.state.components;
    var regexp = /^[0-9,.\b]+$/;

    if (e.target.value === "" || regexp.test(e.target.value)) {
      components[idx].unit_price = e.target.value;
      this.setState({
        components,
      });
      this.calculateTotalComponent(idx);
      this.calculateComponents();
      this.onTrigger();
    }
  };
  onChangehandlerTotalAmount = (e) => {
    const idx = e.target.dataset.idx;
    const components = this.state.components;
    components[idx].total_amount = e.target.value;
    this.setState({
      components,
    });
    // this.calculateTotalComponent(idx);
    this.calculateComponents();
    this.onTrigger();
  };
  onChangehandlerNameConceptGoal = (e) => {
    const idx = e.target.dataset.idx;
    const goals = this.state.goals;
    goals[idx].name_concept = e.target.value;
    this.setState({
      goals,
    });
    this.onTrigger();
  };
  onChangehandlerUnitMeasureGoal = (e) => {
    const idx = e.target.dataset.idx;
    const goals = this.state.goals;
    goals[idx].unit_of_measure = e.target.value;
    this.setState({
      goals,
    });
    this.onTrigger();
  };
  onChangehandlerQuantityGoal = (e) => {
    const idx = e.target.dataset.idx;
    const goals = this.state.goals;
    goals[idx].quantity = e.target.value;
    this.setState({
      goals,
    });
    this.onTrigger();
  };
  onChangehandlerPIN = (e) => {
    let applicant_pin = e.target.value;
    var regexp = /^[0-9\b]+$/;

    if (applicant_pin === "" || regexp.test(applicant_pin)) {
      this.setState({
        applicant_pin,
      });
    }
    this.onTrigger();
  };
  onChangehandlerBeneficiaries = (e) => {
    let beneficiaries = e.target.value;
    this.setState({
      beneficiaries,
    });
    this.onTrigger();
  };
  onChangeOptimizationMeasuresAlternatives = (e) => {
    const idx = Number(e.target.dataset.idx);
    const optimization_measures = this.state.optimization_measures;
    optimization_measures[idx].alternatives = e.target.value;
    setTimeout(() => {
      this.setState({ optimization_measures });
    }, 100);
    this.onTrigger();
  };

  onChangeOptimizationMeasuresName = (e) => {
    const idx = Number(e.target.dataset.idx);
    const optimization_measures = this.state.optimization_measures;
    optimization_measures[idx].nom_alt = e.target.value;
    setTimeout(() => {
      this.setState({ optimization_measures });
    }, 100);
    this.onTrigger();
  };

  onChangeOptimizationMeasuresTotalCostVAT = (e) => {
    const idx = Number(e.target.dataset.idx);
    const optimization_measures = this.state.optimization_measures;
    optimization_measures[idx].totalCostVAT = e.target.value;
    setTimeout(() => {
      this.setState({ optimization_measures });
    }, 100);
    this.onTrigger();
  };

  //estudio
  onChangeTableExtra = (e) => {
    const idx = Number(e.target.dataset.idx);
    const tabla = e.target.dataset.tabla;
    const nameCol = e.target.name;
    const table = [];
    const fm = new FormatMoney({
      decimals: 2,
    });

    if (tabla === "data_sheet_extra_pre_investments") {
      const table = this.state.data_sheet_extra_pre_investments;
      if (nameCol === "study_name") {
        table[idx].study_name = e.target.value;
      }
      if (nameCol === "type_of_study") {
        table[idx].type_of_study = e.target.value;
      }
      if (nameCol === "estimated_date_completion") {
        table[idx].estimated_date_completion = e.target.value;
      }
      if (nameCol === "second_estimated_date_completion") {
        table[idx].second_estimated_date_completion = e.target.value;
      }
      if (nameCol === "justification_realization") {
        table[idx].justification_realization = e.target.value;
      }
      if (nameCol === "description") {
        table[idx].description = e.target.value;
      }
      if (nameCol === "estimated_cost_amount") {
        table[idx].estimated_cost_amount = e.target.value;
      }
    } else {
      return true;
    }

    setTimeout(() => {
      this.setState({ table });
    }, 100);

    this.onTrigger();

    this.calculateDataSheet();
    this.onTrigger();
  };

  calculateTotalFunding = (index) => {
    const fm = new FormatMoney({
      decimals: 2,
    });
    const founding_sources_origin = this.state.founding_sources_origin;
    let percentage = 0,
      amount = 0;
    founding_sources_origin.map((funding) => {
      percentage += Number(funding.percentage);
      amount += Number(funding.amount.toString().replace(/,/g, ""));
      return true;
    });
    setTimeout(() => {
      this.setState({
        total_percentage_funding: percentage,
        total_amount_funding: fm.from(amount),
      });
      this.onTrigger();
    }, 100);
  };

  calculateTotalComponent = (index) => {
    const components = this.state.components;
    let idx = Number(index);
    if (
      components[idx].quantity !== undefined &&
      components[idx].unit_price !== undefined
    ) {
      if (
        Number(components[idx].quantity.toString().replace(/,/g, "")) &&
        Number(components[idx].unit_price.toString().replace(/,/g, ""))
      ) {
        components[idx].total_amount =
          Number(components[idx].quantity.toString().replace(/,/g, "")) *
          Number(components[idx].unit_price.toString().replace(/,/g, ""));
      } else {
        return false;
      }
      this.setState({
        components,
      });
      this.onTrigger();
    }
  };

  calculateComponents = () => {
    const fm = new FormatMoney({
      decimals: 2,
    });
    let subtotal = 0;
    this.state.components.map((element) => {
      if (element.quantity !== "" && element.unit_price !== "") {
        subtotal += element.total_amount;
      }
      return true;
    });

    this.setState(
      {
        subtotal_components: fm.from(subtotal),
        iva_components: fm.from(subtotal * 0.16),
        total_components: fm.from(subtotal * 1.16),
      },
      () => {
        this.forceUpdate();
      }
    );
    this.onTrigger();
  };

  //calculete estudio
  calculateDataSheet = async (index) => {
    const fm = new FormatMoney({
      decimals: 2,
    });

    const data_sheet_extra_pre_investments =
      this.state.data_sheet_extra_pre_investments;

    // let idx = Number(index);
    let amount = 0,
      amount1 = 0,
      amount2 = 0,
      amount8 = 0,
      amount9 = 0,
      amount10 = 0,
      amount11 = 0,
      amount12 = 0,
      amount13 = 0,
      amount14 = 0,
      amount16 = 0,
      amount17 = 0,
      amount18 = 0;

    data_sheet_extra_pre_investments.map((funding) => {
      amount14 += Number(
        funding.estimated_cost_amount.toString().replaceAll(",", "")
      );
      return true;
    });

    setTimeout(() => {
      this.setState({
        total_IX_amount: fm.from(amount14),
      });
      this.onTrigger();
    }, 100);
  };

  calculateInvestment = () => {
    const fm = new FormatMoney({
      decimals: 2,
    });
    let newTotal = 0,
      newTotalFinancial = 0;

    this.state.calendar_investment.map((element) => {
      newTotal += Number(element.physical);
      newTotalFinancial += Number(
        element.financial.toString().replace(/,/g, "")
      );
      return true;
    });

    this.setState({
      total_physical: newTotal,
      total_financial: newTotalFinancial,
      total_financial_currency_format: fm.from(newTotalFinancial),
    });
    this.onTrigger();
  };

  /* Handlers de clicks para abrir ventanas de selección de archivos */
  handleInputFileClick = (e) => {
    this.fileInput.click();
  };
  architecturalPlanfile = (e) => {
    this.planfileInput.click();
  };
  handleInputFileOficioClick = (e) => {
    this.fileOficioInput.click();
  };
  handleInputAttachmentsClick = (e) => {
    this.attachmentsInput.click();
  };
  handleInputArchivosClick = (e) => {
    this.archivosInput.click();
  };
  /*  */

  setDoneByAdmin = (e) => {
    let id = this.props.id;
    let formatoAprobado = "";
    axios
      .put(`${this.api}/ppi/formatDone/${id}`, { section: e })
      .then((response) => {
        if (response.status === 200) {
          switch (e.toLowerCase()) {
            case "general_information":
              formatoAprobado = this.state.formatReadyToApprove;
              formatoAprobado[0].seccion1 = true;
              this.setState({
                general_information_approved: true,
                commentsGIPFlag: false,
                commentsGIP: "",
                showModal: true,
                icon: "success",
                titleModal: "Sección aprobada",
                messageModal:
                  "La sección Información general del PPI fue aprobada",
                formatReadyToApprove: formatoAprobado,
              });
              break;
            case "problem_description":
              formatoAprobado = this.state.formatReadyToApprove;
              formatoAprobado[0].seccion2 = true;
              this.setState({
                problem_description_approved: true,
                commentsPDPFlag: false,
                commentsPDP: "",
                showModal: true,
                icon: "success",
                titleModal: "Sección aprobada",
                messageModal: "La sección Descripción del PPI fue aprobada",
                formatReadyToApprove: formatoAprobado,
              });
              break;
            case "general_description":
              formatoAprobado = this.state.formatReadyToApprove;
              formatoAprobado[0].seccion3 = true;
              this.setState({
                general_description_approved: true,
                commentsGDPFlag: false,
                commentsGDP: "",
                showModal: true,
                icon: "success",
                titleModal: "Sección aprobada",
                messageModal:
                  "La sección Análisis de la situación actual fue aprobada",
                formatReadyToApprove: formatoAprobado,
              });
              break;
            case "objective":
              formatoAprobado = this.state.formatReadyToApprove;
              formatoAprobado[0].seccion4 = true;
              this.setState({
                objective_approved: true,
                commentsObjectiveFlag: false,
                commentsObjective: "",
                showModal: true,
                icon: "success",
                titleModal: "Sección aprobada",
                messageModal:
                  "La sección Análisis de la situación con PPI fue aprobada",
                formatReadyToApprove: formatoAprobado,
              });
              break;
            case "execution_results":
              formatoAprobado = this.state.formatReadyToApprove;
              formatoAprobado[0].seccion5 = true;
              this.setState({
                exesution_results_approved: true,
                commentsExecutionResultsFlag: false,
                commentsExecutionResults: "",
                showModal: true,
                icon: "success",
                titleModal: "Sección aprobada",
                messageModal:
                  "La sección Análisis de la situación optimizada PPI fue aprobada",
                formatReadyToApprove: formatoAprobado,
              });
              break;
            case "feasibilities":
              formatoAprobado = this.state.formatReadyToApprove;
              formatoAprobado[0].seccion6 = true;
              this.setState({
                feasibilities_approved: true,
                commentsFPFlag: false,
                commentsFP: "",
                showModal: true,
                icon: "success",
                titleModal: "Sección aprobada",
                messageModal:
                  "La sección Alternativas de solución fue aprobada",
                formatReadyToApprove: formatoAprobado,
              });
              break;
            case "attachments":
              formatoAprobado = this.state.formatReadyToApprove;
              formatoAprobado[0].seccion7 = true;
              this.setState({
                attachments_approved: true,
                commentsAPFlag: false,
                commentsAP: "",
                showModal: true,
                icon: "success",
                titleModal: "Sección aprobada",
                messageModal: "La sección consideraciones finales fue aprobada",
                formatReadyToApprove: formatoAprobado,
              });
              break;
            case "project_situation":
              formatoAprobado = this.state.formatReadyToApprove;
              formatoAprobado[0].seccion8 = true;
              this.setState({
                project_situation_approved: true,
                commentsPSPFlag: false,
                commentsPSP: "",
                showModal: true,
                icon: "success",
                titleModal: "Sección aprobada",
                messageModal:
                  "La sección Anexo I. Situación con proyecto fue aprobada",
              });
              break;
            case "situation_ppi":
              formatoAprobado = this.state.formatReadyToApprove;
              formatoAprobado[0].seccion9 = true;
              this.setState({
                situation_wppi_approved: true,
                commentsSWPFlag: false,
                commentsSWP: "",
                showModal: true,
                icon: "success",
                titleModal: "Sección aprobada",
                messageModal: "La sección anexo socioeconomico fue aprobada",
              });
              break;
            default:
              break;
          }
        }

        const { tipoFormato, formatReadyToApprove } = this.state;

        const CountTipoFormato = tipoFormato.filter((key, index) => {
          if (Boolean(key) == formatReadyToApprove[0][`seccion${index + 1}`]) {
            return 1;
          } else {
            return 0;
          }
        });

        const filteredFormats = tipoFormato.filter(
          (tipo, index) => Number(tipo) === 1
        );

        if (filteredFormats.length === 0) {
          this.setState(
            {
              formatoAprobado_: false,
            },
            () => {
              this.rerenderComponent();
            }
          );
        } else if (filteredFormats.length == CountTipoFormato.length) {
          this.setState(
            {
              formatoAprobado_: true,
            },
            () => {
              this.rerenderComponent();
            }
          );
        }

        if (response.data.sections === true) {
          this.setState({
            //formatReadyToApprove: true
          });
          this.props.parentCallback(this.state);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  setUndoByAdmin = (e) => {
    let id = this.props.id;
    axios
      .put(`${this.api}/ppi/formatUndo/${id}`, { section: e })
      .then((response) => {
        if (response.status === 200) {
          switch (e.toLowerCase()) {
            case "general_information":
              this.setState({
                general_information_approved: false,
                showModal: true,
                icon: "warning",
                titleModal: "Acción revertida",
                messageModal:
                  "La sección Información general del PPI regresó a estatus no aprobada",
              });
              break;
            case "problem_description":
              this.setState({
                problem_description_approved: false,
                showModal: true,
                icon: "warning",
                titleModal: "Acción revertida",
                messageModal:
                  "La sección Descripción del PPI regresó a estatus no aprobada",
              });
              break;
            case "general_description":
              this.setState({
                general_description_approved: false,
                showModal: true,
                icon: "warning",
                titleModal: "Acción revertida",
                messageModal:
                  "La sección Análisis de la situación actual regresó a estatus no aprobada",
              });
              break;
            case "objective":
              this.setState({
                objective_approved: false,
                showModal: true,
                icon: "warning",
                titleModal: "Acción revertida",
                messageModal:
                  "La sección Análisis de la situación con PPI regresó a estatus no aprobada",
              });
              break;
            case "execution_results":
              this.setState({
                exesution_results_approved: false,
                showModal: true,
                icon: "warning",
                titleModal: "Acción revertida",
                messageModal:
                  "La sección Análisis de la situación optimizada regresó a estatus no aprobada",
              });
              break;
            case "feasibilities":
              this.setState({
                feasibilities_approved: false,
                showModal: true,
                icon: "warning",
                titleModal: "Acción revertida",
                messageModal:
                  "La sección Alternativas de solución regresó a estatus no aprobada",
              });
              break;
            case "general_considerations":
              this.setState({
                general_considerations_approved: false,
                showModal: true,
                icon: "warning",
                titleModal: "Acción revertida",
                messageModal:
                  "La sección Consideraciones Generales regresó a estatus no aprobada",
              });
              break;
            case "attachments":
              this.setState({
                attachments_approved: false,
                showModal: true,
                icon: "warning",
                titleModal: "Acción revertida",
                messageModal:
                  "La sección consideraciones finales regresó a estatus no aprobada",
              });
              break;
            case "project_situation":
              this.setState({
                project_situation_approved: false,
                showModal: true,
                icon: "success",
                titleModal: "Acción revertida",
                messageModal:
                  "La sección Anexo I. Situación con proyecto regresó a estatus no aprobada",
              });
              break;
            case "situation_ppi":
              this.setState({
                situation_wppi_approved: true,
                commentsSWPFlag: false,
                commentsSWP: "",
                showModal: true,
                icon: "success",
                titleModal: "Sección aprobada",
                messageModal:
                  "La sección Anexo socioeconimico regresó a estatus no aprobada",
              });
              break;
            default:
              break;
          }
        }

        this.setState(
          {
            formatoAprobado_: false,
          },
          () => {
            this.rerenderComponent();
          }
        );
        if (response.data.sections === false) {
          this.setState({
            //formatReadyToApprove: false
          });
          this.props.parentCallback(this.state);
        }
      })
      .catch((err) => {
        console.error(err);
      });
  };

  /* COMMENTS */
  AddCommentGIP = () => {
    this.setState({
      commentsGIPFlag: !this.state.commentsGIPFlag,
    });
  };

  AddCommentPDP = () => {
    this.setState({
      commentsPDPFlag: !this.state.commentsPDPFlag,
    });
  };

  AddCommentGDP = () => {
    this.setState({
      commentsGDPFlag: !this.state.commentsGDPFlag,
    });
  };

  AddCommentObjective = () => {
    this.setState({
      commentsObjectiveFlag: !this.state.commentsObjectiveFlag,
    });
  };

  AddCommentEr = () => {
    this.setState({
      commentsExecutionResultsFlag: !this.state.commentsExecutionResultsFlag,
    });
  };

  AddCommentFP = () => {
    this.setState({
      commentsFPFlag: !this.state.commentsFPFlag,
    });
  };

  AddCommentGCP = () => {
    this.setState({
      commentsGCPFlag: !this.state.commentsGCPFlag,
    });
  };

  AddCommentAP = () => {
    this.setState({
      commentsAPFlag: !this.state.commentsAPFlag,
    });
  };

  AddCommentPSP = () => {
    this.setState({
      commentsPSPFlag: !this.state.commentsPSPFlag,
    });
  };

  AddCommentSWP = () => {
    this.setState({
      commentsSWPFlag: !this.state.commentsSWPFlag,
    });
  };

  /* TERMINA AREA DE COMMENTS HANDLERS */

  /* Files */
  handleChangePicFileInput = (e) => {
    const nextState = { ...this.state };
    const value = e.target.files[0];
    // console.log("value", value);

    if (!this.isValidFileType(value)) {
      // Mostrar un mensaje de error aquí
      NotificationManager.error(
        "Error al cargar archivo",
        "SOLO SE PERMITE FORMATO WORD"
      );
      setTimeout(() => {
        $(".loader").hide("slow", () => {});
      }, 1000);

      console.error(
        "Tipo de archivo no admitido. Por favor, seleccione un archivo de Word."
      );
      return;
    }

    $(".loader").show("fast", () => {});
    nextState.PicFile = value;
    nextState.pic_file_name = value.name;
    this.setState(nextState);
    this.onTrigger();

    const imgData = new FormData();
    if (nextState.PicFile) {
      imgData.append("document_files", nextState.PicFile);

      axios
        .post(`${this.api}/ppi/images/${this.props.id}`, imgData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          NotificationManager.success(
            "Plantilla de imágenes situación actual",
            "¡Archivo guardado!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => {});
          }, 1000);
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          setTimeout(() => {
            $(".loader").hide("slow", () => {});
          }, 1000);
        });
    }
  };

  isValidFileType = (file) => {
    const allowedTypes = [".doc", ".docx", "application/msword"];
    const fileType = file.type || "";
    const fileName = file.name || "";

    return allowedTypes.some(
      (type) => fileType.includes(type) || fileName.endsWith(type)
    );
  };

  handleChangeOficioFileInput = (e) => {
    const nextState = { ...this.state };
    const value = e.target.files[0];

    if (!this.isValidPDFFileType(value)) {
      // Mostrar un mensaje de error aquí
      NotificationManager.error(
        "SOLO SE PERMITE PDF",
        "¡ERROR AL CARGAR ARCHIVO !"
      );
      setTimeout(() => {
        $(".loader").hide("slow", () => {});
      }, 1000);
      console.error(
        "Tipo de archivo no admitido. Por favor, seleccione un archivo PDF válido."
      );
      return;
    }

    $(".loader").show("fast", () => {});
    nextState.responsable_document = value;

    if (value.name.length > 20) {
      nextState.responsable_document_name = value.name.substr(0, 14) + "...";
    } else {
      nextState.responsable_document_name = value.name;
    }

    this.setState(nextState);
    this.onTrigger();

    const oficioData = new FormData();
    if (nextState.responsable_document) {
      oficioData.append("oficio", nextState.responsable_document);

      axios
        .post(`${this.api}/ppi/oficio/${this.props.id}`, oficioData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          NotificationManager.success(
            "Oficio guardado correctamente",
            "¡Archivo guardado!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => {});
          }, 1000);
          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          NotificationManager.error(
            "El oficio no se guardó correctamente",
            "¡Error al guardar el Oficio!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => {});
          }, 1000);
        });
    }
  };

  isValidPDFFileType = (file) => {
    if (!file) return false; // Verificar si el archivo existe

    const allowedTypes = ["application/pdf"]; // MIME type para PDF
    const allowedExtensions = [".pdf"]; // Extensión permitida

    const fileType = file.type || "";
    const fileName = file.name || "";

    return (
        allowedTypes.includes(fileType) || 
        allowedExtensions.some((ext) => fileName.toLowerCase().endsWith(ext))
    );
};


  handleChangeAttachmentsFileInput = (e) => {
    var nextState = this.state;
    let values = e.target.files;

    $(".loader").show("fast", () => {});
    Array.from(values).forEach((file) => {
      nextState.attachments.push(file);
      var _size = file.size;
      var fSExt = ["Bytes", "KB", "MB", "GB"],
        i = 0;
      while (_size > 900) {
        _size /= 1024;
        i++;
      }

      var exactSize = Math.ceil(Math.round(_size * 100) / 100) + " " + fSExt[i];

      nextState.attachments_names.push({ name: file.name, size: exactSize });
    });
    this.setState(nextState);
    this.onTrigger();

    const attachmentData = new FormData();
    let countFiles = 0;

    if (nextState.attachments) {
      nextState.attachments.map((file, idx) => {
        attachmentData.append("attachments[" + idx + "]", file);
        countFiles++;
        return true;
      });
    }

    if (countFiles > 0) {
      axios
        .post(`${this.api}/ppi/attachments/${this.props.id}`, attachmentData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let attachmentsFiles = [];
          if (response.data.attachments.length > 0) {
            response.data.attachments.map((attachment) => {
              var _size = attachment.file_size;
              var fSExt = ["Bytes", "KB", "MB", "GB"],
                i = 0;
              while (_size > 900) {
                _size /= 1024;
                i++;
              }

              var exactSize =
                Math.ceil(Math.round(_size * 100) / 100) + " " + fSExt[i];
              attachmentsFiles.push({
                name: attachment.name,
                id: attachment.pk_attachments,
                size: exactSize,
                path: attachment.file,
              });
              return true;
            });
          }

          this.setState({
            attachments_names: attachmentsFiles,
            attachments: [],
          });
          NotificationManager.success(
            "Documentos adjuntos guardados correctamente",
            "¡Archivo guardado!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => {});
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
          NotificationManager.error(
            "Los documentos adjuntos no se guardaron correctamente",
            "¡Error al guardar los archivos adjuntos!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => {});
          }, 1000);
        });
    }
  };

  handleChangeArchivosFileInput = (e) => {
    var nextState = this.state;
    let values = e.target.files;

    $(".loader").show("fast", () => {});
    Array.from(values).forEach((file) => {
      nextState.archivos.push(file);
      var _size = file.size;
      var fSExt = ["Bytes", "KB", "MB", "GB"],
        i = 0;
      while (_size > 900) {
        _size /= 1024;
        i++;
      }

      var exactSize = Math.ceil(Math.round(_size * 100) / 100) + " " + fSExt[i];

      nextState.archivos_nombre.push({ name: file.name, size: exactSize });
    });
    this.setState(nextState);
    this.onTrigger();

    const attachmentData = new FormData();
    let countFiles = 0;

    if (nextState.archivos) {
      nextState.archivos.map((file, idx) => {
        attachmentData.append("estudiosocioeconomico[" + idx + "]", file);
        countFiles++;
        return true;
      });
    }

    if (countFiles > 0) {
      axios
        .post(`${this.api}/ppi/estudio/${this.props.id}`, attachmentData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((response) => {
          let attachmentsFiles = [];
          if (response.data.archivos.length > 0) {
            response.data.archivos.map((archivos) => {
              var _size = archivos.file_size;
              var fSExt = ["Bytes", "KB", "MB", "GB"],
                i = 0;
              while (_size > 900) {
                _size /= 1024;
                i++;
              }

              var exactSize =
                Math.ceil(Math.round(_size * 100) / 100) + " " + fSExt[i];
              attachmentsFiles.push({
                name: archivos.name,
                id: archivos.pk_attachments,
                size: exactSize,
                path: archivos.file,
              });
              return true;
            });
          }

          this.setState({ archivos_nombre: attachmentsFiles, archivos: [] });
          NotificationManager.success(
            "Documentos guardados correctamente",
            "¡Archivo guardado!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => {});
          }, 1000);
        })
        .catch((error) => {
          console.error(error);
          NotificationManager.error(
            "Los documentos no se guardaron correctamente",
            "¡Error al guardar los archivos!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => {});
          }, 1000);
        });
    }
  };
  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  handleShow = () => {
    this.setState({
      showModal: true,
    });
  };
  handleCloseConfirm = () => {
    this.setState({
      showModalConfirm: false,
    });
  };

  handleCloseConfirmArchivo = () => {
    this.setState({
      ShowModalArchivoConfirm: false,
    });
  };

  handleCloseConfirmImage = () => {
    this.setState({
      showModalImagesConfirm: false,
    });
  };

  handleCloseConfirmOficio = () => {
    this.setState({
      ShowModalOficioConfirm: false,
    });
  };
  handleCloseConfirmPlans = () => {
    this.setState({
      ShowModalPlansConfirm: false,
    });
  };

  handleShowConfirm = () => {
    this.setState({
      showModalConfirm: true,
    });
  };
  handleShowConfirmImage = () => {
    this.setState({
      showModalImagesConfirm: true,
    });
  };

  handleCloseDeleteImage = () => {
    this.setState({
      showModalDeleteImage: false,
      showModalDeleteImagen: false,
    });
  };

  handleShowDeleteImage = () => {
    this.setState({
      showModalDeleteImage: true,
    });
  };

  /* Agregar y Quitar Renglones en Tablas */

  //Estudios preinversion
  addRowExtraComp(tabla) {
    var nextState = this.state;
    /*
            if(tabla === "viii_id_costos"){
                if(nextState.data_sheet_extra_iccb_costs.length < 35){        
                    let newFSOrigin = {cost_type:'',description_temporality:'',quantification:'',assessment:'',periodicity:''};            
                    nextState.data_sheet_extra_iccb_costs.push(newFSOrigin);
                }else{
                    setTimeout(() => {
                        this.setState({
                            showModal: true,
                            icon: 'warning',
                            titleModal : '¡Advertencia!',
                            messageModal: 'Has superado el límite de registros en Identificación de Costos'
                        });
                    }, 100);
                }
            }
            else if(tabla === "viii_id_beneficios"){
                if(nextState.data_sheet_extra_iccb_benefits.length < 35){          
                    let newFSOrigin = {benefit:'',description:'',quantification:'',assessment:'',periodicity:''};        
                    nextState.data_sheet_extra_iccb_benefits.push(newFSOrigin);
                }else{
                    setTimeout(() => {
                        this.setState({
                            showModal: true,
                            icon: 'warning',
                            titleModal : '¡Advertencia!',
                            messageModal: 'Has superado el límite de registros en Identificación de Beneficios'
                        });
                    }, 100);
                }
            }   
            else if(tabla === "viii_id_annex_benefits"){   
                if(nextState.data_sheet_extra_annex_benefits.length < 35){  
                    let anio = nextState.data_sheet_extra_annex_benefits.length;
                    let newFSOrigin = {};
                    if(nextState.data_sheet_extra_annex_costs[anio]){
                        let investment = Number(nextState.data_sheet_extra_annex_costs[anio].investment.toString().replaceAll(',', ''));
                        if(investment > 0){
                            newFSOrigin = {ano:anio,ben1:'',ben2:'', total_actualizado: '', total_neto: '', tri: '', blocked: true};
                        }else{
                            newFSOrigin = {ano:anio,ben1:'',ben2:'', total_actualizado: '', total_neto: '', tri: '', blocked: false};
                        }
                    }else{
                        newFSOrigin = {ano:anio,ben1:'',ben2:'', total_actualizado: '', total_neto: '', tri: '', blocked: false};
                    }
                    nextState.data_sheet_extra_annex_benefits.push(newFSOrigin);
                }else{
                    setTimeout(() => {
                        this.setState({
                            showModal: true,
                            icon: 'warning',
                            titleModal : '¡Advertencia!',
                            messageModal: 'Has superado el límite de años en Cuantificación de beneficios'
                        });
                    }, 100);
                }
            }
            
            else if(tabla === "viii_id_annex_costs"){  
                if(nextState.data_sheet_extra_annex_costs.length < 35){
                    let newFSOrigin = {investment:'',operation:'',maintenance:'',externalities:'', total_actualizado: ''};    
                    nextState.data_sheet_extra_annex_costs.push(newFSOrigin);
                }else{
                    setTimeout(() => {
                        this.setState({
                            showModal: true,
                            icon: 'warning',
                            titleModal : '¡Advertencia!',
                            messageModal: 'Has superado el límite de años en Cuantificación de costos'
                        });
                    }, 100);
                }
            }*/
    if (tabla === "viii_id_preinver") {
      let newFSOrigin = {
        study_name: "",
        type_of_study: "",
        estimated_date_completion: "",
        second_estimated_date_completion: "",
        justification_realization: "",
        description: "",
        estimated_cost_amount: "",
      };
      nextState.data_sheet_extra_pre_investments.push(newFSOrigin);
    }

    this.setState(nextState);
  }

  //eliminar estudios

  removeExtras(index, tabla) {
    /*
        if(tabla === "data_sheet_extra_iccb_costs"){
            let rowSourcesProyects = [...this.state.rowSourcesProyects];
            var data_sheet_extra_iccb_costs = [...this.state.data_sheet_extra_iccb_costs];
            const findRealIdx = (element) => element === index;
            let realIndex = data_sheet_extra_iccb_costs.findIndex(findRealIdx);
            data_sheet_extra_iccb_costs.splice(realIndex, 1);
            setTimeout(() => {
                this.setState({ rowSourcesProyects, data_sheet_extra_iccb_costs });
            }, 100);
        }
        if(tabla === "data_sheet_extra_iccb_benefits"){
            let rowSourcesProyects = [...this.state.rowSourcesProyects];
            var data_sheet_extra_iccb_benefits = [...this.state.data_sheet_extra_iccb_benefits];
            const findRealIdx = (element) => element === index;
            let realIndex = data_sheet_extra_iccb_benefits.findIndex(findRealIdx);
            data_sheet_extra_iccb_benefits.splice(realIndex, 1);
            setTimeout(() => {
                this.setState({ rowSourcesProyects, data_sheet_extra_iccb_benefits });
            }, 100);
        }

        if(tabla === "data_sheet_extra_annex_benefits"){
            let rowSourcesProyects = [...this.state.rowSourcesProyects];
            var data_sheet_extra_annex_benefits = [...this.state.data_sheet_extra_annex_benefits];
            const findRealIdx = (element) => element === index;
            let realIndex = data_sheet_extra_annex_benefits.findIndex(findRealIdx);
            data_sheet_extra_annex_benefits.splice(realIndex, 1);
            setTimeout(() => {
                this.setState({ rowSourcesProyects, data_sheet_extra_annex_benefits });
            }, 100);
        }

        if(tabla === "data_sheet_extra_annex_costs"){
            let rowSourcesProyects = [...this.state.rowSourcesProyects];
            var data_sheet_extra_annex_costs = [...this.state.data_sheet_extra_annex_costs];
            const findRealIdx = (element) => element === index;
            let realIndex = data_sheet_extra_annex_costs.findIndex(findRealIdx);
            data_sheet_extra_annex_costs.splice(realIndex, 1);
            setTimeout(() => {
                this.setState({ rowSourcesProyects, data_sheet_extra_annex_costs });
            }, 100);
        }
*/
    if (tabla === "data_sheet_extra_pre_investments") {
      let rowSourcesProyects = [...this.state.rowSourcesProyects];
      var data_sheet_extra_pre_investments = [
        ...this.state.data_sheet_extra_pre_investments,
      ];
      const findRealIdx = (element) => element === index;
      let realIndex = data_sheet_extra_pre_investments.findIndex(findRealIdx);
      data_sheet_extra_pre_investments.splice(realIndex, 1);
      setTimeout(() => {
        this.setState({ rowSourcesProyects, data_sheet_extra_pre_investments });
      }, 100);
    }

    setTimeout(() => {
      this.calculateDataSheet();
      this.onTrigger();
    }, 200);
  }

  addRowSources() {
    var nextState = this.state;
    let newFSOrigin = { origin: 0, percentage: "", amount: "" };
    nextState.founding_sources_origin.push(newFSOrigin);
    this.setState(nextState);
  }

  addRowOptimizationMeasures = (e) => {
    var nextState = this.state;
    let newFSOrigin = { nom_alt: "", alternatives: "", totalCostVAT: "" };
    nextState.optimization_measures.push(newFSOrigin);
    this.setState(nextState);
  };

  removeRowSources(index) {
    var rowsSources = [...this.state.rowsSources];
    var founding_sources_origin = [...this.state.founding_sources_origin];
    const findRealIdx = (element) => element === index;
    var realIndex = founding_sources_origin.findIndex(findRealIdx);
    founding_sources_origin.splice(realIndex, 1);
    setTimeout(() => {
      this.setState({ rowsSources, founding_sources_origin });
      this.calculateTotalFunding();
    }, 100);
    this.onTrigger();
  }

  addRowCalendar() {
    if (this.state.calendar_investment.length === 100) {
      this.setState({
        showModal: true,
        icon: "warning",
        titleModal: "¡Advertencia!",
        messageModal: "Has superado el límite de meses de inversión",
      });
      return false;
    }
    let index = this.state.calendar_index + 1;
    setTimeout(() => {
      this.setState({
        calendar_index: index,
      });
      this.rerenderList();
    }, 100);

    var nextState = this.state;
    let newCalendar = { advance: `Mes ${index}`, physical: "", financial: "" };
    nextState.calendar_investment.push(newCalendar);
    this.setState(nextState);
  }

  removeRowCalendar(index) {
    var rowsCalendar = [...this.state.rowsCalendar];
    var calendar_investment = [...this.state.calendar_investment];
    const findRealIdx = (element) => element === index;
    var realIndex = calendar_investment.findIndex(findRealIdx);
    calendar_investment.splice(realIndex, 1);
    this.setState({ rowsCalendar, calendar_investment: calendar_investment });
    setTimeout(() => {
      this.calculateInvestment();
      this.onTrigger();
      this.rerenderList();
    }, 100);
  }
  rerenderList() {
    var calendar_investment = [...this.state.calendar_investment];
    let index = 0;
    for (var month in calendar_investment) {
      index++;
      calendar_investment[month].advance = `Mes ${index}`;
    }
    setTimeout(() => {
      this.setState({
        calendar_investment,
        calendar_index: index,
      });
    }, 100);
  }
  addRowComponent() {
    if (this.state.components.length === 50) {
      this.setState({
        showModal: true,
        icon: "warning",
        titleModal: "¡Advertencia!",
        messageModal: "Has superado el límite de componentes por PPI",
      });
      return false;
    }
    var nextState = this.state;
    let newComponent = {
      name_concept: "",
      description_concept: "",
      unit_of_measure: 0,
      quantity: "",
      unit_price: "",
      total_amount: "",
      number_concept: 0,
      number_item: 0,
      description_item: "",
    };
    nextState.components.push(newComponent);
    this.setState(nextState);
  }

  addRowAlineacion() {
    var nextState = this.state;
    let newAlineacion = {
      plan: "",
      eje: "",
      tema: "",
      objetivo: "",
      estrategia: "",
      linea: "",
    };
    nextState.alineacion_estrategica.push(newAlineacion);
    this.setState(nextState);
  }

  //NUEVA VERSION
  removeRowAlineacion(index) {
    this.setState(
      (prevState) => {
        const updatedRowsAlineacion = prevState.rowsAlineacion.filter(
          (row) => row !== index
        );
        const updatedComponents = prevState.alineacion_estrategica.filter(
          (component) => component !== index
        );

        return {
          rowsAlineacion: updatedRowsAlineacion,
          alineacion_estrategica: updatedComponents,
        };
      },
      () => {
        this.onTrigger(); // Llamar a onTrigger después de que el estado se haya actualizado
      }
    );
  }
  //alternativas de solucion
  addRowAlternativa() {
    var nextState = this.state;
    let newAlternativa = { alternativa: "", ventajas: "", desventajas: "" };
    nextState.alternativas_solucion.push(newAlternativa);
    this.setState(nextState);
  }

  removeRowAlternativa(index) {
    this.setState(
      (prevState) => {
        const updatedRowsAlternativa = prevState.rowsAlternativa.filter(
          (row) => row !== index
        );
        const updatedComponent = prevState.alternativas_solucion.filter(
          (component) => component !== index
        );

        return {
          rowsAlternativa: updatedRowsAlternativa,
          alternativas_solucion: updatedComponent,
        };
      },
      () => {
        this.onTrigger(); // Llamar a onTrigger después de que el estado se haya actualizado
      }
    );
  }

  addRowCostQuantification = async () => {
    var nextState = this.state;
    let newFSOrigin = {
      year: 0,
      inversion: "",
      operation: "",
      maintenance: "",
      externalities: "",
      total: 0,
      total_actualizado: 0,
    };
    if (nextState.cost_quantification.length < 100) {
      nextState.cost_quantification.push(newFSOrigin);
    }
    setTimeout(async () => {
      //await this.calculateCostQuantification();
      this.rerenderListCQ();
      this.onTrigger();
    }, 100);
    this.setState(nextState);
  };
  removeRowCostQuantification(index) {
    var cost_quantification = [...this.state.cost_quantification];
    const findRealIdx = (element) => element === index;
    var realIndex = cost_quantification.findIndex(findRealIdx);
    cost_quantification.splice(realIndex, 1);

    setTimeout(async () => {
      // await this.calculateCostQuantification();
      this.rerenderListCQ();
      this.onTrigger();
    }, 100);

    this.setState({ cost_quantification, processRemove: false });
  }

  addRowCostQuantificationAlt2 = async () => {
    var nextState = this.state;
    let newFSOrigin = {
      year: 0,
      inversion: "",
      operation: "",
      maintenance: "",
      externalities: "",
      total: 0,
    };
    if (nextState.cost_quantification_alt2.length < 100) {
      nextState.cost_quantification_alt2.push(newFSOrigin);
      //await this.calculateCostQuantificationAlt2();
      this.rerenderListCQAlt2();
    }
    this.setState(nextState);
  };
  removeRowCostQuantificationAlt2(index) {
    var cost_quantification_alt2 = [...this.state.cost_quantification_alt2];
    const findRealIdx = (element) => element === index;
    var realIndex = cost_quantification_alt2.findIndex(findRealIdx);
    cost_quantification_alt2.splice(realIndex, 1);
    this.setState({ cost_quantification_alt2 });

    setTimeout(async () => {
      // this.calculateCostQuantificationAlt2();
      this.rerenderListCQAlt2();
      this.onTrigger();
    }, 100);
  }

  addRowBenefitQuantification() {
    var nextState = this.state;
    let newFSOrigin = {};
    if (nextState.benefits_quantification.length < 100) {
      let anio = nextState.benefits_quantification.length;

      if (nextState.cost_quantification[anio]) {
        let investment = Number(
          nextState.cost_quantification[anio].inversion
            .toString()
            .replace(/,/g, "")
        );
        if (investment > 0) {
          newFSOrigin = {
            ano: anio,
            benefit_1: "",
            benefit_2: "",
            total_beneficios: "",
            total_neto: "",
            tri: "",
            blocked: true,
          };
        } else {
          newFSOrigin = {
            ano: anio,
            benefit_1: "",
            benefit_2: "",
            total_beneficios: "",
            total_neto: "",
            tri: "",
            blocked: false,
          };
        }
      } else {
        newFSOrigin = {
          ano: anio,
          benefit_1: "",
          benefit_2: "",
          total_beneficios: "",
          total_neto: "",
          tri: "",
          blocked: false,
        };
      }

      nextState.benefits_quantification.push(newFSOrigin);
      //this.calculateBenefitQuantification();
      this.rerenderListBQ();
    }
    this.setState(nextState);
  }
  removeRowBenefitQuantification(index) {
    var benefits_quantification = [...this.state.benefits_quantification];
    const findRealIdx = (element) => element === index;
    var realIndex = benefits_quantification.findIndex(findRealIdx);

    benefits_quantification.splice(realIndex, 1);
    this.setState({ benefits_quantification });

    setTimeout(() => {
      //this.calculateBenefitQuantification();
      this.rerenderListBQ();
      this.onTrigger();
    }, 100);
  }

  removeRowComponent(index) {
    var rowsComponent = [...this.state.rowsComponent];
    var components = [...this.state.components];
    const findRealIdx = (element) => element === index;
    var realIndex = components.findIndex(findRealIdx);
    components.splice(realIndex, 1);
    this.setState({ rowsComponent, components: components });
    this.forceUpdate();
    setTimeout(() => {
      this.calculateComponents();
      this.onTrigger();
    }, 100);
  }

  addRowComponentAdq() {
    if (this.state.components.length === 50) {
      this.setState({
        showModal: true,
        icon: "warning",
        titleModal: "¡Advertencia!",
        messageModal: "Has superado el límite de componentes por PPI",
      });
      return false;
    }
    var nextState = this.state;
    let newComponent = {
      number_concept: 0,
      description_concept: "",
      number_item: 0,
      description_item: "",
      name_concept: "",
      description: "",
      unit_of_measure: 0,
      quantity: "",
      unit_price: "",
      total_amount: "",
    };
    nextState.components.push(newComponent);
    this.setState(nextState);
  }
  removeRowComponentAdq(index) {
    var rowsComponent = [...this.state.rowsComponent];
    var components = [...this.state.components];
    const findRealIdx = (element) => element === index;
    var realIndex = components.findIndex(findRealIdx);
    components.splice(realIndex, 1);
    this.setState({ rowsComponent, components: components });
    this.forceUpdate();
    setTimeout(() => {
      this.calculateComponents();
      this.onTrigger();
    }, 100);
  }

  addRowGoal() {
    if (this.state.goals.length === 50) {
      this.setState({
        showModal: true,
        icon: "warning",
        titleModal: "¡Advertencia!",
        messageModal: "Has superado el límite de metas por PPI",
      });
      return false;
    }
    var nextState = this.state;
    let newGoal = { name_concept: "", unit_of_measure: 0, quantity: "" };
    nextState.goals.push(newGoal);
    this.setState(nextState);
  }
  removeRowGoal(index) {
    var rowsComponent = [...this.state.rowsComponent];
    var goals = [...this.state.goals];
    const findRealIdx = (element) => element === index;
    var realIndex = goals.findIndex(findRealIdx);
    goals.splice(realIndex, 1);
    this.setState({ rowsComponent, goals: goals });
    this.onTrigger();
  }

  removeRowOptimizationMeasures(index) {
    /*
        var rowOptimizationMeasures = [...this.state.rowOptimizationMeasures];
        var optimization_measures = [...this.state.optimization_measures];
        const findRealIdx = (element) => element === index;
        var realIndex = optimization_measures.findIndex(findRealIdx);
        optimization_measures.splice(realIndex, 1);
        setTimeout(() => {
            this.setState({ rowOptimizationMeasures, optimization_measures });
        }, 100);
        this.onTrigger();*/

    this.setState(
      (prevState) => {
        const updatedRowsOptimization =
          prevState.rowOptimizationMeasures.filter((row) => row !== index);
        const updatedComponent = prevState.optimization_measures.filter(
          (component) => component !== index
        );

        return {
          rowOptimizationMeasures: updatedRowsOptimization,
          optimization_measures: updatedComponent,
        };
      },
      () => {
        this.onTrigger(); // Llamar a onTrigger después de que el estado se haya actualizado
      }
    );
  }

  addRowCity() {
    var nextState = this.state;
    let newCity = { city: 0, delete: true };
    nextState.citySelected.push(newCity);
    this.setState(nextState);
  }
  removeRowCity(index) {
    var citySelected = [...this.state.citySelected];
    const findRealIdx = (element) => element === index;
    var realIndex = citySelected.findIndex(findRealIdx);
    citySelected.splice(realIndex, 1);
    this.setState({ citySelected });
    this.onTrigger();
  }

  rerenderList() {
    var calendar_investment = [...this.state.calendar_investment];
    let index = 0;
    for (var month in calendar_investment) {
      index++;
      calendar_investment[month].advance = `Mes ${index}`;
    }
    setTimeout(() => {
      this.setState({
        calendar_investment,
        calendar_index: index,
      });
    }, 100);
  }
  rerenderListCQ = async () => {
    var cost_quantification = [...this.state.cost_quantification];
    let index = 0;
    for (var year in cost_quantification) {
      cost_quantification[year].year = `${index}`;
      index++;
    }
    setTimeout(() => {
      this.setState({
        cost_quantification,
        cost_quantification_index: index,
      });
    }, 100);
  };
  rerenderListCQAlt2() {
    var cost_quantification_alt2 = [...this.state.cost_quantification_alt2];
    let index = 0;
    for (var year in cost_quantification_alt2) {
      cost_quantification_alt2[year].year = `${index}`;
      index++;
    }
    setTimeout(() => {
      this.setState({
        cost_quantification_alt2,
        cost_quantification_alt2_index: index,
      });
    }, 100);
  }
  rerenderListBQ = async () => {
    var benefits_quantification = [...this.state.benefits_quantification];
    let index = 0;
    for (var year in benefits_quantification) {
      benefits_quantification[year].year = `${index}`;
      index++;
    }
    setTimeout(() => {
      this.setState({
        benefits_quantification,
        benefits_quantification_index: index,
      });
    }, 100);
  };

  handleButtonClick = async () => {
    try {
      await this.calculateCostQuantification();
      await this.calculateBenefitQuantification();

      await await this.calculateCostQuantificationAlt2();
      await this.rerenderListCQ();
      await this.rerenderListBQ();

      await this.calculateComponents();

      this.onTrigger();
    } catch (error) {
      // Manejar errores si es necesario
    }
  };
  /* calculateCostQuantification() {
         const {
             cost_quantification,
             cantidadDecimales,
             tasa
         } = this.state;
     
         const updatedQuantification = cost_quantification.map((cost,idx) => {
             let total = 0;
     
             const parseAndAdd = (value) => {
                 if (typeof value === 'string' && value.length > 0) {
                     total += parseFloat(value.replaceAll(',', ''));
                 } else if (value.length > 0 || value !== 0) {
                     total += parseFloat(value);
                 }
             };
     
             parseAndAdd(cost.inversion);
             parseAndAdd(cost.operation);
             parseAndAdd(cost.maintenance);
             parseAndAdd(cost.externalities);
     
             const percenta_discount = 1 + (tasa / 100);
             const total_actualizado = total / Math.pow(percenta_discount, idx);
     
             const formattedTotal = parseFloat(total.toFixed(5));
             const formattedTotalActualizado = parseFloat(total_actualizado.toFixed(5));
     
             const cantidad = this.GetCantidadesPunto;
             const updateCantidad = (property) => {
                 if (cantidadDecimales[0][property][0].cantidad[idx] === "" || cantidadDecimales[0][property][0].cantidad[idx] === undefined) {
                     cantidadDecimales[0][property][0].type[idx] = true;
                 }
                 cantidadDecimales[0][property][0].cantidad[idx] = cantidad(cost[property]);
             };
     
             updateCantidad('cantidadInversion');
             updateCantidad('cantidadOperation');
             updateCantidad('cantidadMaintenance');
             updateCantidad('cantidadexternalities');
             updateCantidad('total_cantidad');
             updateCantidad('total_cantidad2');
     
             return {
                 ...cost,
                 total: isNaN(formattedTotal) ? 0 : formattedTotal,
                 total_actualizado: isNaN(formattedTotalActualizado) ? 0 : formattedTotalActualizado
             };
         });
     
         const {
             total_inversion,
             total_operation,
             total_maintenance,
             total_externalitie
         } = updatedQuantification.reduce((totals, cost) => {
             totals.total_inversion += cost.inversion;
             totals.total_operation += cost.operation;
             totals.total_maintenance += cost.maintenance;
             totals.total_externalitie += cost.externalities;
             return totals;
         }, {
             total_inversion: 0,
             total_operation: 0,
             total_maintenance: 0,
             total_externalitie: 0
         });
     
         const {
             total_absolute,
             total_absolute_actualizado
         } = updatedQuantification.reduce((totals, cost) => {
             totals.total_absolute += cost.total;
             totals.total_absolute_actualizado += cost.total_actualizado;
             return totals;
         }, {
             total_absolute: 0,
             total_absolute_actualizado: 0
         });
     
         setTimeout(() => {
             this.setState({
                 cost_quantification: updatedQuantification,
                 total_inversion,
                 total_operation,
                 total_maintenance,
                 total_externalitie,
                 total_absolute,
                 total_absolute_actualizado,
                 cantidadDecimales
             });
         }, 100);
         }*/

  calculateCostQuantification = async () => {
    var cost_quantification = [...this.state.cost_quantification];
    var cost_cantidadDecimal = [...this.state.cantidadDecimales];

    let total_inversion = 0,
      total_operation = 0,
      total_maintenance = 0,
      total_externalitie = 0,
      total_absolute = 0,
      total_absolute_actualizado = 0;

    const fm = new FormatMoney({
      decimals: 2,
    });

    this.state.cost_quantification.map((cost, idx) => {
      let total = 0;

      if (typeof cost.inversion == "string" && cost.inversion.length > 0) {
        total += parseFloat(cost.inversion.toString().replace(/,/g, ""));
        total_inversion += parseFloat(cost.inversion.replaceAll(",", ""));
      } else if (cost.inversion.length > 0 || cost.inversion != 0) {
        total += parseFloat(cost.inversion);
        total_inversion += parseFloat(cost.inversion);
      }

      if (typeof cost.operation == "string" && cost.operation.length > 0) {
        total += parseFloat(cost.operation.toString().replace(/,/g, ""));
        total_operation += parseFloat(
          cost.operation.toString().replace(/,/g, "")
        );
      } else if (cost.operation.length > 0 || cost.operation != 0) {
        total += parseFloat(cost.operation);
        total_operation += parseFloat(cost.operation);
      }

      if (typeof cost.maintenance == "string" && cost.maintenance.length > 0) {
        total += parseFloat(cost.maintenance.toString().replace(/,/g, ""));
        total_maintenance += parseFloat(
          cost.maintenance.toString().replace(/,/g, "")
        );
      } else if (cost.maintenance.length > 0 || cost.maintenance != 0) {
        total += parseFloat(cost.maintenance);
        total_maintenance += parseFloat(cost.maintenance);
      }

      if (
        typeof cost.externalities == "string" &&
        cost.externalities.length > 0
      ) {
        total += parseFloat(cost.externalities.toString().replace(/,/g, ""));
        total_externalitie += parseFloat(
          cost.externalities.toString().replace(/,/g, "")
        );
      } else if (cost.externalities.length > 0 || cost.externalities != 0) {
        total += parseFloat(cost.externalities);
        total_externalitie += parseFloat(cost.externalities);
      }

      let anio = idx;
      let percenta_discount = 1 + this.state.tasa / 100;
      //let total_actualizado = parseFloat((total / (Math.pow(percenta_discount, anio))).toFixed(5))
      let total_actualizado = parseFloat(
        (total / Math.pow(percenta_discount, anio)).toFixed(5)
      );

      cost_quantification[idx].total = isNaN(total)
        ? 0
        : parseFloat(parseFloat(total).toFixed(5)); //fm.from(total);
      cost_quantification[idx].total_actualizado = isNaN(total_actualizado)
        ? 0
        : parseFloat(parseFloat(total_actualizado).toFixed(5)); //fm.from(parseFloat(total_actualizado));

      total_absolute += total;
      total_absolute_actualizado += parseFloat(
        parseFloat(total_actualizado).toFixed(5)
      );

      this.setState({
        ...this.state.cantidadDecimales,
        cost_cantidadDecimal,
        cost_quantification,
      });
      return true;
    });

    total_inversion = isNaN(parseFloat(total_inversion))
      ? 0
      : parseFloat(parseFloat(total_inversion).toFixed(5)); //fm.from(total_inversion)
    total_operation = isNaN(parseFloat(total_operation))
      ? 0
      : parseFloat(parseFloat(total_operation).toFixed(5)); //fm.from(total_operation)
    total_maintenance = isNaN(parseFloat(total_maintenance))
      ? 0
      : parseFloat(parseFloat(total_maintenance).toFixed(5)); // fm.from(total_maintenance)
    total_externalitie = isNaN(parseFloat(total_externalitie))
      ? 0
      : parseFloat(parseFloat(total_externalitie).toFixed(5)); //fm.from(total_externalitie)
    total_absolute = isNaN(total_absolute)
      ? 0
      : parseFloat(parseFloat(total_absolute).toFixed(5)); //fm.from(total_absolute)
    total_absolute_actualizado = isNaN(total_absolute_actualizado)
      ? 0
      : parseFloat(parseFloat(total_absolute_actualizado).toFixed(5)); //fm.from(parseFloat(total_absolute_actualizado))

    /* CALCULO CAE */
    let resultado_anualidad = 0;
    let costo_actualizado = Number(
      total_absolute_actualizado.toString().replaceAll(",", "")
    );
    if (costo_actualizado !== undefined) {
      let tasa = this.state.tasa / 100;
      let anios = this.state.cost_quantification.length - 1;
      let percent = Math.pow(1 + Number(tasa), anios);
      let multiplica_tasa = tasa * percent;
      let resta_tasa = percent - 1;
      let resultado_division = multiplica_tasa / resta_tasa;
      resultado_anualidad =
        Number(costo_actualizado.toString().replaceAll(",", "")) *
        resultado_division;
    }

    setTimeout(() => {
      this.setState({
        cost_quantification,
        total_inversion,
        total_operation,
        total_maintenance,
        total_externalitie,
        total_absolute,
        total_absolute_actualizado,
        ppi_evaluado: resultado_anualidad,
      });
    }, 100);
  };

  calculateCostQuantificationAlt2 = async () => {
    var cost_quantification_alt2 = [...this.state.cost_quantification_alt2];

    let total_inversion_alt2 = 0,
      total_operation_alt2 = 0,
      total_maintenance_alt2 = 0,
      total_externalitie_alt2 = 0,
      total_absolute_alt2 = 0,
      total_absolute_actualizado_alt2 = 0;

    const fm = new FormatMoney({
      decimals: 2,
    });

    this.state.cost_quantification_alt2.map((cost, idx) => {
      let total = 0;
      if (typeof cost.inversion == "string" && cost.inversion.length > 0) {
        total += parseFloat(cost.inversion.replaceAll(",", ""));
        total_inversion_alt2 += parseFloat(cost.inversion.replaceAll(",", ""));
      } else if (cost.inversion.length > 0 || cost.inversion != 0) {
        total += parseFloat(cost.inversion);
        total_inversion_alt2 += parseFloat(cost.inversion);
      }

      if (typeof cost.operation == "string" && cost.operation.length > 0) {
        total += parseFloat(cost.operation.replaceAll(",", ""));
        total_operation_alt2 += parseFloat(cost.operation.replaceAll(",", ""));
      } else if (cost.operation.length > 0 || cost.operation != 0) {
        total += parseFloat(cost.operation);
        total_operation_alt2 += parseFloat(cost.operation);
      }

      if (typeof cost.maintenance == "string" && cost.maintenance.length > 0) {
        total += parseFloat(cost.maintenance.replaceAll(",", ""));
        total_maintenance_alt2 += parseFloat(
          cost.maintenance.replaceAll(",", "")
        );
      } else if (cost.maintenance.length > 0 || cost.maintenance != 0) {
        total += parseFloat(cost.maintenance);
        total_maintenance_alt2 += parseFloat(cost.maintenance);
      }

      if (
        typeof cost.externalities == "string" &&
        cost.externalities.length > 0
      ) {
        total += parseFloat(cost.externalities.replaceAll(",", ""));
        total_externalitie_alt2 += parseFloat(
          cost.externalities.replaceAll(",", "")
        );
      } else if (cost.externalities.length > 0 || cost.externalities != 0) {
        total += parseFloat(cost.externalities);
        total_externalitie_alt2 += parseFloat(cost.externalities);
      }

      let anio = idx;
      let percenta_discount = 1 + this.state.tasa / 100;
      let total_actualizado = (
        total / Math.pow(percenta_discount, anio)
      ).toFixed(5);

      cost_quantification_alt2[idx].total = isNaN(total)
        ? 0
        : parseFloat(parseFloat(total).toFixed(5)); //fm.from(total);
      cost_quantification_alt2[idx].total_actualizado = isNaN(total)
        ? 0
        : parseFloat(parseFloat(total_actualizado).toFixed(5)); //fm.from(parseFloat(total_actualizado));

      total_absolute_alt2 += total;
      total_absolute_actualizado_alt2 += parseFloat(total_actualizado);

      return true;
    });

    total_inversion_alt2 = isNaN(total_inversion_alt2)
      ? 0
      : parseFloat(parseFloat(total_inversion_alt2).toFixed(5)); //fm.from(total_inversion_alt2)
    total_operation_alt2 = isNaN(total_operation_alt2)
      ? 0
      : parseFloat(parseFloat(total_operation_alt2).toFixed(5)); //fm.from(total_operation_alt2)
    total_maintenance_alt2 = isNaN(total_maintenance_alt2)
      ? 0
      : parseFloat(parseFloat(total_maintenance_alt2).toFixed(5)); //fm.from(total_maintenance_alt2)
    total_externalitie_alt2 = isNaN(total_externalitie_alt2)
      ? 0
      : parseFloat(parseFloat(total_externalitie_alt2).toFixed(5)); //fm.from(total_externalitie_alt2)
    total_absolute_alt2 = isNaN(total_absolute_alt2)
      ? 0
      : parseFloat(parseFloat(total_absolute_alt2).toFixed(5)); //fm.from(total_absolute_alt2)
    total_absolute_actualizado_alt2 = isNaN(total_absolute_actualizado_alt2)
      ? 0
      : parseFloat(parseFloat(total_absolute_actualizado_alt2).toFixed(5)); //fm.from(parseFloat(total_absolute_actualizado_alt2))

    let resultado_anualidad_alt = 0;

    /* CALCULO CAE ALTERNATIVA */
    let costo_actualizado_alternativa = Number(
      total_absolute_actualizado_alt2.toString().replaceAll(",", "")
    );

    if (costo_actualizado_alternativa !== undefined) {
      let tasa = this.state.tasa / 100;
      let anios = this.state.cost_quantification_alt2.length - 1;
      let percent = Math.pow(1 + Number(tasa), anios);
      let multiplica_tasa = tasa * percent;
      let resta_tasa = percent - 1;
      let resultado_division = multiplica_tasa / resta_tasa;
      resultado_anualidad_alt =
        Number(costo_actualizado_alternativa.toString().replaceAll(",", "")) *
        resultado_division;
    }
    /**************/
    setTimeout(async () => {
      this.setState({
        cost_quantification_alt2,
        total_inversion_alt2,
        total_operation_alt2,
        total_maintenance_alt2,
        total_externalitie_alt2,
        total_absolute_alt2,
        total_absolute_actualizado_alt2,
        alternativa: resultado_anualidad_alt,
      });
    }, 100);
  };

  calculateBenefitQuantification = async () => {
    var benefits_quantification = [...this.state.benefits_quantification];
    let total_benefit_1 = 0,
      total_benefit_2 = 0,
      total_benefit_absolute = 0;
    let total_benefit_absolute_actualizado = 0,
      total_benefit_absolute_neto = 0;

    const fm = new FormatMoney({
      decimals: 2,
    });

    const cost = this.state.cost_quantification;

    let total_cost_actualizado = 0;
    cost.map((costo, idx) => {
      total_cost_actualizado += Number(
        costo.total_actualizado.toString().replaceAll(",", "")
      );
      return true;
    });

    this.state.benefits_quantification.map((benefit, idx) => {
      let total = 0;

      if (
        typeof benefit.benefit_1 === "string" &&
        benefit.benefit_1.length > 0
      ) {
        total += parseFloat(benefit.benefit_1.replaceAll(",", ""));
        total_benefit_1 += parseFloat(benefit.benefit_1.replaceAll(",", ""));
      } else if (benefit.benefit_1.length > 0 || benefit.benefit_1 !== 0) {
        total += !isNaN(parseFloat(benefit.benefit_1))
          ? parseFloat(benefit.benefit_1)
          : 0;
        total_benefit_1 += !isNaN(parseFloat(benefit.benefit_1))
          ? parseFloat(benefit.benefit_1)
          : 0;
      }

      if (
        typeof benefit.benefit_2 === "string" &&
        benefit.benefit_2.length > 0
      ) {
        total += parseFloat(benefit.benefit_2.replaceAll(",", ""));
        total_benefit_2 += parseFloat(benefit.benefit_2.replaceAll(",", ""));
      } else if (benefit.benefit_2.length > 0 || benefit.benefit_2 !== 0) {
        total += !isNaN(parseFloat(benefit.benefit_2))
          ? parseFloat(benefit.benefit_2)
          : 0;
        total_benefit_2 += !isNaN(parseFloat(benefit.benefit_2))
          ? parseFloat(benefit.benefit_2)
          : 0;
      }

      let anio = idx;
      let percenta_discount = 1 + this.state.tasa / 100;
      let total_actualizado = (
        total / Math.pow(percenta_discount, anio)
      ).toFixed(2);
      let neto = 0;

      if (cost[idx]) {
        let previo = cost[idx].total;
        neto = total - Number(previo);
      }

      if (Number(benefit.benefit_1) == 0 && Number(benefit.benefit_2) == 0) {
        neto = 0;
      }

      benefits_quantification[idx].total = isNaN(total) ? 0 : fm.from(total);
      benefits_quantification[idx].total_beneficios = isNaN(total_actualizado)
        ? 0
        : fm.from(parseFloat(total_actualizado));
      benefits_quantification[idx].total_neto = isNaN(neto)
        ? 0
        : fm.from(parseFloat(neto));

      total_benefit_absolute += isNaN(parseFloat(total))
        ? 0
        : parseFloat(total);
      total_benefit_absolute_actualizado += isNaN(parseFloat(total_actualizado))
        ? 0
        : parseFloat(total_actualizado);
      total_benefit_absolute_neto += isNaN(parseFloat(neto))
        ? 0
        : parseFloat(neto);

      let tri = 0;

      if (
        Number(
          benefits_quantification[idx].benefit_1.toString().replaceAll(",", "")
        ) > 0
      ) {
        /* TRI */
        let start = idx - 1;

        if (start < 0) {
          start = 0;
        }
        let sumatoria_ciclica = 0;
        let anio_pow = 0;
        let tasa = this.state.tasa / 100;

        sumatoria_ciclica += cost[start]
          ? Number(cost[start].inversion.toString().replaceAll(",", ""))
          : 0;
        // console.log("start i", start);
        if (start - 1 >= 0) {
          for (var i = start - 1; i >= 0; i--) {
            anio_pow++;
            let percent = Math.pow(1 + Number(tasa), anio_pow);
            // console.log("final_result", cost);
            // console.log("cost 0", cost[i]);
            // console.log("final_result i", i);
            var final_result = 0;
            if (cost[i]?.inversion) {
              final_result =
                Number(cost[i].inversion.toString().replaceAll(",", "")) *
                percent;
            }

            sumatoria_ciclica += final_result;
          }
          let ben_neto = Number(
            benefits_quantification[idx].total_neto
              .toString()
              .replaceAll(",", "")
          );

          tri = ben_neto / sumatoria_ciclica;
        } else {
          let ben_neto = Number(
            benefits_quantification[idx].total_neto
              .toString()
              .replaceAll(",", "")
          );
          tri = ben_neto / sumatoria_ciclica;
        }

        tri = (Number(tri) * 100).toFixed(2);
        benefits_quantification[idx].tri = tri;
        /* TERMINA TRI */
      } else {
        tri = 0;
        benefits_quantification[idx].tri = tri;
      }
      return true;
    });

    let flag = false;
    this.state.benefits_quantification.map((ben, idx) => {
      if (Number(ben.benefit_1.toString().replaceAll(",", "")) > 0 && !flag) {
        if (Number(ben.tri) > 0) {
          this.setState({
            tri: ben.tri,
            optimal_tri: idx,
          });
          flag = true;
        }
      }
      return true;
    });

    let vpn = total_benefit_absolute_actualizado - total_cost_actualizado;
    vpn = parseFloat(vpn).toFixed(2);

    total_benefit_1 = fm.from(total_benefit_1);
    total_benefit_2 = fm.from(total_benefit_2);
    total_benefit_absolute = fm.from(total_benefit_absolute);
    total_benefit_absolute_actualizado = fm.from(
      total_benefit_absolute_actualizado
    );
    total_benefit_absolute_neto = fm.from(total_benefit_absolute_neto);

    let tir = 0,
      tir_decimal = 0,
      tir_decimaldos = 0,
      tir_decimaltre = 0;
    if (Number(total_benefit_absolute.toString().replaceAll(",", "")) > 0) {
      tir = await this.calculateTIR(this.state.tasa);
      tir_decimal = await this.calculateTIRDecimals(tir);
      tir_decimaldos = await this.calculateTIRDecimalsDos(tir_decimal);
      tir_decimaltre = await this.calculateTIRDecimalsTres(tir_decimaldos);
    }

    tir_decimaltre = parseFloat(tir_decimaltre).toFixed(2);
    // this.setState({benefits_quantification, total_benefit_1, total_benefit_2, total_benefit_3, total_benefit_4, total_benefit_5, total_benefit_6,total_benefit_externalitie, total_benefit_absolute});
    this.setState({
      benefits_quantification,
      total_benefit_1,
      total_benefit_2,
      total_benefit_absolute,
      total_benefit_absolute_actualizado,
      total_benefit_absolute_neto,
      tir: tir_decimaltre,
      vpn,
    });

    setTimeout(() => {
      /* CALCULO CAE */
      let resultado_anualidad = 0,
        resultado_anualidad_alt = 0;
      let costo_actualizado = this.state.total_absolute_actualizado;
      if (costo_actualizado !== undefined) {
        let tasa = this.state.tasa / 100;
        let anios = this.state.cost_quantification.length - 1;
        let percent = Math.pow(1 + Number(tasa), anios);
        let multiplica_tasa = tasa * percent;
        let resta_tasa = percent - 1;
        let resultado_division = multiplica_tasa / resta_tasa;
        resultado_anualidad =
          Number(costo_actualizado.toString().replaceAll(",", "")) *
          resultado_division;
      }
      /**************/

      /* CALCULO CAE ALTERNATIVA */
      let costo_actualizado_alternativa =
        this.state.total_absolute_actualizado_alt2;
      if (costo_actualizado_alternativa !== undefined) {
        let tasa = this.state.tasa / 100;
        let anios = this.state.cost_quantification_alt2.length - 1;
        let percent = Math.pow(1 + Number(tasa), anios);
        let multiplica_tasa = tasa * percent;
        let resta_tasa = percent - 1;
        let resultado_division = multiplica_tasa / resta_tasa;
        resultado_anualidad_alt =
          Number(costo_actualizado_alternativa.toString().replaceAll(",", "")) *
          resultado_division;
      }
      /**************/

      this.setState({
        ppi_evaluado: fm.from(resultado_anualidad),
        alternativa: fm.from(resultado_anualidad_alt),
      });
    }, 200);
  };

  async calculateTIR(tasa) {
    let sum_costos_actualizados = 0,
      sum_ben_actualizados = 0;
    let nueva_tasa = Number(tasa) + 1;

    this.state.cost_quantification.map((costo, idx) => {
      let total_costo = 0,
        total_actualizado = 0;
      let percent_discount = 1 + nueva_tasa / 100;

      total_costo = Number(costo.total.toString().replace(/,/g, ""));
      total_actualizado = (
        total_costo / Math.pow(percent_discount, idx)
      ).toFixed(2);
      sum_costos_actualizados += parseFloat(total_actualizado);
      return true;
    });

    this.state.benefits_quantification.map((beneficio, idx) => {
      if (Number(beneficio.benefit_1) > 0) {
        let total_ben = 0,
          total_actualizado = 0;
        let percent_discount = 1 + nueva_tasa / 100;

        total_ben = Number(
          beneficio.total.toString().replace(/,/g, "")
        ).toFixed(2);
        total_actualizado = (
          total_ben / Math.pow(percent_discount, idx)
        ).toFixed(2);
        sum_ben_actualizados += parseFloat(total_actualizado);
      }
      return true;
    });
    sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
    sum_ben_actualizados = sum_ben_actualizados.toFixed(2);

    let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

    if (parseFloat(nueva_vpn) > 0 && nueva_tasa < 100) {
      return await this.calculateTIR(nueva_tasa);
    } else {
      return tasa;
    }
  }
  async calculateTIRDecimals(tasa) {
    let sum_costos_actualizados = 0,
      sum_ben_actualizados = 0;
    let nueva_tasa = Number(tasa) + 0.1;

    this.state.cost_quantification.map((costo, idx) => {
      let total_costo = 0,
        total_actualizado = 0;
      let percent_discount = 1 + nueva_tasa / 100;

      total_costo =
        Number(costo.inversion.toString().replace(/,/g, "")) +
        Number(costo.operation.toString().replace(/,/g, "")) +
        Number(costo.maintenance.toString().replace(/,/g, "")) +
        Number(costo.externalities.toString().replace(/,/g, ""));
      total_actualizado = (
        total_costo / Math.pow(percent_discount, idx)
      ).toFixed(2);

      sum_costos_actualizados += parseFloat(total_actualizado);
      return true;
    });

    this.state.benefits_quantification.map((beneficio, idx) => {
      if (Number(beneficio.benefit_1.toString().replace(/,/g, "")) > 0) {
        let total_ben = 0,
          total_actualizado = 0;
        let percent_discount = 1 + nueva_tasa / 100;

        total_ben = (
          Number(beneficio.benefit_1.toString().replace(/,/g, "")) +
          Number(beneficio.benefit_2.toString().replace(/,/g, ""))
        ).toFixed(2);
        total_actualizado = Number(
          total_ben / Math.pow(percent_discount, idx)
        ).toFixed(2);
        total_actualizado = total_actualizado * 1;
        sum_ben_actualizados += parseFloat(total_actualizado * 1);
      }
      return true;
    });

    sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
    sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
    let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

    if (nueva_vpn > 0 && nueva_tasa < 100) {
      return await this.calculateTIRDecimals(nueva_tasa);
    } else {
      return tasa.toFixed(2);
    }
  }
  async calculateTIRDecimalsDos(tasa) {
    let sum_costos_actualizados = 0,
      sum_ben_actualizados = 0;
    let nueva_tasa = Number(tasa) + 0.01;

    this.state.cost_quantification.map((costo, idx) => {
      let total_costo = 0,
        total_actualizado = 0;
      let percent_discount = 1 + nueva_tasa / 100;

      total_costo =
        Number(costo.inversion.toString().replace(/,/g, "")) +
        Number(costo.operation.toString().replace(/,/g, "")) +
        Number(costo.maintenance.toString().replace(/,/g, "")) +
        Number(costo.externalities.toString().replace(/,/g, ""));
      total_actualizado = (
        total_costo / Math.pow(percent_discount, idx)
      ).toFixed(2);
      sum_costos_actualizados += parseFloat(total_actualizado);
      return true;
    });

    this.state.benefits_quantification.map((beneficio, idx) => {
      if (Number(beneficio.benefit_1.toString().replace(/,/g, "")) > 0) {
        let total_ben = 0,
          total_actualizado = 0;
        let percent_discount = 1 + nueva_tasa / 100;

        total_ben = (
          Number(beneficio.benefit_1.toString().replace(/,/g, "")) +
          Number(beneficio.benefit_2.toString().replace(/,/g, ""))
        ).toFixed(2);
        total_actualizado = Number(
          total_ben / Math.pow(percent_discount, idx)
        ).toFixed(2);
        total_actualizado = total_actualizado * 1;

        sum_ben_actualizados += parseFloat(total_actualizado * 1);
      }
      return true;
    });
    sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
    sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
    let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

    if (nueva_vpn > 0 && nueva_tasa < 100) {
      return await this.calculateTIRDecimalsDos(nueva_tasa);
    } else {
      return tasa;
    }
  }
  async calculateTIRDecimalsTres(tasa) {
    let sum_costos_actualizados = 0,
      sum_ben_actualizados = 0;
    let nueva_tasa = Number(tasa) + 0.001;

    this.state.cost_quantification.map((costo, idx) => {
      let total_costo = 0,
        total_actualizado = 0;
      let percent_discount = 1 + nueva_tasa / 100;

      total_costo =
        Number(costo.inversion) +
        Number(costo.operation) +
        Number(costo.maintenance) +
        Number(costo.externalities);
      total_actualizado = (
        total_costo / Math.pow(percent_discount, idx)
      ).toFixed(2);

      sum_costos_actualizados += parseFloat(total_actualizado);
      return true;
    });

    this.state.benefits_quantification.map((beneficio, idx) => {
      if (Number(beneficio.benefit_1) > 0) {
        let total_ben = 0,
          total_actualizado = 0;
        let percent_discount = 1 + nueva_tasa / 100;

        total_ben = (
          Number(beneficio.benefit_1) + Number(beneficio.benefit_2)
        ).toFixed(2);
        total_actualizado = Number(
          total_ben / Math.pow(percent_discount, idx)
        ).toFixed(2);
        total_actualizado = total_actualizado * 1;

        sum_ben_actualizados += parseFloat(total_actualizado * 1);
      }
      return true;
    });
    sum_costos_actualizados = sum_costos_actualizados.toFixed(2);
    sum_ben_actualizados = sum_ben_actualizados.toFixed(2);
    let nueva_vpn = (sum_ben_actualizados - sum_costos_actualizados).toFixed(2);

    if (nueva_vpn > 0) {
      return await this.calculateTIRDecimalsTres(nueva_tasa);
    } else {
      this.setState({
        annexed_tir: tasa,
      });
      return tasa;
    }
  }

  /* ONCLICK Y CHANGE */

  onClickhandlerInversion = (e) => {
    {
      let idx = e.target.dataset.idx;
      var cost_quantification = [...this.state.cost_quantification];

      /*this.state.cost_quantification.map((cost, idx) => {
                try {


                    this.state.cantidadDecimales[0].cantidadInversion[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadOperation[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadMaintenance[0].type[idx] = true;


                    this.state.cantidadDecimales[0].cantidadexternalities[0].type[idx] = true;


                    this.state.cantidadDecimales[0].total_cantidad[0].type[idx] = true;


                    this.state.cantidadDecimales[0].total_cantidad2[0].type[idx] = true;

                    this.setState({ ...this.state.cantidadDecimales });

                }
                catch (err) {
                    // alert(err);
                    console.log(err);
                }
            })*/
      this.state.cantidadDecimales[0].cantidadInversion[0].type[idx] = this
        .state.cantidadDecimales[0].cantidadInversion[0].type[idx]
        ? true
        : false;

      this.state.cantidadDecimales[0].cantidadInversion[0].type[idx] = false;
      this.setState({ ...this.state.cantidadDecimales });
    }
  };

  handleChangeCost = (e, key) => {
    const idx = e.target.dataset.idx;
    const { cost_quantification } = this.state;
    const value = e.target.value;
    const regexp = /^-?[0-9,.\b]+$/;

    if (value === "" || regexp.test(value)) {
      cost_quantification[idx][key] = value;

      this.setState({
        cost_quantification,
      });
    }
  };
  handleChangeCostAlt2 = (e, key) => {
    const idx = e.target.dataset.idx;
    const { cost_quantification_alt2 } = this.state;
    const value = e.target.value;
    const regexp = /^-?[0-9,.\b]+$/;

    if (value === "" || regexp.test(value)) {
      cost_quantification_alt2[idx][key] = value;

      this.setState({
        cost_quantification_alt2,
      });
    }
  };

  handleChangeBenefitQuantification = (e, key) => {
    const idx = e.target.dataset.idx;
    const { benefits_quantification } = this.state;
    const value = e.target.value;
    const regexp = /^-?[0-9,.\b]+$/;

    if (value === "" || regexp.test(value)) {
      benefits_quantification[idx][key] = value;

      this.setState({
        benefits_quantification,
      });
    }
  };

  onChangehandlerBenefitTotal = (e) => {
    const idx = e.target.dataset.idx;
    const benefits_quantification = this.state.benefits_quantification;
    var regexp = /^[0-9,.\b]+$/;

    if (e.target.value === "" || regexp.test(e.target.value)) {
      benefits_quantification[idx].total = e.target.value;
      this.setState({
        benefits_quantification,
      });
      this.calculateBenefitQuantification();
      this.onTrigger();
    }
  };

  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};

    data[name] = value;

    if(name=='tipo_bien' && value == 1){
      data['numero_catastro'] = 0;
    }

    if(name=='tipo_bien' && value == 2){
      data['numero_catastro'] = 1;
    }
    console.log("Nombre2 del campo:", name);
    console.log("Valor ingresado:", value);
    console.log("Objeto data actualizado:", data);

    this.setState(data, () => {
      console.log("Nuevo estado:", this.state);
    });

    this.onTrigger();
  };

  onChangehandlerExternalities = (e) => {
    const idx = e.target.dataset.idx;
    const cost_quantification = this.state.cost_quantification;
    var regexp = /^[0-9,.\b]+$/;

    if (e.target.value === "" || regexp.test(e.target.value)) {
      cost_quantification[idx].externalities = e.target.value;

      this.setState({
        cost_quantification,
        ...this.state.cantidadDecimales,
      });
      this.calculateCostQuantification();
      this.onTrigger();
    }
  };

  onClickhandlerexternalities = (e) => {
    {
      let idx = e.target.dataset.idx;
      var cost_quantification = [...this.state.cost_quantification];

      this.state.cost_quantification.map((cost, idx) => {
        try {
          this.state.cantidadDecimales[0].cantidadInversion[0].type[idx] = true;

          this.state.cantidadDecimales[0].cantidadOperation[0].type[idx] = true;

          this.state.cantidadDecimales[0].cantidadMaintenance[0].type[
            idx
          ] = true;

          this.state.cantidadDecimales[0].cantidadexternalities[0].type[
            idx
          ] = true;

          this.state.cantidadDecimales[0].total_cantidad[0].type[idx] = true;

          this.state.cantidadDecimales[0].total_cantidad2[0].type[idx] = true;

          this.setState({ ...this.state.cantidadDecimales });
        } catch (err) {
          // alert(err);
          console.log(err);
        }
      });
      this.state.cantidadDecimales[0].cantidadexternalities[0].type[idx] = this
        .state.cantidadDecimales[0].cantidadexternalities[0].type[idx]
        ? true
        : false;

      this.state.cantidadDecimales[0].cantidadexternalities[0].type[
        idx
      ] = false;
      this.setState({ ...this.state.cantidadDecimales });
    }
  };

  handleClickSomeButton = () => {
    const saveWithObservationsButton =
      this.sideBarRef.current.saveWithObservationsButtonRef.current;
    const returnWithObservationsButton =
      this.sideBarRef.current.returnWithObservationsButtonRef.current;
    saveWithObservationsButton.click();

    // Accede a los botones mediante las referencias
    const approveButton = this.sideBarRef.current.approveButtonRef.current;
    const cancelButton = this.sideBarRef.current.cancelButtonRef.current;
  };

  render() {
    const fm = new FormatMoney({
      decimals: 2,
    });
    let id = this.props.id;
    let status = this.props.status;
    let user = JSON.parse(localStorage.getItem("user"));
    let message = "";
    let botonesobservaciones = "";
    let seccion1 = "";
    let seccion2 = "";
    let seccion3 = "";
    let seccion4 = "";
    let seccion5 = "";
    let seccion6 = "";
    let seccion8 = "";

    let seccion9 = "";
    let tabla1 = "";
    //let tabla2 = "";
    //let tabla3 = "";
    let seccion7 = "";
    let isAdmin = false;
    let VisualizarTodos = false;
    let adminInRevision = false;
    console.log("Renderizando con estado:", this.state);

    const { tipo_bien, tipo_bien_descripcion } = this.state;

    //  let isPropio = tipo_bien === "1";
    let isPropio = String(tipo_bien) === "2";

    let isOtro = String(tipo_bien_descripcion) === "2";

    let Iscatastro = String(tipo_bien) !== "1";

    console.log("tipo_bien:", tipo_bien);
    console.log("isPropio:", isPropio);
    console.log("tipo_bien_descripcion:", tipo_bien_descripcion);

    console.log("catastro:", Iscatastro);
    //  const isOtro = tipo_bien_descripcion === "2";

    if (user.fk_role === 1 && status === 5) {
      botonesobservaciones = (
        <SideBarComments
          comments={this.state}
          sections={this.state}
          botones={true}
          id={this.props.id}
        />
      );
    }

    if (user.fk_role === 1 && status === 6) {
      adminInRevision = true;

      botonesobservaciones = (
        <SideBarComments
          comments={this.state}
          sections={this.state}
          botones={true}
          id={this.props.id}
        />
      );
    }
    if (user.fk_role === 1) {
      isAdmin = true;
    }
    var isResponsable =
      this.state.id_responsable === user.pk_user ? true : false;
    var pd_disabled =
      !adminInRevision && this.state.problem_description_approved === true
        ? true
        : false;
    var gd_disabled =
      !adminInRevision && this.state.general_description_approved === true
        ? true
        : false;
    var ob_disabled =
      !adminInRevision && this.state.objective_approved === true ? true : false;
    var gi_disabled =
      !adminInRevision && this.state.general_information_approved === true
        ? true
        : false;

    var er_disabled =
      !adminInRevision && this.state.exesution_results_approved === true
        ? true
        : false;
    var sal_disabled =
      !adminInRevision && this.state.solution_alternatives_approved === true
        ? true
        : false;
    var fe_disabled =
      !adminInRevision && this.state.feasibilities_approved === true
        ? true
        : false;
    var cg_disabled =
      !adminInRevision && this.state.general_considerations_approved === true
        ? true
        : false;
    var da_disabled =
      !adminInRevision && this.state.attachments_approved === true
        ? true
        : false;

    if (this.props.formato_PPI > 1) {
      VisualizarTodos = false;
    }

    seccion1 = (
      <>
        <div className="row title-section" disabled={VisualizarTodos}>
          <div className="col-10">Información general del PPI</div>

          {adminInRevision &&
            isResponsable &&
            this.state.general_information_approved === false && (
              <div className="col-2">
                <button
                  className="btn btn-success btn-circle"
                  onClick={() => this.setDoneByAdmin("general_information")}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <button
                  className="btn btn-orange btn-circle"
                  onClick={this.AddCommentGIP}
                >
                  <div className="icon material-icons">comment</div>
                </button>
              </div>
            )}
          {adminInRevision &&
            isResponsable &&
            this.state.general_information_approved === true && (
              <div className="col-2">
                <button
                  className="btn btn-black btn-circle"
                  onClick={() => this.setUndoByAdmin("general_information")}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </button>
              </div>
            )}
          {!adminInRevision &&
            this.state.general_information_approved === true && (
              <div className="col-2">
                <span className="section-approved">Aprobada</span>
              </div>
            )}
        </div>

        <div className="row wrap-section">
          <div className="col-12 not-padding">
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row">
                  <label className="col-lg-5 col-sm-12">Nombre del PPI</label>
                  <div className="col-lg-7 col-sm-12">
                    <OverlayTrigger
                      key={"bottom"}
                      placement={"bottom"}
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          {this.state.name_ppi}
                        </Tooltip>
                      }
                    >
                      {!isAdmin && status === 5 && !gi_disabled ? (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Escribe aquí"
                          name="name_ppi"
                          value={this.state.name_ppi}
                          onChange={this.onChangehandler}
                        />
                      ) : (
                        <input
                          type="text"
                          className="form-control"
                          placeholder="Escribe aquí"
                          name="name_ppi"
                          value={this.state.name_ppi}
                          onChange={this.onChangehandler}
                          disabled
                        />
                      )}
                    </OverlayTrigger>
                  </div>
                </div>

                <div className="row">
                  <label className="col-lg-5 col-sm-12">Monto</label>
                  <div className="col-lg-7 col-sm-12">
                    <OverlayTrigger
                      key={"bottom"}
                      placement={"bottom"}
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          $ {this.state.amount}
                        </Tooltip>
                      }
                    >
                      <div class="input-group mb-3">
                        <div class="input-group-prepend">
                          <span class="input-group-text">$</span>
                        </div>
                        {!isAdmin && status === 5 && !gi_disabled ? (
                          <NumberFormat
                            type="text"
                            className="form-control"
                            placeholder="$"
                            name="amount"
                            thousandSeparator={true}
                            value={this.state.amount}
                            onChange={this.onChangehandler}
                          />
                        ) : (
                          <NumberFormat
                            type="text"
                            className="form-control"
                            placeholder="$"
                            name="amount"
                            thousandSeparator={true}
                            value={this.state.amount}
                            onChange={this.onChangehandler}
                            disabled
                          />
                        )}
                      </div>
                    </OverlayTrigger>
                  </div>
                </div>

                <div className="row">
                  <label className="col-lg-5 col-sm-12">Tipo de PPI</label>
                  <div className="col-lg-7 col-sm-12">
                    <OverlayTrigger
                      key={"bottom"}
                      placement={"bottom"}
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          {this.state.type_ppi}
                        </Tooltip>
                      }
                    >
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Escribe aquí"
                        name="type_ppi"
                        value={this.state.type_ppi}
                        onChange={this.onChangehandler}
                        disabled
                      />
                    </OverlayTrigger>
                  </div>
                </div>

                {this.state.citySelected.map((city, idx) => (
                  <div className="row">
                    <label className="col-lg-5 col-sm-12">Municipio(s)</label>
                    <div className={"col-lg-5 col-sm-12"}>
                      <Form.Control
                        size="sm"
                        as="select"
                        name="citySelected"
                        onChange={this.onChangehandlerCity}
                        disabled={
                          (isAdmin && gi_disabled) ||
                          isAdmin ||
                          (!isAdmin && gi_disabled)
                        }
                        data-idx={idx}
                        key={"citie_" + idx}
                        value={this.state.citySelected[idx].city}
                      >
                        <option value="0" disabled>
                          Seleccionar
                        </option>
                        {this.state.cities.map((type, idx) => (
                          <option name="citySelected" value={type.value}>
                            {type.name}
                          </option>
                        ))}
                      </Form.Control>
                    </div>
                    {!isAdmin && !gi_disabled && (
                      <div className="col-1">
                        {!this.state.citySelected[idx].delete ? (
                          <button
                            className="col-12 btn btn-link"
                            onClick={this.addRowCity}
                          >
                            <FontAwesomeIcon icon={faPlusCircle} size={"2x"} />
                          </button>
                        ) : (
                          <button
                            className="col-12 btn btn-link delete-row"
                            onClick={() => this.removeRowCity(city, idx)}
                          >
                            <FontAwesomeIcon icon={faTimesCircle} size={"2x"} />
                          </button>
                        )}
                      </div>
                    )}
                  </div>
                ))}
                <div className="row">
                  <label className="col-lg-5 col-sm-12">
                    Dependencia solicitante
                  </label>
                  <div className="col-lg-7 col-sm-12">
                    <OverlayTrigger
                      key={"bottom"}
                      placement={"bottom"}
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          {this.state.dependency_applicant}
                        </Tooltip>
                      }
                    >
                      <input
                        type="text"
                        className="form-control"
                        name="dependency_applicant"
                        value={this.state.dependency_applicant}
                        onChange={this.onChangehandler}
                        disabled
                      />
                    </OverlayTrigger>
                  </div>
                </div>
                <div className="row">
                  <label className="col-lg-5 col-sm-12">
                    Dependencia responsable
                  </label>
                  <div
                    className="col-lg-7 col-sm-12"
                    style={{ "text-align": "left" }}
                  >
                    <OverlayTrigger
                      key={"bottom"}
                      placement={"bottom"}
                      overlay={
                        <Tooltip id={`tooltip-bottom`}>
                          {this.state.dependencies.map((type, idx) =>
                            type.value == this.state.dependency_executor
                              ? type.name
                              : ""
                          )}
                        </Tooltip>
                      }
                    >
                      {!isAdmin && !gi_disabled ? (
                        <Form.Control
                          size="sm"
                          as="select"
                          name="dependency_executor"
                          disabled={
                            (isAdmin && gi_disabled) ||
                            isAdmin ||
                            (!isAdmin && gi_disabled)
                          }
                          onChange={this.onChangehandler}
                          value={this.state.dependency_executor}
                        >
                          <option value="0" disabled>
                            Seleccionar
                          </option>
                          {this.state.dependencies.map((type, idx) => (
                            <option
                              key={idx}
                              name="dependency_executor"
                              value={type.value}
                            >
                              {type.name}
                            </option>
                          ))}
                        </Form.Control>
                      ) : (
                        <span>
                          {this.state.dependencies.map((type, idx) =>
                            type.value == this.state.dependency_executor
                              ? type.name
                              : ""
                          )}
                        </span>
                      )}
                    </OverlayTrigger>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-10 offset-1">
                <div className="row subtitle-section">
                  <div className="col-10">
                    <h6>Fuentes de financiamiento</h6>
                  </div>
                  {!isAdmin && !gi_disabled && (
                    <div className="col-2">
                      <button
                        className="col-12 btn btn-link"
                        onClick={this.addRowSources}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </button>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <Table bordered className="table-not-padding">
                      <thead>
                        <tr>
                          <td>
                            <strong>Origen</strong>
                          </td>
                          <td>
                            <strong>Fondo</strong>
                          </td>
                          <td>
                            <strong>%</strong>
                          </td>
                          <td>
                            <strong>Monto</strong>
                          </td>
                          {!isAdmin && !gi_disabled && <td></td>}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.founding_sources_origin.map(
                          (row, index) => (
                            <tr key={"origin_" + index}>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      {this.state.funding_sources.map(
                                        (type, idx) =>
                                          type.value ==
                                          this.state.founding_sources_origin[
                                            index
                                          ].origin
                                            ? type.name
                                            : ""
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  {!isAdmin && !gi_disabled ? (
                                    <Form.Control
                                      size="sm"
                                      as="select"
                                      name="founding_sources_origin"
                                      className="d-block form-control border-0 size-14 text-center amount-txt"
                                      onChange={this.onChangehandlerOrigin}
                                      data-idx={index}
                                      disabled={
                                        (isAdmin && gi_disabled) ||
                                        isAdmin ||
                                        (!isAdmin && gi_disabled)
                                      }
                                      value={
                                        this.state.founding_sources_origin[
                                          index
                                        ].origin
                                      }
                                    >
                                      <option value="0" disabled>
                                        Seleccionar
                                      </option>
                                      {this.state.funding_sources.map(
                                        (type, idx) => (
                                          <option
                                            key={idx}
                                            name="founding_sources_origin"
                                            value={type.value}
                                          >
                                            {type.name}
                                          </option>
                                        )
                                      )}
                                    </Form.Control>
                                  ) : (
                                    <span>
                                      {this.state.funding_sources.map(
                                        (type, idx) =>
                                          type.value ==
                                          this.state.founding_sources_origin[
                                            index
                                          ].origin
                                            ? type.name
                                            : ""
                                      )}
                                    </span>
                                  )}
                                </OverlayTrigger>
                              </td>
                              <td>
                                {
                                  this.state.founding_sources_origin[index]
                                    .subsourceFlag
                                }
                                {this.state.founding_sources_origin[index]
                                  .subsourceFlag === true ? (
                                  <OverlayTrigger
                                    key={"bottom"}
                                    placement={"bottom"}
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        {this.state.funding_subsources.map(
                                          (type, idx) =>
                                            type.value ==
                                            this.state.founding_sources_origin[
                                              index
                                            ].subsource
                                              ? type.name
                                              : ""
                                        )}
                                      </Tooltip>
                                    }
                                  >
                                    {!isAdmin && !gi_disabled ? (
                                      <Form.Control
                                        size="sm"
                                        as="select"
                                        name="founding_sources_origin"
                                        className="d-block form-control border-0 size-14 text-center amount-txt"
                                        onChange={this.onChangehandlerSubsource}
                                        data-idx={index}
                                        disabled={
                                          (isAdmin && gi_disabled) ||
                                          isAdmin ||
                                          (!isAdmin && gi_disabled)
                                        }
                                        value={
                                          this.state.founding_sources_origin[
                                            index
                                          ].subsource
                                        }
                                      >
                                        <option value="0" disabled>
                                          Seleccionar
                                        </option>
                                        {this.state.funding_subsources.map(
                                          (type, idx) => (
                                            <option
                                              key={idx}
                                              name="founding_sources_origin"
                                              value={type.value}
                                            >
                                              {type.name}
                                            </option>
                                          )
                                        )}
                                      </Form.Control>
                                    ) : (
                                      <span>
                                        {this.state.funding_subsources.map(
                                          (type, idx) =>
                                            type.value ==
                                            this.state.founding_sources_origin[
                                              index
                                            ].subsource
                                              ? type.name
                                              : ""
                                        )}
                                      </span>
                                    )}
                                  </OverlayTrigger>
                                ) : null}

                                {
                                  this.state.founding_sources_origin[index]
                                    .otherFlag
                                }
                                {this.state.founding_sources_origin[index]
                                  .otherFlag === true ? (
                                  <OverlayTrigger
                                    key={"bottom"}
                                    placement={"bottom"}
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        {
                                          this.state.founding_sources_origin[
                                            index
                                          ].other
                                        }
                                      </Tooltip>
                                    }
                                  >
                                    <input
                                      type="text"
                                      name={`founding_sources_origin`}
                                      className="d-block form-control border-0 size-14 text-center amount-txt"
                                      placeholder="Escribe aquí"
                                      value={
                                        this.state.founding_sources_origin[
                                          index
                                        ].other
                                      }
                                      data-idx={index}
                                      disabled={
                                        (isAdmin && gi_disabled) ||
                                        isAdmin ||
                                        (!isAdmin && gi_disabled)
                                      }
                                      onChange={this.onChangehandlerOther}
                                    />
                                  </OverlayTrigger>
                                ) : null}
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      {
                                        this.state.founding_sources_origin[
                                          index
                                        ].percentage
                                      }{" "}
                                      %
                                    </Tooltip>
                                  }
                                >
                                  <input
                                    type="text"
                                    name={`founding_sources_origin`}
                                    className="d-block form-control border-0 size-14 text-center amount-txt"
                                    placeholder="Escribe aquí"
                                    value={
                                      this.state.founding_sources_origin[index]
                                        .percentage
                                    }
                                    data-idx={index}
                                    disabled={
                                      (isAdmin && gi_disabled) ||
                                      isAdmin ||
                                      (!isAdmin && gi_disabled)
                                    }
                                    onChange={this.onChangehandlerPercentage}
                                  />
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      ${" "}
                                      {fm.from(
                                        parseFloat(
                                          this.state.founding_sources_origin[
                                            index
                                          ].amount
                                        )
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    className="d-block form-control border-0 size-14 text-center amount-txt"
                                    placeholder="Escribe aquí"
                                    value={
                                      this.state.founding_sources_origin[index]
                                        .amount
                                    }
                                    data-idx={index}
                                    onChange={this.onChangehandlerAmount}
                                    disabled
                                    thousandSeparator={true}
                                  />
                                </OverlayTrigger>
                              </td>
                              {!isAdmin && !gi_disabled && (
                                <td>
                                  <button
                                    className="col-12 btn btn-link delete-row"
                                    data-row={row}
                                    onClick={() => this.removeRowSources(row)}
                                  >
                                    <FontAwesomeIcon
                                      icon={faTimesCircle}
                                      size={"2x"}
                                    />
                                  </button>
                                </td>
                              )}
                            </tr>
                          )
                        )}
                      </tbody>
                    </Table>
                  </div>
                </div>
                {/*DIV ROW*/}
                <div className="col-md-12 ">
                  <div className="row results-investment">
                    <div className="col-3">Porcentaje (%)</div>
                    <div className="col-3">
                      {this.state.total_percentage_funding}%
                    </div>
                    <div className="col-3">Total ($)</div>
                    <div className="col-3">
                      $ {this.state.total_amount_funding}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row subtitle-section">
                  <div className="col-10">
                    <h6>Calendario de inversión</h6>
                  </div>
                  {!isAdmin && !gi_disabled && (
                    <div className="col-2">
                      <button
                        className="col-12 btn btn-link"
                        onClick={this.addRowCalendar}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} size={"2x"} />
                      </button>
                    </div>
                  )}
                </div>

                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <Table bordered className="table-not-padding">
                      <thead>
                        <tr>
                          <td>
                            <strong>Avance</strong>
                          </td>
                          <td>
                            <strong>Físico (%)</strong>
                          </td>
                          <td>
                            <strong>Financiero ($)</strong>
                          </td>
                          {!isAdmin && !gi_disabled && <td></td>}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.calendar_investment.map((row, index) => (
                          <tr key={"ci_" + index}>
                            <td>
                              <OverlayTrigger
                                key={"bottom"}
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    {
                                      this.state.calendar_investment[index]
                                        .advance
                                    }
                                  </Tooltip>
                                }
                              >
                                <input
                                  type="text"
                                  name={`calendar_investment`}
                                  className="d-block form-control border-0 size-14 text-center amount-txt"
                                  placeholder="Escribe aquí"
                                  value={
                                    this.state.calendar_investment[index]
                                      .advance
                                  }
                                  data-idx={index}
                                  disabled
                                  onChange={this.onChangehandlerAdvance}
                                />
                              </OverlayTrigger>
                            </td>
                            <td>
                              <OverlayTrigger
                                key={"bottom"}
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    {
                                      this.state.calendar_investment[index]
                                        .physical
                                    }{" "}
                                    %
                                  </Tooltip>
                                }
                              >
                                <input
                                  type="text"
                                  name={`calendar_investment`}
                                  className="d-block form-control border-0 size-14 text-center amount-txt"
                                  placeholder="Escribe aquí"
                                  value={
                                    this.state.calendar_investment[index]
                                      .physical
                                  }
                                  data-idx={index}
                                  disabled={
                                    (isAdmin && gi_disabled) ||
                                    isAdmin ||
                                    (!isAdmin && gi_disabled)
                                  }
                                  onChange={this.onChangehandlerPhysical}
                                />
                              </OverlayTrigger>
                            </td>
                            <td>
                              <OverlayTrigger
                                key={"bottom"}
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    ${" "}
                                    {fm.from(
                                      parseFloat(
                                        this.state.calendar_investment[index]
                                          .financial
                                      )
                                    )}
                                  </Tooltip>
                                }
                              >
                                <NumberFormat
                                  className="d-block form-control border-0 size-14 text-center amount-txt"
                                  placeholder="Escribe aquí"
                                  value={
                                    this.state.calendar_investment[index]
                                      .financial
                                  }
                                  data-idx={index}
                                  disabled={
                                    (isAdmin && gi_disabled) ||
                                    isAdmin ||
                                    (!isAdmin && gi_disabled)
                                  }
                                  onChange={this.onChangehandlerFinancial}
                                  thousandSeparator={true}
                                />
                              </OverlayTrigger>
                            </td>
                            {!isAdmin && !gi_disabled && (
                              <td>
                                <button
                                  className="col-12 btn btn-link delete-row"
                                  data-row={row}
                                  onClick={() => this.removeRowCalendar(row)}
                                >
                                  <FontAwesomeIcon icon={faTimesCircle} />
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>

                {/**DIV ROW */}
                <div className="col-lg-12">
                  <div className="row results-investment">
                    <div className="col-3">Total físico (100%)</div>
                    <div className="col-3">{this.state.total_physical}%</div>
                    <div className="col-3">Total financiero ($)</div>
                    <div className="col-3">
                      $ {this.state.total_financial_currency_format}
                    </div>
                  </div>
                </div>

                <div className="row subtitle-section">
                  <div className="col-3 text-left">
                    <h6>Vida útil</h6>
                  </div>
                  <div className="col-3">
                    {!isAdmin && !gi_disabled ? (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0"
                        name="vida_util"
                        value={this.state.vida_util}
                        onChange={this.onChangehandler}
                      />
                    ) : (
                      <div style={{ textAlign: "left" }}>
                        {this.state.vida_util}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row subtitle-section">
                  <div className="col-12">
                    <h6>Localización geográfica</h6>
                  </div>
                </div>
                <div className="row">
                  {!isAdmin && !gi_disabled ? (
                    <div className="col-lg-12">
                      <PlacesWithStandaloneSearchBox
                        amount={this.state.amount}
                        coordinates={this.state.coordinatesData}
                        id={id}
                        parentCallback={this.handleCallback}
                      />
                    </div>
                  ) : (
                    <div className="col-lg-12" style={{ height: "400px" }}>
                      <MyComponent
                        amount={this.state.amount}
                        disabled={true}
                        coordinates={this.state.coordinatesData}
                        id={id}
                      />
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row d-flex">
                      <div className="col-lg-12">
                        <div className="row subtitle-section">
                          <div className="col-12">
                            <h6>Observaciones</h6>
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-12">
                            {!isAdmin && !gi_disabled ? (
                              <textarea
                                className="col-12"
                                placeholder="Escribe aquí"
                                name="observations_location"
                                value={this.state.observations_location}
                                onChange={this.onChangehandler}
                              ></textarea>
                            ) : (
                              <div
                                style={{
                                  textAlign: "justify",
                                  "white-space": "pre-line",
                                }}
                              >
                                {this.state.observations_location}
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-lg-12">
                    <div className="row subtitle-section">
                      <div className="col-10">
                        <h6>Alineación estratégica</h6>
                      </div>
                      <div className="col-2">
                        {!isAdmin && !gi_disabled && (
                          <button
                            className="col-12 btn btn-link"
                            onClick={this.addRowAlineacion}
                          >
                            <FontAwesomeIcon icon={faPlusCircle} size={"2x"} />
                          </button>
                        )}
                      </div>
                    </div>
                    {this.state.alineacion_estrategica.map((row, index) => (
                      <div className="row d-flex">
                        <div className="col-lg-12">
                          <Table bordered className="table-not-padding">
                            <tbody>
                              <tr>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "left",
                                    width: "150px",
                                  }}
                                >
                                  <strong>Plan o programa</strong>
                                </td>

                                <td>
                                  <OverlayTrigger
                                    key={"bottom"}
                                    placement={"bottom"}
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        {
                                          this.state.alineacion_estrategica[
                                            index
                                          ].plan
                                        }
                                      </Tooltip>
                                    }
                                  >
                                    <textarea
                                      type="text"
                                      name={`plan`}
                                      style={{
                                        verticalAlign: "middle",
                                        "margin-top": "38px",
                                      }}
                                      className="col-12"
                                      placeholder="Escribe aquí"
                                      value={
                                        this.state.alineacion_estrategica[index]
                                          .plan
                                      }
                                      data-idx={index}
                                      disabled={
                                        (isAdmin && gi_disabled) ||
                                        isAdmin ||
                                        (!isAdmin && gi_disabled)
                                      }
                                      onChange={this.onChangehandlerAlineacion_}
                                    />
                                  </OverlayTrigger>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "left",
                                  }}
                                >
                                  <strong>Eje</strong>
                                </td>

                                <td>
                                  <OverlayTrigger
                                    key={"bottom"}
                                    placement={"bottom"}
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        {
                                          this.state.alineacion_estrategica[
                                            index
                                          ].eje
                                        }
                                      </Tooltip>
                                    }
                                  >
                                    <textarea
                                      type="text"
                                      name={`eje`}
                                      className="col-12"
                                      style={{
                                        verticalAlign: "middle",
                                        "margin-top": "38px",
                                      }}
                                      placeholder="Escribe aquí"
                                      value={
                                        this.state.alineacion_estrategica[index]
                                          .eje
                                      }
                                      data-idx={index}
                                      disabled={
                                        (isAdmin && gi_disabled) ||
                                        isAdmin ||
                                        (!isAdmin && gi_disabled)
                                      }
                                      onChange={this.onChangehandlerAlineacion_}
                                    />
                                  </OverlayTrigger>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "left",
                                  }}
                                >
                                  <strong>Tema</strong>
                                </td>

                                <td>
                                  <OverlayTrigger
                                    key={"bottom"}
                                    placement={"bottom"}
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        {
                                          this.state.alineacion_estrategica[
                                            index
                                          ].tema
                                        }
                                      </Tooltip>
                                    }
                                  >
                                    <textarea
                                      type="text"
                                      name={`tema`}
                                      className="col-12"
                                      style={{
                                        verticalAlign: "middle",
                                        "margin-top": "38px",
                                      }}
                                      placeholder="Escribe aquí"
                                      value={
                                        this.state.alineacion_estrategica[index]
                                          .tema
                                      }
                                      data-idx={index}
                                      disabled={
                                        (isAdmin && gi_disabled) ||
                                        isAdmin ||
                                        (!isAdmin && gi_disabled)
                                      }
                                      onChange={this.onChangehandlerAlineacion_}
                                    />
                                  </OverlayTrigger>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "left",
                                  }}
                                >
                                  <strong>Objetivo</strong>
                                </td>
                                <td>
                                  <OverlayTrigger
                                    key={"bottom"}
                                    placement={"bottom"}
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        {
                                          this.state.alineacion_estrategica[
                                            index
                                          ].objetivo
                                        }
                                      </Tooltip>
                                    }
                                  >
                                    <textarea
                                      type="text"
                                      name={`objetivo`}
                                      className="col-12"
                                      style={{
                                        verticalAlign: "middle",
                                        "margin-top": "38px",
                                      }}
                                      placeholder="Escribe aquí"
                                      value={
                                        this.state.alineacion_estrategica[index]
                                          .objetivo
                                      }
                                      data-idx={index}
                                      disabled={
                                        (isAdmin && gi_disabled) ||
                                        isAdmin ||
                                        (!isAdmin && gi_disabled)
                                      }
                                      onChange={this.onChangehandlerAlineacion_}
                                    />
                                  </OverlayTrigger>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "left",
                                  }}
                                >
                                  <strong>Estrategia</strong>
                                </td>

                                <td>
                                  <OverlayTrigger
                                    key={"bottom"}
                                    placement={"bottom"}
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        {
                                          this.state.alineacion_estrategica[
                                            index
                                          ].estrategia
                                        }
                                      </Tooltip>
                                    }
                                  >
                                    <textarea
                                      type="text"
                                      name={`estrategia`}
                                      className="col-12"
                                      style={{
                                        verticalAlign: "middle",
                                        "margin-top": "38px",
                                      }}
                                      placeholder="Escribe aquí"
                                      value={
                                        this.state.alineacion_estrategica[index]
                                          .estrategia
                                      }
                                      data-idx={index}
                                      disabled={
                                        (isAdmin && gi_disabled) ||
                                        isAdmin ||
                                        (!isAdmin && gi_disabled)
                                      }
                                      onChange={this.onChangehandlerAlineacion_}
                                    />
                                  </OverlayTrigger>
                                </td>
                              </tr>
                              <tr>
                                <td
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "left",
                                  }}
                                >
                                  <strong>Línea de acción</strong>
                                </td>
                                <td>
                                  <OverlayTrigger
                                    key={"bottom"}
                                    placement={"bottom"}
                                    overlay={
                                      <Tooltip id={`tooltip-bottom`}>
                                        {
                                          this.state.alineacion_estrategica[
                                            index
                                          ].linea
                                        }
                                      </Tooltip>
                                    }
                                  >
                                    <textarea
                                      type="text"
                                      name={`linea`}
                                      className="col-12"
                                      style={{
                                        verticalAlign: "middle",
                                        "margin-top": "38px",
                                      }}
                                      placeholder="Escribe aquí"
                                      value={
                                        this.state.alineacion_estrategica[index]
                                          .linea
                                      }
                                      data-idx={index}
                                      disabled={
                                        (isAdmin && gi_disabled) ||
                                        isAdmin ||
                                        (!isAdmin && gi_disabled)
                                      }
                                      onChange={this.onChangehandlerAlineacion_}
                                    />
                                  </OverlayTrigger>
                                </td>
                              </tr>
                              <tr>
                                {!isAdmin && !gi_disabled && (
                                  <td
                                    style={{
                                      verticalAlign: "middle",
                                      textAlign: "left",
                                    }}
                                  >
                                    <button
                                      className="btn btn-link delete-row"
                                      onClick={() =>
                                        this.removeRowAlineacion(row)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        size={"2x"}
                                      />
                                    </button>
                                  </td>
                                )}
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    ))}
                  </div>
                </div>
              </div>
            </div>

            <SideBarComments
              comments={this.state}
              sections={this.state}
              origin={"approval"}
              apartado={"General_Information"}
              id={this.props.id}
              botones={false}
            />
            {this.state.commentsGIPFlag === true && (
              <div className="row">
                <div className="col-10 offset-1">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <label className="col-12">Comentarios del Admin</label>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="commentsGIP"
                            value={this.state.commentsGIP}
                            onChange={this.onChangehandler}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );
    seccion2 = (
      <>
        <div className="row title-section" disabled={VisualizarTodos}>
          <div className="col-10">Descripción del PPI</div>
          {adminInRevision &&
            isResponsable &&
            this.state.problem_description_approved === false && (
              <div className="col-2">
                <button
                  className="btn btn-success btn-circle"
                  onClick={() => this.setDoneByAdmin("problem_description")}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <button
                  className="btn btn-orange btn-circle"
                  onClick={this.AddCommentPDP}
                >
                  <div className="icon material-icons">comment</div>
                </button>
              </div>
            )}
          {adminInRevision &&
            isResponsable &&
            this.state.problem_description_approved === true && (
              <div className="col-2">
                <button
                  className="btn btn-black btn-circle"
                  onClick={() => this.setUndoByAdmin("problem_description")}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </button>
              </div>
            )}
          {!adminInRevision &&
            this.state.problem_description_approved === true && (
              <div className="col-2">
                <span className="section-approved">Aprobada</span>
              </div>
            )}
        </div>
        <div className="row wrap-section">
          <div className="col-12 not-padding">
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row d-flex">
                  <div className="col-lg-12">
                    <div className="row">
                      <label className="col-12">
                        Descripción general del PPI
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {!isAdmin && !pd_disabled ? (
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="general_description"
                            value={this.state.general_description}
                            onChange={this.onChangehandler}
                          ></textarea>
                        ) : (
                          <div
                            style={{
                              textAlign: "justify",
                              "white-space": "pre-line",
                            }}
                          >
                            {this.state.general_description}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row d-flex">
                  <div className="col-lg-12">
                    <div className="row">
                      <label className="col-12">Objetivo</label>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {!isAdmin && !pd_disabled ? (
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="objective_general"
                            value={this.state.objective_general}
                            onChange={this.onChangehandler}
                          ></textarea>
                        ) : (
                          <div
                            style={{
                              textAlign: "justify",
                              "white-space": "pre-line",
                            }}
                          >
                            {this.state.objective_general}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>

                {this.state.formato === 7 && (
                  /**Seguir aqui mañana */
                  <div className="row">
                    <div className="col-12">
                      <div className="row subtitle-section">
                        <div className="col-11"></div>
                        {!isAdmin && !pd_disabled && (
                          <div className="col-1">
                            <button
                              className="col-12 btn btn-link p5"
                              style={{
                                position: "absolute",
                                right: "0px",
                                top: "-40px",
                              }}
                              onClick={() =>
                                this.addRowExtraComp("viii_id_preinver")
                              }
                            >
                              <FontAwesomeIcon icon={faPlusCircle} />
                            </button>
                          </div>
                        )}
                      </div>
                      <div className="row">
                        <div className="col-12 col-sm-6"></div>
                        <div
                          className="col-10 col-sm-4 pt-2"
                          style={{ textAlign: "right" }}
                        >
                          <b>Suma Total de inversión en estudios:</b>
                        </div>
                        <div className="col-10 col-sm-2">
                          <input
                            className="form-control"
                            value={this.state.total_IX_amount}
                            disabled
                          />{" "}
                        </div>

                        <div className="col-lg-12 col-sm-12">
                          <Table bordered className="table-not-padding">
                            <thead>
                              <tr>
                                <td colspan="6">
                                  <center>
                                    <b>Estudios de preinversión requeridos</b>
                                  </center>
                                </td>
                              </tr>
                              <tr>
                                <td style={{ width: "15%" }}>
                                  <strong>Nombre del estudio</strong>
                                </td>
                                <td style={{ width: "15%" }}>
                                  <strong>Tipo de estudio</strong>
                                </td>
                                <td style={{ width: "20%" }}>
                                  <strong>Descripción</strong>
                                </td>
                                <td style={{ width: "20%" }}>
                                  <strong>
                                    Justificación de su realización
                                  </strong>
                                </td>
                                <td style={{ width: "10%" }}>
                                  <strong>
                                    Fecha de inicio <br /> de realización
                                  </strong>
                                </td>
                                <td style={{ width: "10%" }}>
                                  <strong>
                                    Fecha final <br /> de realización
                                  </strong>
                                </td>
                                <td style={{ width: "10%" }}>
                                  <strong>
                                    Monto estimado <br />
                                    (incluye IVA)
                                  </strong>
                                </td>
                                {!isAdmin && !pd_disabled && <td></td>}
                              </tr>
                            </thead>
                            <tbody>
                              {this.state.data_sheet_extra_pre_investments.map(
                                (row, index) => (
                                  <tr key={"typeorigina_" + index}>
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {
                                              this.state
                                                .data_sheet_extra_pre_investments[
                                                index
                                              ].study_name
                                            }
                                          </Tooltip>
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Escribe aquí"
                                          name="study_name"
                                          value={
                                            this.state
                                              .data_sheet_extra_pre_investments[
                                              index
                                            ].study_name
                                          }
                                          data-tabla="data_sheet_extra_pre_investments"
                                          data-idx={index}
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          onChange={this.onChangeTableExtra}
                                        />
                                      </OverlayTrigger>
                                    </td>
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {
                                              this.state
                                                .data_sheet_extra_pre_investments[
                                                index
                                              ].type_of_study
                                            }
                                          </Tooltip>
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Escribe aquí"
                                          name="type_of_study"
                                          value={
                                            this.state
                                              .data_sheet_extra_pre_investments[
                                              index
                                            ].type_of_study
                                          }
                                          data-tabla="data_sheet_extra_pre_investments"
                                          data-idx={index}
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          onChange={this.onChangeTableExtra}
                                        />
                                      </OverlayTrigger>
                                    </td>
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {
                                              this.state
                                                .data_sheet_extra_pre_investments[
                                                index
                                              ].description
                                            }
                                          </Tooltip>
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Escribe aquí"
                                          name="description"
                                          value={
                                            this.state
                                              .data_sheet_extra_pre_investments[
                                              index
                                            ].description
                                          }
                                          data-tabla="data_sheet_extra_pre_investments"
                                          data-idx={index}
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          onChange={this.onChangeTableExtra}
                                        />
                                      </OverlayTrigger>
                                    </td>
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {
                                              this.state
                                                .data_sheet_extra_pre_investments[
                                                index
                                              ].justification_realization
                                            }
                                          </Tooltip>
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Escribe aquí"
                                          name="justification_realization"
                                          value={
                                            this.state
                                              .data_sheet_extra_pre_investments[
                                              index
                                            ].justification_realization
                                          }
                                          data-tabla="data_sheet_extra_pre_investments"
                                          data-idx={index}
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          onChange={this.onChangeTableExtra}
                                        />
                                      </OverlayTrigger>
                                    </td>
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {
                                              this.state
                                                .data_sheet_extra_pre_investments[
                                                index
                                              ].estimated_date_completion
                                            }
                                          </Tooltip>
                                        }
                                      >
                                        <input
                                          type="date"
                                          className="form-control"
                                          placeholder="Escribe aquí"
                                          name="estimated_date_completion"
                                          value={
                                            this.state
                                              .data_sheet_extra_pre_investments[
                                              index
                                            ].estimated_date_completion
                                          }
                                          data-tabla="data_sheet_extra_pre_investments"
                                          data-idx={index}
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          onChange={this.onChangeTableExtra}
                                        />
                                      </OverlayTrigger>
                                    </td>
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {
                                              this.state
                                                .data_sheet_extra_pre_investments[
                                                index
                                              ].second_estimated_date_completion
                                            }
                                          </Tooltip>
                                        }
                                      >
                                        <input
                                          type="date"
                                          className="form-control"
                                          placeholder="Escribe aquí"
                                          name="second_estimated_date_completion"
                                          value={
                                            this.state
                                              .data_sheet_extra_pre_investments[
                                              index
                                            ].second_estimated_date_completion
                                          }
                                          data-tabla="data_sheet_extra_pre_investments"
                                          data-idx={index}
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          onChange={this.onChangeTableExtra}
                                        />
                                      </OverlayTrigger>
                                    </td>

                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            ${" "}
                                            {fm.from(
                                              parseFloat(
                                                this.state.data_sheet_extra_pre_investments[
                                                  index
                                                ].estimated_cost_amount
                                                  .toString()
                                                  .replaceAll(",", "")
                                              )
                                            )}
                                          </Tooltip>
                                        }
                                      >
                                        <NumberFormat
                                          thousandSeparator={true}
                                          type="text"
                                          className="form-control"
                                          placeholder="Escribe aquí"
                                          name="estimated_cost_amount"
                                          value={
                                            this.state
                                              .data_sheet_extra_pre_investments[
                                              index
                                            ].estimated_cost_amount
                                          }
                                          data-tabla="data_sheet_extra_pre_investments"
                                          data-idx={index}
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          onChange={this.onChangeTableExtra}
                                        />
                                      </OverlayTrigger>
                                    </td>
                                    {!isAdmin && !pd_disabled && (
                                      <td>
                                        <button
                                          className="col-12 btn btn-link delete-row"
                                          data-row={row}
                                          onClick={() =>
                                            this.removeExtras(
                                              row,
                                              "data_sheet_extra_pre_investments"
                                            )
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faTimesCircle}
                                            size={"2x"}
                                          />
                                        </button>
                                      </td>
                                    )}
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}

                {this.props.adquisiciones === true && (
                  <div className="row d-flex">
                    <div className="col-lg-12">
                      <div className="row subtitle-section">
                        <div className="col-12">
                          <small className="note">Máximo 50 componentes</small>
                        </div>
                        <div className="col-10">
                          <h6>Descripción de los componentes del PPI</h6>
                        </div>
                        <div className="col-2">
                          {!isAdmin && !pd_disabled && (
                            <button
                              className="col-12 btn btn-link"
                              onClick={this.addRowComponentAdq}
                            >
                              <FontAwesomeIcon
                                icon={faPlusCircle}
                                size={"2x"}
                              />
                            </button>
                          )}
                        </div>
                      </div>
                      <div className="row d-flex">
                        <div className="col-lg-12">
                          <Table bordered className="table-not-padding">
                            <thead>
                              <tr>
                                <th colspan="2">Concepto</th>
                                <th colspan="2">Partida</th>
                                <th
                                  rowspan="2"
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  Descripción del componente
                                </th>
                                <th
                                  rowspan="2"
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  Unidad de medida
                                </th>
                                <th
                                  rowspan="2"
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  Cantidad
                                </th>
                                <th
                                  rowspan="2"
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  Precio unitario (sin IVA $)
                                </th>
                                <th
                                  rowspan="2"
                                  style={{
                                    verticalAlign: "middle",
                                    textAlign: "center",
                                  }}
                                >
                                  Monto total (sin IVA $)
                                </th>
                                {!isAdmin && !pd_disabled && (
                                  <th
                                    rowspan="2"
                                    style={{
                                      verticalAlign: "middle",
                                      textAlign: "center",
                                    }}
                                  ></th>
                                )}
                              </tr>

                              <tr>
                                <th>Num.</th>
                                <th>Descripción</th>
                                <th>Num.</th>
                                <th>Descripción</th>
                              </tr>
                            </thead>
                            <tbody className="body-components">
                              {this.state.components.map((row, index) => (
                                <tr key={"co_" + index}>
                                  <td>
                                    <OverlayTrigger
                                      key={"bottom"}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          {
                                            this.state.components[index]
                                              .number_concept
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      {!isAdmin && !pd_disabled ? (
                                        <Form.Control
                                          size="sm"
                                          as="select"
                                          name="classificationSelected"
                                          onChange={
                                            this.onChangehandlerNumberConcept
                                          }
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          data-idx={index}
                                          value={
                                            this.state.components[index]
                                              .number_concept
                                          }
                                        >
                                          <option disabled value="0">
                                            Seleccionar
                                          </option>
                                          {this.state.concepts.map(
                                            (concept, idx) => (
                                              <option
                                                key={idx}
                                                name="classificationSelected"
                                                value={concept.value}
                                              >
                                                {concept.value}
                                              </option>
                                            )
                                          )}
                                        </Form.Control>
                                      ) : (
                                        <span>
                                          {
                                            this.state.components[index]
                                              .number_concept
                                          }
                                        </span>
                                      )}
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      key={"bottom"}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          {
                                            this.state.components[index]
                                              .description_concept
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Escribe aquí"
                                          value={
                                            this.state.components[index]
                                              .description_concept
                                          }
                                          data-idx={index}
                                          disabled={true}
                                          onChange={
                                            this.onChangehandlerDescConcept
                                          }
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      key={"bottom"}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          {
                                            this.state.components[index]
                                              .number_item
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      {!isAdmin && !pd_disabled ? (
                                        <Form.Control
                                          size="sm"
                                          as="select"
                                          name="classificationSelected"
                                          onChange={
                                            this.onChangehandlerNumberItem
                                          }
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          data-idx={index}
                                          value={
                                            this.state.components[index]
                                              .number_item
                                          }
                                        >
                                          <option disabled value="0">
                                            Seleccionar
                                          </option>
                                          {this.state.items.map((item, idx) => (
                                            <option
                                              key={idx}
                                              name="classificationSelected"
                                              value={item.value}
                                            >
                                              {item.value}
                                            </option>
                                          ))}
                                        </Form.Control>
                                      ) : (
                                        <span>
                                          {
                                            this.state.components[index]
                                              .number_item
                                          }
                                        </span>
                                      )}
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      key={"bottom"}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          {
                                            this.state.components[index]
                                              .description_item
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Escribe aquí"
                                          value={
                                            this.state.components[index]
                                              .description_item
                                          }
                                          data-idx={index}
                                          disabled={true}
                                          onChange={
                                            this.onChangehandlerDescItem
                                          }
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      key={"bottom"}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          {
                                            this.state.components[index]
                                              .description
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <div>
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Escribe aquí"
                                          value={
                                            this.state.components[index]
                                              .description
                                          }
                                          data-idx={index}
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          onChange={
                                            this
                                              .onChangehandlerDescriptionConcept
                                          }
                                        />
                                      </div>
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      key={"bottom"}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          {this.state.measurement_units.map(
                                            (type, idx) =>
                                              type.value ==
                                              this.state.components[index]
                                                .unit_of_measure
                                                ? type.name
                                                : ""
                                          )}
                                        </Tooltip>
                                      }
                                    >
                                      {!isAdmin && !pd_disabled ? (
                                        <Form.Control
                                          size="sm"
                                          as="select"
                                          onChange={
                                            this.onChangehandlerUnitOfMeasure
                                          }
                                          data-idx={index}
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          value={
                                            this.state.components[index]
                                              .unit_of_measure
                                          }
                                        >
                                          <option disabled value="0">
                                            Seleccionar
                                          </option>
                                          {this.state.measurement_units.map(
                                            (type, idx) => (
                                              <option
                                                key={idx}
                                                name="components"
                                                value={type.value}
                                              >
                                                {type.name}
                                              </option>
                                            )
                                          )}
                                        </Form.Control>
                                      ) : (
                                        <span>
                                          {this.state.measurement_units.map(
                                            (type, idx) =>
                                              type.value ==
                                              this.state.components[index]
                                                .unit_of_measure
                                                ? type.name
                                                : ""
                                          )}
                                        </span>
                                      )}
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      key={"bottom"}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          {
                                            this.state.components[index]
                                              .quantity
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <input
                                        type="text"
                                        className="d-block form-control border-0 size-14 text-center amount-txt"
                                        placeholder="Escribe aquí"
                                        value={
                                          this.state.components[index].quantity
                                        }
                                        data-idx={index}
                                        disabled={
                                          (isAdmin && pd_disabled) ||
                                          isAdmin ||
                                          (!isAdmin && pd_disabled)
                                        }
                                        onChange={
                                          this.onChangehandlerQuantityComponent
                                        }
                                      />
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      key={"bottom"}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          ${" "}
                                          {fm.from(
                                            parseFloat(
                                              this.state.components[index]
                                                .unit_price
                                            )
                                          )}
                                        </Tooltip>
                                      }
                                    >
                                      <NumberFormat
                                        className="d-block form-control border-0 size-14 text-center amount-txt"
                                        placeholder="0"
                                        value={
                                          this.state.components[index]
                                            .unit_price
                                        }
                                        data-idx={index}
                                        disabled={
                                          (isAdmin && pd_disabled) ||
                                          isAdmin ||
                                          (!isAdmin && pd_disabled)
                                        }
                                        onChange={this.onChangehandlerUnitPrice}
                                        thousandSeparator={true}
                                        decimalSeparator={"."}
                                        fixedDecimalScale={true}
                                      />
                                    </OverlayTrigger>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      key={"bottom"}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          ${" "}
                                          {fm.from(
                                            parseFloat(
                                              this.state.components[index]
                                                .total_amount
                                            )
                                          )}
                                        </Tooltip>
                                      }
                                    >
                                      <NumberFormat
                                        className="d-block form-control border-0 size-14 text-center amount-txt"
                                        placeholder="0"
                                        value={
                                          this.state.components[index]
                                            .total_amount
                                        }
                                        data-idx={index}
                                        onChange={
                                          this.onChangehandlerTotalAmount
                                        }
                                        thousandSeparator={true}
                                        disabled
                                      />
                                    </OverlayTrigger>
                                  </td>
                                  {!isAdmin && !pd_disabled && (
                                    <td>
                                      <button
                                        className="btn btn-link delete-row"
                                        onClick={() =>
                                          this.removeRowComponent(row)
                                        }
                                      >
                                        <FontAwesomeIcon
                                          icon={faTimesCircle}
                                          size={"2x"}
                                        />
                                      </button>
                                    </td>
                                  )}
                                </tr>
                              ))}
                            </tbody>
                          </Table>
                        </div>
                      </div>

                      <div className="row d-flex">
                        <div className="col-lg-4 offset-lg-8">
                          <Table bordered>
                            <tbody>
                              <tr>
                                <td>
                                  <strong>Subtotal</strong>
                                </td>
                                <td>$ {this.state.subtotal_components}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>IVA</strong>
                                </td>
                                <td>$ {this.state.iva_components}</td>
                              </tr>
                              <tr>
                                <td>
                                  <strong>Total</strong>
                                </td>
                                <td>$ {this.state.total_components}</td>
                              </tr>
                            </tbody>
                          </Table>
                        </div>
                      </div>
                    </div>
                  </div>
                )}
                {this.props.adquisiciones === false &&
                  this.state.formato !== 7 && (
                    <div className="row d-flex">
                      <div className="col-lg-12">
                        <div className="row subtitle-section">
                          <div className="col-12">
                            <small className="note">
                              Máximo 50 componentes
                            </small>
                          </div>

                          <div className="col-10">
                            <h6>Descripción de los componentes del PPI</h6>
                          </div>
                          <div className="col-2">
                            {!isAdmin && !pd_disabled && (
                              <button
                                className="col-12 btn btn-link"
                                onClick={this.addRowComponent}
                              >
                                <FontAwesomeIcon
                                  icon={faPlusCircle}
                                  size={"2x"}
                                />
                              </button>
                            )}
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-lg-12">
                            <Table bordered className="table-not-padding">
                              <thead>
                                <tr>
                                  <td>
                                    <strong>Nombre del componente</strong>
                                  </td>
                                  <td>
                                    <strong>Descripción</strong>
                                  </td>
                                  <td>
                                    <strong>Unidad de medida</strong>
                                  </td>
                                  <td>
                                    <strong>Cantidad</strong>
                                  </td>
                                  <td>
                                    <strong>Precio unitario (Sin IVA $)</strong>
                                  </td>
                                  <td>
                                    <strong>Monto total (Sin IVA $)</strong>
                                  </td>
                                  {!isAdmin && !pd_disabled && <td></td>}
                                </tr>
                              </thead>
                              <tbody className="body-components">
                                {this.state.components.map((row, index) => (
                                  <tr key={"co_" + index}>
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {
                                              this.state.components[index]
                                                .name_concept
                                            }
                                          </Tooltip>
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Escribe aquí"
                                          value={
                                            this.state.components[index]
                                              .name_concept
                                          }
                                          data-idx={index}
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          onChange={
                                            this.onChangehandlerNameConcept
                                          }
                                        />
                                      </OverlayTrigger>
                                    </td>
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {
                                              this.state.components[index]
                                                .description
                                            }
                                          </Tooltip>
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="form-control"
                                          placeholder="Escribe aquí"
                                          value={
                                            this.state.components[index]
                                              .description
                                          }
                                          data-idx={index}
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          onChange={
                                            this
                                              .onChangehandlerDescriptionConcept
                                          }
                                        />
                                      </OverlayTrigger>
                                    </td>
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {this.state.measurement_units.map(
                                              (type, idx) =>
                                                type.value ==
                                                this.state.components[index]
                                                  .unit_of_measure
                                                  ? type.name
                                                  : ""
                                            )}
                                          </Tooltip>
                                        }
                                      >
                                        {!isAdmin && !pd_disabled ? (
                                          <Form.Control
                                            size="sm"
                                            as="select"
                                            onChange={
                                              this.onChangehandlerUnitOfMeasure
                                            }
                                            data-idx={index}
                                            disabled={
                                              (isAdmin && pd_disabled) ||
                                              isAdmin ||
                                              (!isAdmin && pd_disabled)
                                            }
                                            value={
                                              this.state.components[index]
                                                .unit_of_measure
                                            }
                                          >
                                            <option disabled value="0">
                                              Seleccionar
                                            </option>
                                            {this.state.measurement_units.map(
                                              (type, idx) => (
                                                <option
                                                  key={idx}
                                                  name="components"
                                                  value={type.value}
                                                >
                                                  {type.name}
                                                </option>
                                              )
                                            )}
                                          </Form.Control>
                                        ) : (
                                          <span>
                                            {this.state.measurement_units.map(
                                              (type, idx) =>
                                                type.value ==
                                                this.state.components[index]
                                                  .unit_of_measure
                                                  ? type.name
                                                  : ""
                                            )}
                                          </span>
                                        )}
                                      </OverlayTrigger>
                                    </td>
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {
                                              this.state.components[index]
                                                .quantity
                                            }
                                          </Tooltip>
                                        }
                                      >
                                        <input
                                          type="text"
                                          className="d-block form-control border-0 size-14 text-center amount-txt"
                                          placeholder="Escribe aquí"
                                          value={
                                            this.state.components[index]
                                              .quantity
                                          }
                                          data-idx={index}
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          onChange={
                                            this
                                              .onChangehandlerQuantityComponent
                                          }
                                        />
                                      </OverlayTrigger>
                                    </td>
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            ${" "}
                                            {fm.from(
                                              parseFloat(
                                                this.state.components[index]
                                                  .unit_price
                                              )
                                            )}
                                          </Tooltip>
                                        }
                                      >
                                        <NumberFormat
                                          className="d-block form-control border-0 size-14 text-center amount-txt"
                                          placeholder="0"
                                          value={
                                            this.state.components[index]
                                              .unit_price
                                          }
                                          data-idx={index}
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          onChange={
                                            this.onChangehandlerUnitPrice
                                          }
                                          thousandSeparator={true}
                                          decimalSeparator={"."}
                                        />
                                      </OverlayTrigger>
                                    </td>
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            ${" "}
                                            {fm.from(
                                              parseFloat(
                                                this.state.components[index]
                                                  .total_amount
                                              )
                                            )}
                                          </Tooltip>
                                        }
                                      >
                                        <NumberFormat
                                          className="d-block form-control border-0 size-14 text-center amount-txt"
                                          placeholder="0"
                                          value={
                                            this.state.components[index]
                                              .total_amount
                                          }
                                          data-idx={index}
                                          onChange={
                                            this.onChangehandlerTotalAmount
                                          }
                                          thousandSeparator={true}
                                          disabled
                                        />
                                      </OverlayTrigger>
                                    </td>
                                    {!isAdmin && !pd_disabled && (
                                      <td>
                                        <button
                                          className="btn btn-link delete-row"
                                          onClick={() =>
                                            this.removeRowComponent(row)
                                          }
                                        >
                                          <FontAwesomeIcon
                                            icon={faTimesCircle}
                                            size={"2x"}
                                          />
                                        </button>
                                      </td>
                                    )}
                                  </tr>
                                ))}
                              </tbody>
                            </Table>
                          </div>
                        </div>
                        <div className="row d-flex">
                          <div className="col-lg-4 offset-lg-8">
                            <Table bordered>
                              <tbody>
                                <tr>
                                  <td>
                                    <strong>Subtotal</strong>
                                  </td>
                                  <td>$ {this.state.subtotal_components}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>IVA</strong>
                                  </td>
                                  <td>$ {this.state.iva_components}</td>
                                </tr>
                                <tr>
                                  <td>
                                    <strong>Total</strong>
                                  </td>
                                  <td>$ {this.state.total_components}</td>
                                </tr>
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                <div className="row subtitle-section">
                  <div className="col-3 text-left">
                    <label>Número de beneficiarios directos del PPI</label>
                  </div>
                  <div className="col-3">
                    {!isAdmin && !pd_disabled ? (
                      <input
                        type="text"
                        className="form-control"
                        placeholder="0"
                        name="beneficiaries"
                        value={this.state.beneficiaries}
                        onChange={this.onChangehandlerBeneficiaries}
                      />
                    ) : (
                      <div style={{ textAlign: "left" }}>
                        {this.state.beneficiaries}
                      </div>
                    )}
                  </div>
                </div>
                <br></br>
                <br></br>
                <div className="row d-flex">
                  <div className="col-lg-12">
                    <div className="row">
                      <label className="col-12">
                        Resultados de la ejecución y beneficios económicos y/o
                        sociales
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {!isAdmin && !pd_disabled ? (
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="execution_result"
                            value={this.state.execution_result}
                            onChange={this.onChangehandler}
                          ></textarea>
                        ) : (
                          <div
                            style={{
                              textAlign: "justify",
                              "white-space": "pre-line",
                            }}
                          >
                            {this.state.execution_result}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
                {this.props.adquisiciones === false &&
                  this.state.formato !== 7 && (
                    <div className="row d-flex">
                      <div className="col-lg-12">
                        <div className="row">
                          <label className="col-12">
                            Factibilidades del PPI
                          </label>
                        </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <Table bordered className="table-not-padding">
                              <thead>
                                <tr>
                                  <td>
                                    <strong>Tipo de factibilidad</strong>
                                  </td>
                                  <td>
                                    <strong>Respuesta</strong>
                                  </td>
                                  <td>
                                    <strong>Comentario</strong>
                                  </td>
                                </tr>
                              </thead>
                              <tbody>
                                <tr>
                                  <td>Se cuenta con proyecto ejecutivo</td>
                                  <td>
                                    <Form.Control
                                      size="sm"
                                      as="select"
                                      disabled={
                                        (isAdmin && pd_disabled) ||
                                        isAdmin ||
                                        (!isAdmin && pd_disabled)
                                      }
                                      className="d-block form-control border-0 size-14 text-center amount-txt"
                                      name="executive_project"
                                      value={this.state.executive_project}
                                      onChange={this.onChangehandler}
                                    >
                                      <option value="0">No</option>
                                      <option value="1">Sí</option>
                                      <option value="2" hidden>
                                        NA
                                      </option>
                                    </Form.Control>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      key={"bottom"}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          {
                                            this.state
                                              .executive_project_description
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <input
                                        type="text"
                                        disabled={
                                          (isAdmin && pd_disabled) ||
                                          isAdmin ||
                                          (!isAdmin && pd_disabled)
                                        }
                                        className="col-12 form control border-0 size-14 text-center amount-txt"
                                        placeholder="Escribe aquí"
                                        name="executive_project_description"
                                        value={
                                          this.state
                                            .executive_project_description
                                        }
                                        onChange={this.onChangehandler}
                                      />
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Se cuenta con permisos</td>
                                  <td>
                                    <Form.Control
                                      size="sm"
                                      as="select"
                                      disabled={
                                        (isAdmin && pd_disabled) ||
                                        isAdmin ||
                                        (!isAdmin && pd_disabled)
                                      }
                                      className="d-block form-control border-0 size-14 text-center amount-txt"
                                      name="permissions"
                                      value={this.state.permissions}
                                      onChange={this.onChangehandler}
                                    >
                                      <option selected value="0">
                                        No
                                      </option>
                                      <option value="1">Sí</option>
                                      <option value="2" hidden>
                                        NA
                                      </option>
                                    </Form.Control>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      key={"bottom"}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          {this.state.permissions_description}
                                        </Tooltip>
                                      }
                                    >
                                      <input
                                        type="text"
                                        disabled={
                                          (isAdmin && pd_disabled) ||
                                          isAdmin ||
                                          (!isAdmin && pd_disabled)
                                        }
                                        className="d-block form-control border-0 size-14 text-center amount-txt"
                                        placeholder="Escribe aquí"
                                        name="permissions_description"
                                        value={
                                          this.state.permissions_description
                                        }
                                        onChange={this.onChangehandler}
                                      />
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                                <tr>
                                  <td>Se cuenta con estudios ambientales</td>
                                  <td>
                                    <Form.Control
                                      size="sm"
                                      as="select"
                                      disabled={
                                        (isAdmin && pd_disabled) ||
                                        isAdmin ||
                                        (!isAdmin && pd_disabled)
                                      }
                                      className="d-block form-control border-0 size-14 text-center amount-txt"
                                      name="enviromental_studies"
                                      value={this.state.enviromental_studies}
                                      onChange={this.onChangehandler}
                                    >
                                      <option selected value="0">
                                        No
                                      </option>
                                      <option value="1">Sí</option>
                                      <option value="2" hidden>
                                        NA
                                      </option>
                                    </Form.Control>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      key={"bottom"}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          {
                                            this.state
                                              .enviromental_studies_description
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <input
                                        type="text"
                                        disabled={
                                          (isAdmin && pd_disabled) ||
                                          isAdmin ||
                                          (!isAdmin && pd_disabled)
                                        }
                                        className="d-block form-control border-0 size-14 text-center amount-txt"
                                        placeholder="Escribe aquí"
                                        name="enviromental_studies_description"
                                        value={
                                          this.state
                                            .enviromental_studies_description
                                        }
                                        onChange={this.onChangehandler}
                                      />
                                    </OverlayTrigger>
                                  </td>
                                </tr>
                                {/* <tr>
                                  <td>Se cuenta con derechos de propiedad</td>
                                  <td>
                                    <Form.Control
                                      size="sm"
                                      as="select"
                                      disabled={
                                        (isAdmin && pd_disabled) ||
                                        isAdmin ||
                                        (!isAdmin && pd_disabled)
                                      }
                                      className="d-block form-control border-0 size-14 text-center amount-txt"
                                      name="property_rights"
                                      value={this.state.property_rights}
                                      onChange={this.onChangehandler}
                                    >
                                      <option selected value="0">
                                        No
                                      </option>
                                      <option value="1">Sí</option>
                                      <option value="2" hidden>
                                        NA
                                      </option>
                                    </Form.Control>
                                  </td>
                                  <td>
                                    <OverlayTrigger
                                      key={"bottom"}
                                      placement={"bottom"}
                                      overlay={
                                        <Tooltip id={`tooltip-bottom`}>
                                          {
                                            this.state
                                              .property_rights_description
                                          }
                                        </Tooltip>
                                      }
                                    >
                                      <input
                                        type="text"
                                        disabled={
                                          (isAdmin && pd_disabled) ||
                                          isAdmin ||
                                          (!isAdmin && pd_disabled)
                                        }
                                        className="d-block form-control border-0 size-14 text-center amount-txt"
                                        placeholder="Escribe aquí"
                                        name="property_rights_description"
                                        value={
                                          this.state.property_rights_description
                                        }
                                        onChange={this.onChangehandler}
                                      />
                                    </OverlayTrigger>
                                  </td>
                                </tr> */}

                                {}
                                <tr>
                                  <td>
                                    Señalar que tipo de{" "}
                                    <b>
                                      <i>BIEN</i>
                                    </b>{" "}
                                    (*) es el lugar donde se llevará a cabo el PPI
                                  </td>

                                  <td>
                                    <Form.Control
                                      size="sm"
                                      as="select"
                                      disabled={
                                        (isAdmin && pd_disabled) ||
                                        isAdmin ||
                                        (!isAdmin && pd_disabled)
                                      }
                                      className="d-block form-control border-0 size-14 text-center amount-txt"
                                      name="tipo_bien"
                                      value={this.state.tipo_bien}
                                      onChange={this.onChangehandler}
                                    >
                                      <option value="0">
                                        Seleccione una opción
                                      </option>
                                      <option selected value="1">
                                        Dominio publico
                                      </option>
                                      <option value="2">Propio</option>
                                    </Form.Control>
                                  </td>
                                  {isPropio && (
                                    <td>
                                 
                                     
                                        <Form.Control
                                          size="sm"
                                          as="select"
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          className="d-block form-control border-0 size-14 text-center amount-txt"
                                          name="tipo_bien_descripcion"
                                          value={
                                            this.state.tipo_bien_descripcion
                                          }
                                          onChange={this.onChangehandler}
                                        >
                                          <option value="0">Estatal</option>
                                          <option value="1">Municipal</option>
                                          <option value="2">Otro</option>
                                        </Form.Control>
                                    
                                    </td>
                                  )}
                                  

                                  {isPropio && isOtro && (
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {this.state.otro}
                                          </Tooltip>
                                        }
                                      >
                                        <input
                                          type="text"
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          className="d-block form-control border-0 size-14 text-center amount-txt"
                                          placeholder="Especifique"
                                          name="otro"
                                          value={this.state.otro}
                                          onChange={this.onChangehandler}
                                        />
                                      </OverlayTrigger>
                                    </td>
                                  )}
                                  
                                </tr>
                                {console.log(
                                  "This.catastro",
                                  this.state.showNumeroCatastro
                                )}
                                {/* {this.state.showNumeroCatastro ?  ( */}

                                {Iscatastro && (
                                  <tr>
                                    <td>Número de catastro</td>
                                    <td>
                                 
                                      <Form.Control
                                        size="sm"
                                        as="select"
                                        disabled={
                                          (isAdmin && pd_disabled) ||
                                          isAdmin ||
                                          (!isAdmin && pd_disabled)
                                        }
                                        className="d-block form-control border-0 size-14 text-center amount-txt"
                                        name="numero_catastro"
                                        value={this.state.numero_catastro}
                                        onChange={this.onChangehandler}
                                      >
                                        <option selected value="0">
                                          No
                                        </option>
                                      
                                        {isPropio &&
                                          <option selected value="1" >Sí</option>
                                          
                                        }
                                        
                                     
                                      </Form.Control>
                                    </td>
                                    <td>
                                      <OverlayTrigger
                                        key={"bottom"}
                                        placement={"bottom"}
                                        overlay={
                                          <Tooltip id={`tooltip-bottom`}>
                                            {this.state.catastro}
                                          </Tooltip>
                                        }
                                      >
                                        <input
                                          type="text"
                                          disabled={
                                            (isAdmin && pd_disabled) ||
                                            isAdmin ||
                                            (!isAdmin && pd_disabled)
                                          }
                                          className="d-block form-control border-0 size-14 text-center amount-txt"
                                          placeholder="Escribe aquí"
                                          name="catastro"
                                          value={this.state.catastro}
                                          onChange={this.onChangehandler}
                                        />
                                      </OverlayTrigger>
                                    </td>
                                  </tr>
                                )}

                               
                              </tbody>
                            </Table>
                          </div>
                        </div>
                      
                          <div className="nota-facti">
                            <strong>
                            (*) Tipos de bienes: Revisar las definiciones de los conceptos 6100 y 6200, pertenecientes al capítulo 6000 del Clasificador por Objeto de Gasto emitido por la CONAC
                            </strong>
                          </div>
                        
                        <div>
                        
                      </div>
                      </div>
                    </div>
                  )}
              </div>
            </div>
            <SideBarComments
              comments={this.state}
              sections={this.state}
              origin={"approval"}
              apartado={"Problem_Description"}
              id={this.props.id}
              botones={false}
            />
            {this.state.commentsPDPFlag === true && (
              <div className="row">
                <div className="col-10 offset-1">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <label className="col-12">Comentarios del Admin</label>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="commentsPDP"
                            value={this.state.commentsPDP}
                            onChange={this.onChangehandler}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );

    seccion3 = (
      <>
        <div className="row title-section" disabled={VisualizarTodos}>
          <div className="col-10">Análisis de la situación actual </div>
          {adminInRevision &&
            isResponsable &&
            this.state.general_description_approved === false && (
              <div className="col-2">
                <button
                  className="btn btn-success btn-circle"
                  onClick={() => this.setDoneByAdmin("general_description")}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <button
                  className="btn btn-orange btn-circle"
                  onClick={this.AddCommentGDP}
                >
                  <div className="icon material-icons">comment</div>
                </button>
              </div>
            )}
          {adminInRevision &&
            isResponsable &&
            this.state.general_description_approved === true && (
              <div className="col-2">
                <button
                  className="btn btn-black btn-circle"
                  onClick={() => this.setUndoByAdmin("general_description")}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </button>
              </div>
            )}
          {!adminInRevision &&
            this.state.general_description_approved === true && (
              <div className="col-2">
                <span className="section-approved">Aprobada</span>
              </div>
            )}
        </div>
        <div className="row wrap-section">
          <div className="col-12 not-padding">
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row d-flex">
                  <div className="col-lg-12">
                    <div className="row">
                      <label className="col-12">
                        Justificación del PPI (problemática, necesidad u
                        oportunidad)
                      </label>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {!isAdmin && !gd_disabled ? (
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="description"
                            value={this.state.description}
                            onChange={this.onChangehandler}
                          ></textarea>
                        ) : (
                          <div
                            style={{
                              textAlign: "justify",
                              "white-space": "pre-line",
                            }}
                          >
                            {this.state.description}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row d-flex">
                  <div className="col-lg-12">
                    <div className="row">
                      <label className="col-12">Oferta actual</label>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {!isAdmin && !gd_disabled ? (
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="current_situation_offer"
                            value={this.state.current_situation_offer}
                            onChange={this.onChangehandler}
                          ></textarea>
                        ) : (
                          <div
                            style={{
                              textAlign: "justify",
                              "white-space": "pre-line",
                            }}
                          >
                            {this.state.current_situation_offer}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row d-flex">
                  <div className="col-lg-12">
                    <div className="row">
                      <label className="col-12">Demanda actual</label>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {!isAdmin && !gd_disabled ? (
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="demand_current_situation"
                            value={this.state.demand_current_situation}
                            onChange={this.onChangehandler}
                          ></textarea>
                        ) : (
                          <div
                            style={{
                              textAlign: "justify",
                              "white-space": "pre-line",
                            }}
                          >
                            {this.state.demand_current_situation}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row d-flex">
                  <div className="col-lg-12">
                    <div className="row">
                      <label className="col-12">Interacción actual</label>
                    </div>
                    <div className="row">
                      <div className="col-12">
                        {!isAdmin && !gd_disabled ? (
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="intereaction_current_situation"
                            value={this.state.intereaction_current_situation}
                            onChange={this.onChangehandler}
                          ></textarea>
                        ) : (
                          <div
                            style={{
                              textAlign: "justify",
                              "white-space": "pre-line",
                            }}
                          >
                            {this.state.intereaction_current_situation}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-10 offset-1">
                <div className="row subtitle-section">
                  <div className="col-12">
                    <small className="note">
                      Descargar plantilla disponible en "Ayuda/Contacto" {">"}{" "}
                      "Marco Normativo"{" "}
                    </small>
                  </div>

                  <label className="col-12">
                    Plantilla de imágenes situación actual
                  </label>
                </div>

                {!isAdmin && !gd_disabled && (
                  <div className="row">
                    <div className="col-3">
                      <Form.File
                        accept=".doc,.docx,application/msword"
                        id="custom-file"
                        ref={(input) => (this.fileInput = input)}
                        onChange={this.handleChangePicFileInput}
                      />
                      <button
                        className="col-12 btn btn-primary"
                        onClick={this.handleInputFileClick}
                      >
                        <FontAwesomeIcon icon={faPaperclip} /> Agregar
                      </button>
                    </div>
                  </div>
                )}
                {/* {console.log("Images file x", this.state.showImagesFile)} */}
                {this.state.imagesFile !== null ? (
                  <div className="row row-file">
                    <div className="col-1 file-icon">
                      <FontAwesomeIcon icon={faFileAlt} />
                    </div>

                    <div
                      className="col-8 text-collapsed file-name"
                      style={{ textAlign: "left" }}
                    >
                      {this.state.pic_file_name}
                    </div>

                    <div
                      className="col-1 file-download"
                      style={{ textAlign: "center" }}
                    >
                      <a
                        href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.imagesFile}`}
                        download
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </a>
                    </div>

                    {!isAdmin && !gd_disabled && (
                      <div className="col-1" style={{ textAlign: "center" }}>
                        <button
                          className="col-12 btn btn-custom-delete"
                          onClick={this.handleDeleteImagesClick}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>

            <SideBarComments
              comments={this.state}
              sections={this.state}
              origin={"approval"}
              apartado={"General_Description"}
              id={this.props.id}
              botones={false}
            />
            {this.state.commentsGDPFlag === true && (
              <div className="row">
                <div className="col-10 offset-1">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <label className="col-12">Comentarios del Admin</label>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="commentsGDP"
                            value={this.state.commentsGDP}
                            onChange={this.onChangehandler}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );

    seccion4 = (
      <>
        <div className="row title-section">
          <div className="col-10">Análisis de la situación con PPI</div>
          {adminInRevision &&
            isResponsable &&
            this.state.objective_approved === false && (
              <div className="col-2">
                <button
                  className="btn btn-success btn-circle"
                  onClick={() => this.setDoneByAdmin("objective")}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <button
                  className="btn btn-orange btn-circle"
                  onClick={this.AddCommentObjective}
                >
                  <div className="icon material-icons">comment</div>
                </button>
              </div>
            )}
          {adminInRevision &&
            isResponsable &&
            this.state.objective_approved === true && (
              <div className="col-2">
                <button
                  className="btn btn-black btn-circle"
                  onClick={() => this.setUndoByAdmin("objective")}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </button>
              </div>
            )}
          {!adminInRevision && this.state.objective_approved === true && (
            <div className="col-2">
              <span className="section-approved">Aprobada</span>
            </div>
          )}
        </div>
        <div className="row wrap-section">
          <div className="col-12 not-padding">
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row">
                  <label className="col-12">Oferta con PPI</label>
                </div>
                <div className="row d-flex">
                  <div className="col-lg-12">
                    {!isAdmin && !ob_disabled ? (
                      <textarea
                        className="col-12"
                        placeholder="Escribe aquí"
                        name="asa_offer_analysus"
                        value={this.state.asa_offer_analysus}
                        onChange={this.onChangehandler}
                      ></textarea>
                    ) : (
                      <div
                        style={{
                          textAlign: "justify",
                          "white-space": "pre-line",
                        }}
                      >
                        {this.state.asa_offer_analysus}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row">
                  <label className="col-12">Demanda con PPI</label>
                </div>
                <div className="row d-flex">
                  <div className="col-lg-12">
                    {!isAdmin && !ob_disabled ? (
                      <textarea
                        className="col-12"
                        placeholder="Escribe aquí"
                        name="asa_offer_analysis"
                        value={this.state.asa_offer_analysis}
                        onChange={this.onChangehandler}
                      ></textarea>
                    ) : (
                      <div
                        style={{
                          textAlign: "justify",
                          "white-space": "pre-line",
                        }}
                      >
                        {this.state.asa_offer_analysis}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row">
                  <label className="col-12">Interacción con PPI</label>
                </div>
                <div className="row d-flex">
                  <div className="col-lg-12">
                    {!isAdmin && !ob_disabled ? (
                      <textarea
                        className="col-12"
                        placeholder="Escribe aquí"
                        name="asa_interaction"
                        value={this.state.asa_interaction}
                        onChange={this.onChangehandler}
                      ></textarea>
                    ) : (
                      <div
                        style={{
                          textAlign: "justify",
                          "white-space": "pre-line",
                        }}
                      >
                        {this.state.asa_interaction}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row subtitle-section">
                  <div className="col-12">
                    <small className="note">
                      Descargar plantilla disponible en "Ayuda/Contacto" {">"}{" "}
                      "Marco Normativo"{" "}
                    </small>
                  </div>
                  <label className="col-12">
                    Plantilla de imágenes situación con PPI
                  </label>
                </div>

                {!isAdmin && !ob_disabled && (
                  <div className="row">
                    <div className="col-3">
                      <Form.File
                        accept=".doc,.docx,application/msword"
                        id="custom-file"
                        ref={(input) => (this.planfileInput = input)}
                        onChange={this.handleChangePlansFileInput}
                      />
                      <button
                        className="col-12 btn btn-primary"
                        onClick={this.architecturalPlanfile}
                      >
                        <FontAwesomeIcon icon={faPaperclip} /> Agregar
                      </button>
                    </div>
                  </div>
                )}

                {/* {console.log("Plan arquit", this.state.showPlanFile)} */}

                {this.state.showPlanFile ? (
                  <div className="row row-file">
                    <div className="col-1 file-icon">
                      <FontAwesomeIcon icon={faFileAlt} />
                    </div>
                    <div
                      className="col-8 text-collapsed file-name"
                      style={{ textAlign: "left" }}
                    >
                      <span>{this.state.plans_name}</span>
                    </div>
                    <div
                      className="col-1 file-download"
                      style={{ textAlign: "center" }}
                    >
                      <a
                        href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.planFile}`}
                        download
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </a>
                    </div>
                    {!isAdmin && !ob_disabled && (
                      <div className="col-1" style={{ textAlign: "center" }}>
                        <button
                          className="col-12 btn btn-custom-delete"
                          onClick={this.handleDeletePlansClick}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>

            <SideBarComments
              comments={this.state}
              sections={this.state}
              origin={"approval"}
              apartado={"Objective"}
              id={this.props.id}
              botones={false}
            />
            {this.state.commentsObjectiveFlag === true && (
              <div className="row">
                <div className="col-10 offset-1">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <label className="col-12">Comentarios del Admin</label>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="commentsObjective"
                            value={this.state.commentsObjective}
                            onChange={this.onChangehandler}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );

    seccion5 = (
      <>
        <div className="row title-section" disabled={VisualizarTodos}>
          <div className="col-10">Análisis de la situación optimizada</div>
          {adminInRevision &&
            isResponsable &&
            this.state.exesution_results_approved === false && (
              <div className="col-2">
                <button
                  className="btn btn-success btn-circle"
                  onClick={() => this.setDoneByAdmin("execution_results")}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <button
                  className="btn btn-orange btn-circle"
                  onClick={this.AddCommentEr}
                >
                  <div className="icon material-icons">comment</div>
                </button>
              </div>
            )}
          {adminInRevision &&
            isResponsable &&
            this.state.exesution_results_approved === true && (
              <div className="col-2">
                <button
                  className="btn btn-black btn-circle"
                  onClick={() => this.setUndoByAdmin("execution_results")}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </button>
              </div>
            )}
          {!adminInRevision &&
            this.state.exesution_results_approved === true && (
              <div className="col-2">
                <span className="section-approved">Aprobada</span>
              </div>
            )}
        </div>
        <div className="row wrap-section">
          <div className="col-12 not-padding">
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row">
                  <label className="col-12">
                    Descripción de la posible medida de optimización
                  </label>
                </div>
                <div className="row d-flex">
                  <div className="col-lg-12">
                    {!isAdmin && !er_disabled ? (
                      <textarea
                        className="col-12"
                        placeholder="Escribe aquí"
                        name="asp_relevantlegal"
                        value={this.state.asp_relevantlegal}
                        onChange={this.onChangehandler}
                      ></textarea>
                    ) : (
                      <div
                        style={{
                          textAlign: "justify",
                          "white-space": "pre-line",
                        }}
                      >
                        {this.state.asp_relevantlegal}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row">
                  <label className="col-12">Oferta optimizada</label>
                </div>
                <div className="row d-flex">
                  <div className="col-lg-12">
                    {!isAdmin && !er_disabled ? (
                      <textarea
                        className="col-12"
                        placeholder="Escribe aquí"
                        name="offer_ppi"
                        value={this.state.offer_ppi}
                        onChange={this.onChangehandler}
                      ></textarea>
                    ) : (
                      <div
                        style={{
                          textAlign: "justify",
                          "white-space": "pre-line",
                        }}
                      >
                        {this.state.offer_ppi}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row">
                  <label className="col-12">Demanda optimizada</label>
                </div>
                <div className="row d-flex">
                  <div className="col-lg-12">
                    {!isAdmin && !er_disabled ? (
                      <textarea
                        className="col-12"
                        placeholder="Escribe aquí"
                        name="demand_ppi"
                        value={this.state.demand_ppi}
                        onChange={this.onChangehandler}
                      ></textarea>
                    ) : (
                      <div
                        style={{
                          textAlign: "justify",
                          "white-space": "pre-line",
                        }}
                      >
                        {this.state.demand_ppi}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row">
                  <label className="col-12">Interacción optimizada</label>
                </div>
                <div className="row d-flex">
                  <div className="col-lg-12">
                    {!isAdmin && !er_disabled ? (
                      <textarea
                        className="col-12"
                        placeholder="Escribe aquí"
                        name="interaction_ppi"
                        value={this.state.interaction_ppi}
                        onChange={this.onChangehandler}
                      ></textarea>
                    ) : (
                      <div
                        style={{
                          textAlign: "justify",
                          "white-space": "pre-line",
                        }}
                      >
                        {this.state.interaction_ppi}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
            <SideBarComments
              comments={this.state}
              sections={this.state}
              origin={"approval"}
              apartado={"Execution_Results"}
              id={this.props.id}
              botones={false}
            />
          </div>
        </div>
        <div className="row wrap-section">
          <div className="col-12 not-padding">
            <div className="row"></div>
            {this.state.commentsExecutionResultsFlag === true && (
              <div className="row">
                <div className="col-10 offset-1">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <label className="col-12">Comentarios del Admin</label>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="commentsExecutionResults"
                            value={this.state.commentsExecutionResults}
                            onChange={this.onChangehandler}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );

    seccion6 = (
      <>
        <div className="row title-section" disabled={VisualizarTodos}>
          <div className="col-10">Alternativas de solución</div>
          {adminInRevision &&
            isResponsable &&
            this.state.feasibilities_approved === false && (
              <div className="col-2">
                <button
                  className="btn btn-success btn-circle"
                  onClick={() => this.setDoneByAdmin("feasibilities")}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <button
                  className="btn btn-orange btn-circle"
                  onClick={this.AddCommentFP}
                >
                  <div className="icon material-icons">comment</div>
                </button>
              </div>
            )}
          {adminInRevision &&
            isResponsable &&
            this.state.feasibilities_approved === true && (
              <div className="col-2">
                <button
                  className="btn btn-black btn-circle"
                  onClick={() => this.setUndoByAdmin("feasibilities")}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </button>
              </div>
            )}
          {!adminInRevision && this.state.feasibilities_approved === true && (
            <div className="col-2">
              <span className="section-approved">Aprobada</span>
            </div>
          )}
        </div>
        <div className="row wrap-section">
          <div className="col-12 not-padding">
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row subtitle-section">
                  <div className="col-10">
                    <h6>Alternativas de solución desechadas</h6>
                  </div>
                  <div className="col-2">
                    {!isAdmin && !fe_disabled && (
                      <button
                        className="col-12 btn btn-link"
                        onClick={this.addRowOptimizationMeasures}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} size={"2x"} />
                      </button>
                    )}
                  </div>
                </div>
                <div className="row d-flex">
                  <div className="col-lg-12">
                    <Table bordered className="table-not-padding">
                      <thead>
                        <tr>
                          <td>
                            <strong>Nombre de la alternativa</strong>
                          </td>
                          <td>
                            <strong>Descripción</strong>
                          </td>
                          <td>
                            <strong>Costo total (incluye IVA $)</strong>
                          </td>
                          {!isAdmin && !fe_disabled && <td></td>}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.optimization_measures.map((row, index) => (
                          <tr key={"typeoriginOM_" + index}>
                            <td>
                              <OverlayTrigger
                                key={"bottom"}
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    {
                                      this.state.optimization_measures[index]
                                        .nom_alt
                                    }
                                  </Tooltip>
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Escribe aquí"
                                  value={
                                    this.state.optimization_measures[index]
                                      .nom_alt
                                  }
                                  data-idx={index}
                                  disabled={
                                    (isAdmin && fe_disabled) ||
                                    isAdmin ||
                                    (!isAdmin && fe_disabled)
                                  }
                                  onChange={
                                    this.onChangeOptimizationMeasuresName
                                  }
                                />
                              </OverlayTrigger>
                            </td>
                            <td>
                              <OverlayTrigger
                                key={"bottom"}
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    {
                                      this.state.optimization_measures[index]
                                        .alternatives
                                    }
                                  </Tooltip>
                                }
                              >
                                <input
                                  type="text"
                                  className="form-control"
                                  placeholder="Escribe aquí"
                                  value={
                                    this.state.optimization_measures[index]
                                      .alternatives
                                  }
                                  data-idx={index}
                                  disabled={
                                    (isAdmin && fe_disabled) ||
                                    isAdmin ||
                                    (!isAdmin && fe_disabled)
                                  }
                                  onChange={
                                    this
                                      .onChangeOptimizationMeasuresAlternatives
                                  }
                                />
                              </OverlayTrigger>
                            </td>
                            <td>
                              <OverlayTrigger
                                key={"bottom"}
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    ${" "}
                                    {fm.from(
                                      parseFloat(
                                        this.state.optimization_measures[index]
                                          .totalCostVAT
                                      )
                                    )}
                                  </Tooltip>
                                }
                              >
                                <NumberFormat
                                  className="d-block form-control border-0 size-14 text-center amount-txt"
                                  placeholder="0"
                                  value={
                                    this.state.optimization_measures[index]
                                      .totalCostVAT
                                  }
                                  data-idx={index}
                                  disabled={
                                    (isAdmin && fe_disabled) ||
                                    isAdmin ||
                                    (!isAdmin && fe_disabled)
                                  }
                                  onChange={
                                    this
                                      .onChangeOptimizationMeasuresTotalCostVAT
                                  }
                                  thousandSeparator={true}
                                />
                              </OverlayTrigger>
                            </td>
                            {!isAdmin && !fe_disabled && (
                              <td>
                                <button
                                  className="btn btn-link delete-row"
                                  onClick={() =>
                                    this.removeRowOptimizationMeasures(row)
                                  }
                                >
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    size={"2x"}
                                  />
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row subtitle-section">
                  <div className="col-10">
                    <h6>
                      Descripción de las ventajas y desventajas del PPI frente a
                      las alternativas de solución desechadas
                    </h6>
                  </div>
                  <div className="col-2">
                    {!isAdmin && !fe_disabled && (
                      <button
                        className="col-12 btn btn-link"
                        onClick={this.addRowAlternativa}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} size={"2x"} />
                      </button>
                    )}
                  </div>
                </div>
                <div className="row d-flex">
                  <div className="col-lg-12">
                    <Table bordered className="table-not-padding">
                      <thead>
                        <tr>
                          <td>
                            <strong>Nombre de la alternativa</strong>
                          </td>
                          <td>
                            <strong>Ventaja del PPI</strong>
                          </td>
                          <td>
                            <strong>Desventaja del PPI</strong>
                          </td>

                          {!isAdmin && !fe_disabled && <th></th>}
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.alternativas_solucion.map((row, index) => (
                          <tr key={"alternativa_solucion" + index}>
                            <td>
                              <OverlayTrigger
                                key={"bottom"}
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    {
                                      this.state.alternativas_solucion[index]
                                        .alternativa
                                    }
                                  </Tooltip>
                                }
                              >
                                <textarea
                                  type="text"
                                  className="col-12"
                                  name={"alternativa"}
                                  placeholder="Escribe aquí"
                                  value={
                                    this.state.alternativas_solucion[index]
                                      .alternativa
                                  }
                                  data-idx={index}
                                  disabled={
                                    (isAdmin && fe_disabled) ||
                                    isAdmin ||
                                    (!isAdmin && fe_disabled)
                                  }
                                  onChange={this.onChangehandlerAlternativas_}
                                />
                              </OverlayTrigger>
                            </td>
                            <td>
                              <OverlayTrigger
                                key={"bottom"}
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    {
                                      this.state.alternativas_solucion[index]
                                        .ventajas
                                    }
                                  </Tooltip>
                                }
                              >
                                <textarea
                                  type="text"
                                  name={"ventajas"}
                                  className="col-12"
                                  placeholder="Escribe aquí"
                                  value={
                                    this.state.alternativas_solucion[index]
                                      .ventajas
                                  }
                                  data-idx={index}
                                  disabled={
                                    (isAdmin && fe_disabled) ||
                                    isAdmin ||
                                    (!isAdmin && fe_disabled)
                                  }
                                  onChange={this.onChangehandlerAlternativas_}
                                />
                              </OverlayTrigger>
                            </td>
                            <td>
                              <OverlayTrigger
                                key={"bottom"}
                                placement={"bottom"}
                                overlay={
                                  <Tooltip id={`tooltip-bottom`}>
                                    {
                                      this.state.alternativas_solucion[index]
                                        .desventajas
                                    }
                                  </Tooltip>
                                }
                              >
                                <textarea
                                  type="text"
                                  name={"desventajas"}
                                  className="col-12"
                                  placeholder="Escribe aquí"
                                  value={
                                    this.state.alternativas_solucion[index]
                                      .desventajas
                                  }
                                  data-idx={index}
                                  disabled={
                                    (isAdmin && fe_disabled) ||
                                    isAdmin ||
                                    (!isAdmin && fe_disabled)
                                  }
                                  onChange={this.onChangehandlerAlternativas_}
                                />
                              </OverlayTrigger>
                            </td>

                            {!isAdmin && !fe_disabled && (
                              <td>
                                <button
                                  className="btn btn-link delete-row"
                                  onClick={() => this.removeRowAlternativa(row)}
                                >
                                  <FontAwesomeIcon
                                    icon={faTimesCircle}
                                    size={"2x"}
                                  />
                                </button>
                              </td>
                            )}
                          </tr>
                        ))}
                      </tbody>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            <SideBarComments
              comments={this.state}
              sections={this.state}
              origin={"approval"}
              apartado={"Feasibilities"}
              id={this.props.id}
              botones={false}
            />

            {this.state.commentsFPFlag === true && (
              <div className="row">
                <div className="col-10 offset-1">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <label className="col-12">Comentarios del Admin</label>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="commentsFP"
                            value={this.state.commentsFP}
                            onChange={this.onChangehandler}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );

    tabla1 = (
      <>
        <div
          className="row"
          style={{
            display: this.state.VerTablaIndicadorAC,
          }}
        >
          <div className="col-10 offset-1">
            <div className="row subtitle-section">
              <div className="col-10"></div>
              {!isAdmin && !sw_disabled && (
                <div className="col-2">
                  <button
                    className="col-12 btn btn-link"
                    onClick={this.addRowCostQuantification}
                  >
                    <FontAwesomeIcon icon={faPlusCircle} />
                  </button>
                </div>
              )}
            </div>
            <div className="row">
              <div className="col-lg-12 col-sm-12">
                <Table bordered className="table-not-padding force-table">
                  <thead>
                    <tr>
                      <td colspan="7">
                        <strong>
                          Cuantificacion de Costos del PPI (sin incluir IVA)
                        </strong>{" "}
                      </td>
                    </tr>
                    <tr>
                      <td style={{ width: "45px" }}>
                        <strong> Año </strong>
                      </td>
                      <td>
                        <strong> Inversión </strong>
                      </td>
                      <td>
                        <strong> Operación </strong>
                      </td>
                      <td>
                        <strong> Mantenimiento</strong>
                      </td>
                      <td>
                        <strong> Otros costos</strong>
                      </td>
                      <td>
                        <strong> Total</strong>
                      </td>
                      <td>
                        <strong> Valor presente de costos totales</strong>
                      </td>
                    </tr>
                  </thead>
                  <tbody>
                    {this.state.cost_quantification.map((row, index) => (
                      <tr key={"alt1" + index}>
                        <td>
                          <OverlayTrigger
                            key={"bottom"}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                {this.state.cost_quantification[index].year}
                              </Tooltip>
                            }
                          >
                            <NumberFormat
                              className="d-block form-control border-0 size-14 text-center"
                              placeholder="0"
                              value={this.state.cost_quantification[index].year}
                              disabled={true}
                            />
                          </OverlayTrigger>
                        </td>
                        <td>
                          <OverlayTrigger
                            key={"bottom"}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                ${" "}
                                {parseFloat(
                                  this.state.cost_quantification[
                                    index
                                  ].inversion
                                    .toString()
                                    .replace(/,/g, "")
                                ).toFixed(
                                  parseInt(
                                    this.state.cantidadDecimales[0]
                                      .cantidadInversion[0].cantidad[index]
                                  )
                                )}
                              </Tooltip>
                            }
                          >
                            <NumberFormat
                              className="d-block form-control border-0 size-14 text-center amount-tx"
                              placeholder="$"
                              data-idx={index}
                              value={parseFloat(
                                this.state.cost_quantification[index].inversion
                                  .toString()
                                  .replace(/,/g, "")
                              )}
                              //onChange={this.onChangehandlerInversion}
                              onChange={(e) =>
                                this.handleChangeCost(e, "inversion")
                              }
                              //onClick={(e) => this.onClickhandlerInversion(e)}
                              thousandSeparator={true}
                              disabled={
                                (isAdmin && sw_disabled) ||
                                isAdmin ||
                                (!isAdmin && sw_disabled)
                              }
                              decimalSeparator={"."}
                              characterLimit={100}
                            />
                          </OverlayTrigger>
                        </td>
                        <td>
                          <OverlayTrigger
                            key={"bottom"}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                ${" "}
                                {parseFloat(
                                  parseFloat(
                                    this.state.cost_quantification[
                                      index
                                    ].operation
                                      .toString()
                                      .replace(/,/g, "")
                                  )
                                ).toFixed(
                                  parseInt(
                                    this.state.cantidadDecimales[0]
                                      .cantidadOperation[0]
                                  )
                                )}
                              </Tooltip>
                            }
                          >
                            <NumberFormat
                              className="d-block form-control border-0 size-14 text-center"
                              placeholder="$"
                              data-idx={index}
                              value={parseFloat(
                                this.state.cost_quantification[index].operation
                                  .toString()
                                  .replace(/,/g, "")
                              )}
                              //onChange={this.onChangehandlerOperation}
                              onChange={(e) =>
                                this.handleChangeCost(e, "operation")
                              }
                              //onClick={(e) => this.onClickhandlerOperation(e)}
                              thousandSeparator={true}
                              disabled={
                                (isAdmin && sw_disabled) ||
                                isAdmin ||
                                (!isAdmin && sw_disabled)
                              }
                              decimalSeparator={"."}
                              characterLimit={100}
                            />
                          </OverlayTrigger>
                        </td>
                        <td>
                          <OverlayTrigger
                            key={"bottom"}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                ${" "}
                                {parseFloat(
                                  this.state.cost_quantification[
                                    index
                                  ].maintenance
                                    .toString()
                                    .replace(/,/g, "")
                                ).toFixed(
                                  parseInt(
                                    this.state.cantidadDecimales[0]
                                      .cantidadMaintenance[0]
                                  )
                                )}
                              </Tooltip>
                            }
                          >
                            <NumberFormat
                              className="d-block form-control border-0 size-14 text-center"
                              placeholder="$"
                              data-idx={index}
                              value={parseFloat(
                                this.state.cost_quantification[
                                  index
                                ].maintenance
                                  .toString()
                                  .replace(/,/g, "")
                              )}
                              onChange={(e) =>
                                this.handleChangeCost(e, "maintenance")
                              }
                              //onClick={(e) => this.onClickhandlerMaintenance(e)}
                              thousandSeparator={true}
                              disabled={
                                (isAdmin && sw_disabled) ||
                                isAdmin ||
                                (!isAdmin && sw_disabled)
                              }
                              decimalSeparator={"."}
                              characterLimit={100}
                            />
                          </OverlayTrigger>
                        </td>
                        <td>
                          <OverlayTrigger
                            key={"bottom"}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                ${" "}
                                {parseFloat(
                                  this.state.cost_quantification[
                                    index
                                  ].externalities
                                    .toString()
                                    .replace(/,/g, "")
                                ).toFixed(
                                  parseInt(
                                    this.state.cantidadDecimales[0]
                                      .cantidadexternalities[0]
                                  )
                                )}
                              </Tooltip>
                            }
                          >
                            <NumberFormat
                              className="d-block form-control border-0 size-14 text-center"
                              placeholder="$"
                              data-idx={index}
                              value={parseFloat(
                                this.state.cost_quantification[
                                  index
                                ].externalities
                                  .toString()
                                  .replace(/,/g, "")
                              ).toFixed(
                                parseInt(
                                  this.state.cantidadDecimales[0]
                                    .cantidadexternalities[0]
                                )
                              )}
                              onChange={(e) =>
                                this.handleChangeCost(e, "externalities")
                              }
                              //onClick={(e) => this.onClickhandlerexternalities(e)}
                              thousandSeparator={true}
                              disabled={
                                (isAdmin && sw_disabled) ||
                                isAdmin ||
                                (!isAdmin && sw_disabled)
                              }
                              decimalSeparator={"."}
                              characterLimit={100}
                            />
                          </OverlayTrigger>
                        </td>
                        <td>
                          <OverlayTrigger
                            key={"bottom"}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                ${" "}
                                {parseFloat(
                                  this.state.cost_quantification[index].total
                                    .toString()
                                    .replace(/,/g, "")
                                ).toFixed(2)}
                              </Tooltip>
                            }
                          >
                            <NumberFormat
                              type="text"
                              className="form-control"
                              placeholder="$"
                              thousandSeparator={true}
                              style={{ "text-align": "right" }}
                              name="cost_quantification"
                              value={parseFloat(
                                this.state.cost_quantification[index].total
                                  .toString()
                                  .replace(/,/g, "")
                              ).toFixed(2)}
                              disabled={true}
                            />
                          </OverlayTrigger>
                        </td>
                        <td>
                          <OverlayTrigger
                            key={"bottom"}
                            placement={"bottom"}
                            overlay={
                              <Tooltip id={`tooltip-bottom`}>
                                ${" "}
                                {parseFloat(
                                  this.state.cost_quantification[index]
                                    .total_actualizado
                                ).toFixed(2)}
                              </Tooltip>
                            }
                          >
                            <NumberFormat
                              type="text"
                              className="form-control"
                              placeholder="$"
                              thousandSeparator={true}
                              style={{ "text-align": "right" }}
                              name="cost_quantification"
                              value={parseFloat(
                                this.state.cost_quantification[
                                  index
                                ].total_actualizado
                                  .toString()
                                  .replace(/,/g, "")
                              ).toFixed(2)}
                              disabled={true}
                            />
                          </OverlayTrigger>

                          {!isAdmin && !sw_disabled && (
                            <div
                              style={{
                                position: "absolute",
                                right: "-30px",
                                width: "60px",
                                "margin-top": "-30px",
                              }}
                            >
                              <button
                                className="btn btn-link delete-row"
                                disabled={
                                  this.state.processRemove ? true : false
                                }
                                onClick={() =>
                                  this.removeRowCostQuantification(row)
                                }
                              >
                                <FontAwesomeIcon
                                  icon={faTimesCircle}
                                  size={"2x"}
                                />
                              </button>
                            </div>
                          )}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                  <tfoot>
                    <tr>
                      <td>
                        <strong>Total</strong>
                      </td>
                      <td>
                        <NumberFormat
                          type="text"
                          className="form-control"
                          placeholder="Escribe aquí"
                          name="name_ppi"
                          disabled={true}
                          thousandSeparator={true}
                          prefix={"$"}
                          value={fm.from(
                            parseFloat(this.state.total_inversion)
                          )}
                          onChange={this.onChangehandler}
                        />
                      </td>
                      <td>
                        <NumberFormat
                          type="text"
                          className="form-control"
                          placeholder="Escribe aquí"
                          thousandSeparator={true}
                          prefix={"$"}
                          name="name_ppi"
                          disabled={true}
                          value={this.state.total_operation}
                          onChange={this.onChangehandler}
                        />
                      </td>
                      <td>
                        <NumberFormat
                          type="text"
                          className="form-control"
                          placeholder="Escribe aquí"
                          thousandSeparator={true}
                          prefix={"$"}
                          name="name_ppi"
                          disabled={true}
                          value={this.state.total_maintenance}
                          onChange={this.onChangehandler}
                        />
                      </td>
                      <td>
                        <NumberFormat
                          type="text"
                          className="form-control"
                          placeholder="Escribe aquí"
                          thousandSeparator={true}
                          prefix={"$"}
                          name="name_ppi"
                          disabled={true}
                          value={this.state.total_externalitie}
                          onChange={this.onChangehandler}
                        />
                      </td>
                      <td>
                        <NumberFormat
                          type="text"
                          className="form-control"
                          placeholder="Escribe aquí"
                          thousandSeparator={true}
                          prefix={"$"}
                          name="name_ppi"
                          disabled={true}
                          value={this.state.total_absolute}
                          onChange={this.onChangehandler}
                        />
                      </td>
                      <td>
                        <NumberFormat
                          type="text"
                          className="form-control"
                          placeholder="Escribe aquí"
                          name="name_ppi"
                          thousandSeparator={true}
                          prefix={"$"}
                          disabled={true}
                          value={this.state.total_absolute_actualizado}
                          onChange={this.onChangehandler}
                        />
                      </td>
                    </tr>
                  </tfoot>
                </Table>
              </div>
            </div>
          </div>
        </div>
      </>
    );

    seccion8 = (
      <>
        <div className="row title-section" disabled={VisualizarTodos}>
          <div className="col-10">Anexo: Indicadores de rentabilidad</div>

          {adminInRevision &&
            isResponsable &&
            this.state.project_situation_approved === false && (
              <div className="col-2">
                <button
                  className="btn btn-success btn-circle"
                  onClick={() => this.setDoneByAdmin("project_situation")}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <button
                  className="btn btn-orange btn-circle"
                  onClick={this.AddCommentPSP}
                >
                  <div className="icon material-icons">comment</div>
                </button>
              </div>
            )}
          {adminInRevision &&
            isResponsable &&
            this.state.project_situation_approved === true && (
              <div className="col-2">
                <button
                  className="btn btn-black btn-circle"
                  onClick={() => this.setUndoByAdmin("project_situation")}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </button>
              </div>
            )}
          {!adminInRevision &&
            this.state.project_situation_approved === true && (
              <div className="col-2">
                <span className="section-approved">Aprobada</span>
              </div>
            )}
        </div>

        <div className="row wrap-section">
          <div className="col-12 not-padding">
            {status === 5 ? (
              <div>
                <div>
                  <div className="nota-indicadores">
                    <FontAwesomeIcon
                      icon={faExclamationTriangle}
                      style={{ marginRight: "8px" }}
                    ></FontAwesomeIcon>
                    <strong>
                      Es necesario presionar el botón de calcular, antes guardar
                      o enviar a revisión el PPI
                    </strong>
                  </div>
                </div>
              </div>
            ) : null}
            {tabla1}

            <div
              className="row"
              style={{
                display: this.state.VerTablaIndicadorACE,
              }}
            >
              <div className="col-10 offset-1">
                <div className="row subtitle-section">
                  <div className="col-10"></div>
                  {!isAdmin && !psa_disabled && (
                    <div className="col-2">
                      <button
                        className="col-12 btn btn-link"
                        onClick={this.addRowCostQuantificationAlt2}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </button>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <Table bordered className="table-not-padding force-table">
                      <thead>
                        <tr>
                          <td colspan="7">
                            <strong>
                              Cuantificación del PPI alternativa 2 (sin incluir
                              IVA)
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td style={{ width: "45px" }}>
                            <strong>Año</strong>
                          </td>
                          <td>
                            <strong>Inversión</strong>
                          </td>
                          <td>
                            <strong>Operación</strong>
                          </td>
                          <td>
                            <strong>Mantenimiento</strong>
                          </td>
                          <td>
                            <strong>Otros costos</strong>
                          </td>
                          <td>
                            <strong>Total</strong>
                          </td>
                          <td>
                            <strong>Valor presente de costos totales</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.cost_quantification_alt2.map(
                          (row, index) => (
                            <tr key={"alt2_" + index}>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      ${" "}
                                      {fm.from(
                                        parseFloat(
                                          this.state.cost_quantification_alt2[
                                            index
                                          ].year
                                        )
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    className="d-block form-control border-0 size-14 text-center"
                                    placeholder="0"
                                    value={
                                      this.state.cost_quantification_alt2[index]
                                        .year
                                    }
                                    disabled={true}
                                  />
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      ${" "}
                                      {fm.from(
                                        parseFloat(
                                          parseFloat(
                                            this.state.cost_quantification_alt2[
                                              index
                                            ].inversion
                                              .toString()
                                              .replace(/,/g, "")
                                          )
                                        ).toFixed(
                                          parseInt(
                                            this.state.cantidadDecimales[0]
                                              .cantidadInversionALT[0].cantidad[
                                              index
                                            ]
                                          )
                                        )
                                      )}
                                      {/* $ {fm.from(parseFloat(this.state.cost_quantification_alt2[index].inversion.toString().replace(/,/g, '')))} */}
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    className="d-block form-control border-0 size-14 text-center"
                                    placeholder="$"
                                    data-idx={index}
                                    value={parseFloat(
                                      this.state.cost_quantification_alt2[
                                        index
                                      ].inversion
                                        .toString()
                                        .replace(/,/g, "")
                                    )}
                                    //value={parseFloat(this.state.cost_quantification_alt2[index].inversion.toString().replace(/,/g, '')).toFixed(parseInt(this.state.cantidadDecimales[0].cantidadInversionALT[0].cantidad[index]))}
                                    onChange={(e) =>
                                      this.handleChangeCostAlt2(e, "inversion")
                                    }
                                    thousandSeparator={true}
                                    disabled={
                                      (isAdmin && psa_disabled) ||
                                      isAdmin ||
                                      (!isAdmin && psa_disabled)
                                    }
                                    decimalSeparator={"."}
                                  />
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      {/* $ {fm.from(parseFloat(this.state.cost_quantification_alt2[index].operation.toString().replace(/,/g, '')))} */}
                                      ${" "}
                                      {fm.from(
                                        parseFloat(
                                          parseFloat(
                                            this.state.cost_quantification_alt2[
                                              index
                                            ].operation
                                              .toString()
                                              .replace(/,/g, "")
                                          )
                                        ).toFixed(
                                          parseInt(
                                            this.state.cantidadDecimales[0]
                                              .cantidadOperationALT[0].cantidad[
                                              index
                                            ]
                                          )
                                        )
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    className="d-block form-control border-0 size-14 text-center"
                                    placeholder="$"
                                    data-idx={index}
                                    // value={parseFloat(this.state.cost_quantification_alt2[index].operation).toFixed(2)}
                                    value={parseFloat(
                                      this.state.cost_quantification_alt2[
                                        index
                                      ].operation
                                        .toString()
                                        .replace(/,/g, "")
                                    )}
                                    onChange={(e) =>
                                      this.handleChangeCostAlt2(e, "operation")
                                    }
                                    thousandSeparator={true}
                                    disabled={
                                      (isAdmin && psa_disabled) ||
                                      isAdmin ||
                                      (!isAdmin && psa_disabled)
                                    }
                                    decimalSeparator={"."}
                                  />
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      {/* $ {fm.from(parseFloat(this.state.cost_quantification_alt2[index].maintenance.toString().replace(/,/g, '')))} */}
                                      ${" "}
                                      {fm.from(
                                        parseFloat(
                                          parseFloat(
                                            this.state.cost_quantification_alt2[
                                              index
                                            ].maintenance
                                              .toString()
                                              .replace(/,/g, "")
                                          )
                                        ).toFixed(
                                          parseInt(
                                            this.state.cantidadDecimales[0]
                                              .cantidadMaintenanceALT[0]
                                              .cantidad[index]
                                          )
                                        )
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    className="d-block form-control border-0 size-14 text-center"
                                    placeholder="$"
                                    data-idx={index}
                                    // value={parseFloat(this.state.cost_quantification_alt2[index].maintenance).toFixed(2)}
                                    value={parseFloat(
                                      this.state.cost_quantification_alt2[
                                        index
                                      ].maintenance
                                        .toString()
                                        .replace(/,/g, "")
                                    )}
                                    onChange={(e) =>
                                      this.handleChangeCostAlt2(
                                        e,
                                        "maintenance"
                                      )
                                    }
                                    thousandSeparator={true}
                                    disabled={
                                      (isAdmin && psa_disabled) ||
                                      isAdmin ||
                                      (!isAdmin && psa_disabled)
                                    }
                                    decimalSeparator={"."}
                                  />
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      {/* $ {fm.from(parseFloat(this.state.cost_quantification_alt2[index].externalities.toString().replace(/,/g, '')))} */}
                                      ${" "}
                                      {fm.from(
                                        parseFloat(
                                          parseFloat(
                                            this.state.cost_quantification_alt2[
                                              index
                                            ].externalities
                                              .toString()
                                              .replace(/,/g, "")
                                          )
                                        ).toFixed(
                                          parseInt(
                                            this.state.cantidadDecimales[0]
                                              .cantidadexternalitiesALT[0]
                                              .cantidad[index]
                                          )
                                        )
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    className="d-block form-control border-0 size-14 text-center"
                                    placeholder="$"
                                    data-idx={index}
                                    // value={parseFloat(this.state.cost_quantification_alt2[index].externalities).toFixed(2)}
                                    value={parseFloat(
                                      this.state.cost_quantification_alt2[
                                        index
                                      ].externalities
                                        .toString()
                                        .replace(/,/g, "")
                                    )}
                                    onChange={(e) =>
                                      this.handleChangeCostAlt2(
                                        e,
                                        "externalities"
                                      )
                                    }
                                    thousandSeparator={true}
                                    disabled={
                                      (isAdmin && psa_disabled) ||
                                      isAdmin ||
                                      (!isAdmin && psa_disabled)
                                    }
                                    decimalSeparator={"."}
                                  />
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      {/* $ {fm.from(parseFloat(this.state.cost_quantification_alt2[index].total.toString().replace(/,/g, '')))} */}
                                      ${" "}
                                      {fm.from(
                                        parseFloat(
                                          this.state.cost_quantification_alt2[
                                            index
                                          ].total
                                            .toString()
                                            .replace(/,/g, "")
                                        ).toFixed(
                                          this.state.cantidadDecimales[0]
                                            .total_cantidadALT[0].cantidad[
                                            index
                                          ]
                                        )
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    type="text"
                                    className="form-control"
                                    placeholder="$"
                                    style={{ "text-align": "right" }}
                                    name="cost_quantification"
                                    thousandSeparator={true}
                                    // value={this.state.cost_quantification_alt2[index].total}
                                    value={parseFloat(
                                      this.state.cost_quantification_alt2[
                                        index
                                      ].total
                                        .toString()
                                        .replace(/,/g, "")
                                    ).toFixed(
                                      parseInt(
                                        this.state.cantidadDecimales[0]
                                          .total_cantidadALT[0].cantidad[index]
                                      )
                                    )}
                                    disabled={true}
                                  />
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      ${" "}
                                      {parseFloat(
                                        this.state.cost_quantification_alt2[
                                          index
                                        ].total_actualizado
                                      ).toFixed(
                                        parseInt(
                                          this.state.cantidadDecimales[0]
                                            .total_cantidad2ALT[0].cantidad[
                                            index
                                          ]
                                        )
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    type="text"
                                    className="form-control"
                                    placeholder="$"
                                    style={{ "text-align": "right" }}
                                    name="cost_quantification"
                                    thousandSeparator={true}
                                    value={parseFloat(
                                      this.state.cost_quantification_alt2[index]
                                        .total_actualizado
                                    ).toFixed(
                                      parseInt(
                                        this.state.cantidadDecimales[0]
                                          .total_cantidad2ALT[0].cantidad[index]
                                      )
                                    )}
                                    disabled={true}
                                  />
                                </OverlayTrigger>
                                {!isAdmin && !psa_disabled && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "-30px",
                                      width: "60px",
                                      "margin-top": "-30px",
                                    }}
                                  >
                                    <button
                                      className="btn btn-link delete-row"
                                      onClick={() =>
                                        this.removeRowCostQuantificationAlt2(
                                          row
                                        )
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        size={"2x"}
                                      />
                                    </button>
                                  </div>
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            <strong>Total</strong>
                          </td>
                          <td>
                            <NumberFormat
                              type="text"
                              className="form-control"
                              placeholder="Escribe aquí"
                              name="name_ppi"
                              thousandSeparator={true}
                              prefix={"$"}
                              disabled={true}
                              value={this.state.total_inversion_alt2}
                              onChange={this.onChangehandler}
                            />
                          </td>
                          <td>
                            <NumberFormat
                              type="text"
                              className="form-control"
                              placeholder="Escribe aquí"
                              name="name_ppi"
                              thousandSeparator={true}
                              prefix={"$"}
                              disabled={true}
                              value={this.state.total_operation_alt2}
                              onChange={this.onChangehandler}
                            />
                          </td>
                          <td>
                            <NumberFormat
                              type="text"
                              className="form-control"
                              placeholder="Escribe aquí"
                              name="name_ppi"
                              thousandSeparator={true}
                              prefix={"$"}
                              disabled={true}
                              value={this.state.total_maintenance_alt2}
                              onChange={this.onChangehandler}
                            />
                          </td>
                          <td>
                            <NumberFormat
                              type="text"
                              className="form-control"
                              placeholder="Escribe aquí"
                              name="name_ppi"
                              thousandSeparator={true}
                              prefix={"$"}
                              disabled={true}
                              value={this.state.total_externalitie_alt2}
                              onChange={this.onChangehandler}
                            />
                          </td>
                          <td>
                            <NumberFormat
                              type="text"
                              className="form-control"
                              placeholder="Escribe aquí"
                              name="name_ppi"
                              thousandSeparator={true}
                              prefix={"$"}
                              disabled={true}
                              value={this.state.total_absolute_alt2}
                              onChange={this.onChangehandler}
                            />
                          </td>
                          <td>
                            <NumberFormat
                              type="text"
                              className="form-control"
                              placeholder="Escribe aquí"
                              name="name_ppi"
                              thousandSeparator={true}
                              prefix={"$"}
                              disabled={true}
                              value={this.state.total_absolute_actualizado_alt2}
                              onChange={this.onChangehandler}
                            />
                          </td>
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                </div>
              </div>
            </div>

            <div
              className="row"
              style={{
                display: this.state.VerTablaIndicadorABC,
              }}
            >
              <div className="col-10 offset-1">
                <div className="row subtitle-section">
                  <div className="col-10">
                    {/* <h4>Cuantificación de beneficios</h4> */}
                  </div>
                  {!isAdmin && !psa_disabled && (
                    <div className="col-2">
                      <button
                        className="col-12 btn btn-link"
                        onClick={this.addRowBenefitQuantification}
                      >
                        <FontAwesomeIcon icon={faPlusCircle} />
                      </button>
                    </div>
                  )}
                </div>
                <div className="row">
                  <div className="col-lg-12 col-sm-12">
                    <Table bordered className="table-not-padding force-table">
                      <thead>
                        <tr>
                          <td colspan="7">
                            <strong>
                              Cuantificacion de Beneficios del PPI (sin incluir
                              IVA)
                            </strong>
                          </td>
                        </tr>
                        <tr>
                          <td rowspan="2" style={{ width: "45px" }}>
                            <strong>Año</strong>
                          </td>
                          <td colspan="2">
                            <strong>Beneficios</strong>
                          </td>
                          <td rowspan="2">
                            <strong>Total</strong>
                          </td>
                          <td rowspan="2">
                            <strong>
                              Valor presente de los beneficios totales
                            </strong>
                          </td>
                          {/*<td rowspan="2">Beneficio neto (corriente)</td>
                                                    <td rowspan="2">TRI</td> */}
                        </tr>
                        <tr>
                          <td>
                            <strong>1</strong>
                          </td>
                          <td>
                            <strong>2</strong>
                          </td>
                        </tr>
                      </thead>
                      <tbody>
                        {this.state.benefits_quantification.map(
                          (row, index) => (
                            <tr key={"cost_" + index}>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      ${" "}
                                      {fm.from(
                                        parseFloat(
                                          this.state.benefits_quantification[
                                            index
                                          ].year
                                        )
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <input
                                    type="text"
                                    className="form-control"
                                    placeholder="Escribe aquí"
                                    name="cost_quantification"
                                    disabled={true}
                                    value={
                                      this.state.benefits_quantification[index]
                                        .year
                                    }
                                  />
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      ${" "}
                                      {parseFloat(
                                        this.state.benefits_quantification[
                                          index
                                        ].benefit_1
                                          .toString()
                                          .replace(/,/g, "")
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    className="d-block form-control border-0 size-14 text-center"
                                    placeholder="0"
                                    data-idx={index}
                                    value={parseFloat(
                                      this.state.benefits_quantification[
                                        index
                                      ].benefit_1
                                        .toString()
                                        .replace(/,/g, "")
                                    )}
                                    //value={parseFloat(this.state.cost_quantification_alt2[index].total.toString().replace(/,/g, '')).toFixed(parseInt(this.state.cantidadDecimales[0].total_cantidadALT[0].cantidad[index]))}
                                    onChange={(e) =>
                                      this.handleChangeBenefitQuantification(
                                        e,
                                        "benefit_1"
                                      )
                                    }
                                    thousandSeparator={true}
                                    disabled={
                                      (isAdmin && psa_disabled) ||
                                      isAdmin ||
                                      (!isAdmin && psa_disabled)
                                    }
                                    decimalSeparator={"."}
                                  />
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      ${" "}
                                      {fm.from(
                                        parseFloat(
                                          this.state.benefits_quantification[
                                            index
                                          ].benefit_2
                                            .toString()
                                            .replace(/,/g, "")
                                        )
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    className="d-block form-control border-0 size-14 text-center"
                                    placeholder="0"
                                    data-idx={index}
                                    value={parseFloat(
                                      this.state.benefits_quantification[
                                        index
                                      ].benefit_2
                                        .toString()
                                        .replace(/,/g, "")
                                    )}
                                    onChange={(e) =>
                                      this.handleChangeBenefitQuantification(
                                        e,
                                        "benefit_2"
                                      )
                                    }
                                    thousandSeparator={true}
                                    disabled={
                                      (isAdmin && psa_disabled) ||
                                      isAdmin ||
                                      (!isAdmin && psa_disabled)
                                    }
                                    decimalSeparator={"."}
                                  />
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      ${" "}
                                      {fm.from(
                                        parseFloat(
                                          this.state.benefits_quantification[
                                            index
                                          ].total
                                        )
                                      )}
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    type="text"
                                    className="form-control"
                                    placeholder="$"
                                    style={{ "text-align": "right" }}
                                    name="cost_quantification"
                                    thousandSeparator={true}
                                    value={
                                      this.state.benefits_quantification[index]
                                        .total
                                    }
                                    disabled={true}
                                  />
                                </OverlayTrigger>
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      ${" "}
                                      {
                                        this.state.benefits_quantification[
                                          index
                                        ].total_beneficios
                                      }
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    type="text"
                                    className="form-control"
                                    placeholder="$"
                                    style={{ "text-align": "right" }}
                                    thousandSeparator={true}
                                    name="cost_quantification"
                                    value={
                                      this.state.benefits_quantification[index]
                                        .total_beneficios
                                    }
                                    disabled={true}
                                  />
                                </OverlayTrigger>
                              </td>
                              {/*<td>
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    $ {this.state.benefits_quantification[index].total_neto}
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="$" 
                                                            style={{'text-align':'right'}}
                                                            name="cost_quantification" 
                                                            value={this.state.benefits_quantification[index].total_neto}
                                                            disabled={true}
                                                            />
                                                        </OverlayTrigger>
                                                        </td> */}
                              <td>
                                {/*  
                                                        <OverlayTrigger
                                                            key={'bottom'}
                                                            placement={'bottom'}
                                                            overlay={
                                                                <Tooltip id={`tooltip-bottom`}>
                                                                    {this.state.benefits_quantification[index].tri} %
                                                                </Tooltip>
                                                            }
                                                        >
                                                        <NumberFormat
                                                            className="d-block form-control border-0 size-14 text-center"
                                                            placeholder="0"
                                                            data-idx={index}
                                                            suffix={'%'}
                                                            disabled={true}
                                                            value={this.state.benefits_quantification[index].tri}
                                                            onChange={this.onChangehandlerBenefitTotal}
                                                            thousandSeparator={true}
                                                            
                                                            decimalSeparator={'.'}
                                                        />
                                                        </OverlayTrigger>{ }  */}
                                {!isAdmin && !psa_disabled && (
                                  <div
                                    style={{
                                      position: "absolute",
                                      right: "-30px",
                                      width: "60px",
                                      "margin-top": "-30px",
                                    }}
                                  >
                                    <button
                                      className="btn btn-link delete-row"
                                      onClick={() =>
                                        this.removeRowBenefitQuantification(row)
                                      }
                                    >
                                      <FontAwesomeIcon
                                        icon={faTimesCircle}
                                        size={"2x"}
                                      />
                                    </button>
                                  </div>
                                )}
                              </td>
                            </tr>
                          )
                        )}
                      </tbody>
                      <tfoot>
                        <tr>
                          <td>
                            <strong>Total</strong>
                          </td>
                          <td>
                            <NumberFormat
                              type="text"
                              className="form-control"
                              placeholder="Escribe aquí"
                              name="name_ppi"
                              thousandSeparator={true}
                              prefix={"$"}
                              disabled={true}
                              value={this.state.total_benefit_1}
                              onChange={this.onChangehandler}
                            />
                          </td>
                          <td>
                            <NumberFormat
                              type="text"
                              className="form-control"
                              placeholder="Escribe aquí"
                              thousandSeparator={true}
                              prefix={"$"}
                              name="name_ppi"
                              disabled={true}
                              value={this.state.total_benefit_2}
                              onChange={this.onChangehandler}
                            />
                          </td>
                          <td>
                            <NumberFormat
                              type="text"
                              className="form-control"
                              placeholder="Escribe aquí"
                              name="name_ppi"
                              thousandSeparator={true}
                              prefix={"$"}
                              disabled={true}
                              value={this.state.total_benefit_absolute}
                              onChange={this.onChangehandler}
                            />
                          </td>
                          <td>
                            <NumberFormat
                              type="text"
                              className="form-control"
                              placeholder="Escribe aquí"
                              name="name_ppi"
                              thousandSeparator={true}
                              prefix={"$"}
                              disabled={true}
                              value={
                                this.state.total_benefit_absolute_actualizado
                              }
                              onChange={this.onChangehandler}
                            />
                          </td>
                          {/*<td>
                                                        <input 
                                                            type="text" 
                                                            className="form-control" 
                                                            placeholder="Escribe aquí" 
                                                            name="name_ppi" 
                                                            disabled={true} 
                                                            value={this.state.total_benefit_absolute_neto}
                                                            onChange={this.onChangehandler} 
                                                            />
                                                    </td>
                                                    <td>
                                                        
                                                        </td>*/}
                        </tr>
                      </tfoot>
                    </Table>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row subtitle-section">
                  <div
                    className="col-sm-12 col-lg-6 offset-lg-3"
                    style={{ textAlign: "center" }}
                  >
                    <h6>Cálculo de indicadores de rentabilidad</h6>
                  </div>
                </div>
                <div className="row d-flex">
                  <div className="col-lg-12">
                    <div className="row">
                      <div
                        className="col-lg-4 col-sm-6"
                        style={{ position: "relative" }}
                      >
                        {!isAdmin && !psa_disabled && (
                          <button
                            style={{
                              position: "center",
                              top: "10px", // Ajusta esta propiedad para la distancia desde la parte superior
                              right: "10px", // Ajusta esta propiedad para la distancia desde la derecha
                              padding: "10px 20px", // Agregar relleno para hacer el botón más grande
                              background: "#2C70B9", // Cambiar el color de fondo
                              color: "#fff", // Cambiar el color del texto
                              border: "none", // Eliminar el borde
                              borderRadius: "5px", // Agregar bordes redondeados
                              cursor: "pointer", // Cambiar el cursor al pasar el mouse
                              fontSize: "16px", // Cambiar el tamaño del texto
                            }}
                            onClick={this.handleButtonClick}
                          >
                            Calcular
                          </button>
                        )}
                      </div>
                      <div
                        className="col-8 col-lg-8"
                        style={{
                          display: this.state.VerTablaIndicadorABC,
                        }}
                      >
                        <Table bordered className="table-not-padding">
                          <thead>
                            <tr>
                              <td colspan="2">
                                <strong>Indicadores</strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <strong>VPN</strong>
                              </td>
                              <td>
                                <NumberFormat
                                  className="d-block form-control border-0 size-14 text-center amount-txt"
                                  placeholder="$"
                                  name="vpn"
                                  prefix={"$"}
                                  disabled={true}
                                  onChange={this.onChangehandler}
                                  value={this.state.vpn}
                                  thousandSeparator={true}
                                  maxLength={3}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>TIR</strong>
                              </td>
                              <td>
                                <NumberFormat
                                  className="d-block form-control border-0 size-14 text-center amount-txt"
                                  placeholder="%"
                                  name="tir"
                                  suffix={"%"}
                                  disabled={true}
                                  onChange={this.onChangehandler}
                                  value={this.state.tir}
                                  thousandSeparator={true}
                                  decimalSeparator={"."}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong>TRI</strong>
                              </td>
                              <td>
                                <NumberFormat
                                  className="d-block form-control border-0 size-14 text-center amount-txt"
                                  placeholder="%"
                                  name="tri"
                                  suffix={"%"}
                                  disabled={true}
                                  onChange={this.onChangehandler}
                                  value={this.state.tri}
                                  thousandSeparator={true}
                                  decimalSeparator={"."}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <center>
                                  <b>Año óptimo de inicio de operación</b>
                                </center>
                              </td>
                              <td>
                                <input
                                  type="text"
                                  className="d-block form-control border-0 size-14 text-center amount-txt"
                                  name="optimal_tri"
                                  disabled={true}
                                  value={this.state.optimal_tri}
                                  onChange={this.onChangehandler}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>

                      <div
                        className="col-lg-8 col-sm-6"
                        style={{
                          display: this.state.VerTablaIndicadorACE,
                          textAlign: "Center",
                        }}
                      >
                        <Table bordered className="table-not-padding">
                          <thead>
                            <tr>
                              <td colspan="2">
                                <strong>CAE</strong>
                              </td>
                            </tr>
                          </thead>
                          <tbody>
                            <tr>
                              <td>
                                <strong>CAE del PPI</strong>
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      {this.state.ppi_evaluado}
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    className="d-block form-control border-0 size-14 text-center amount-txt"
                                    name="ppi_evaluado"
                                    prefix={"$"}
                                    disabled={true}
                                    onChange={this.onChangehandler}
                                    value={this.state.ppi_evaluado}
                                    thousandSeparator={true}
                                    decimalSeparator={"."}
                                  />
                                </OverlayTrigger>
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <strong> CAE Alternativa</strong>
                              </td>
                              <td>
                                <OverlayTrigger
                                  key={"bottom"}
                                  placement={"bottom"}
                                  overlay={
                                    <Tooltip id={`tooltip-bottom`}>
                                      {this.state.alternativa}
                                    </Tooltip>
                                  }
                                >
                                  <NumberFormat
                                    className="d-block form-control border-0 size-14 text-center amount-txt"
                                    onChange={this.onChangehandler}
                                    name="alternativa"
                                    prefix={"$"}
                                    disabled={true}
                                    value={this.state.alternativa}
                                    thousandSeparator={true}
                                    decimalSeparator={"."}
                                  />
                                </OverlayTrigger>
                              </td>
                            </tr>
                          </tbody>
                        </Table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <SideBarComments
              comments={this.state}
              sections={this.state}
              origin={"approval"}
              apartado={"Situation_Wproject"}
              id={this.props.id}
              botones={false}
            />

            {this.state.commentsPSPFlag === true && (
              <div className="row">
                <div className="col-10 offset-1">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <label className="col-12">Comentarios del Admin</label>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="commentsPSP"
                            value={this.state.commentsPSP}
                            onChange={this.onChangehandler}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );

    seccion9 = (
      <>
        <div className="row title-section" disabled={VisualizarTodos}>
          <div className="col-10">Anexo: Estudio socioeconómico</div>
          {adminInRevision &&
            isResponsable &&
            this.state.situation_wppi_approved === false && (
              <div className="col-2">
                <button
                  className="btn btn-success btn-circle"
                  onClick={() => this.setDoneByAdmin("situation_ppi")}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <button
                  className="btn btn-orange btn-circle"
                  onClick={this.AddCommentSWP}
                >
                  <div className="icon material-icons">comment</div>
                </button>
              </div>
            )}
          {adminInRevision &&
            isResponsable &&
            this.state.situation_wppi_approved === true && (
              <div className="col-2">
                <button
                  className="btn btn-black btn-circle"
                  onClick={() => this.setUndoByAdmin("situation_ppi")}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </button>
              </div>
            )}
          {!adminInRevision && this.state.situation_wppi_approved === true && (
            <div className="col-2">
              <span className="section-approved">Aprobada</span>
            </div>
          )}
        </div>

        <div className="row wrap-section">
          <div className="col-12 not-padding">
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row d-flex">
                  <div className="col-lg-8">
                    <div className="row">
                      <small className="col-12 note text-left">
                        Adjuntar evaluación socioeconómica, hoja de cálculo y
                        demás archivos que respalden el estudio{" "}
                      </small>
                    </div>
                  </div>
                  {
                    <div className="col-lg-4">
                      <Form.Control
                        type="file"
                        id="custom-file"
                        ref={(input) => (this.archivosInput = input)}
                        onChange={this.handleChangeArchivosFileInput}
                        multiple
                      />
                      <button
                        className="col-12 btn btn-primary"
                        onClick={this.handleInputArchivosClick}
                      >
                        <FontAwesomeIcon icon={faPaperclip} /> Agregar
                      </button>
                    </div>
                  }
                </div>
                <div className="row">
                  <div className="col-12">
                    {this.state.archivos_nombre.length > 0 &&
                      this.state.archivos_nombre.map((archivo, idx) => (
                        <div
                          className="row row-file"
                          key={"estudiosocioeconomico_" + idx}
                        >
                          <div className="col-1 file-icon">
                            <FontAwesomeIcon icon={faFileAlt} />
                          </div>
                          <div
                            className="col-8 text-collapsed file-name"
                            style={{ textAlign: "left" }}
                          >
                            <span>{archivo.name}</span>
                          </div>

                          <div
                            className="col-1 file-download"
                            style={{ textAlign: "center" }}
                          >
                            {archivo.path && (
                              <div>
                                <a
                                  href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${archivo.path}`}
                                  download
                                >
                                  <FontAwesomeIcon icon={faDownload} />
                                </a>
                              </div>
                            )}
                          </div>
                          {
                            <div
                              className="col-1"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                className="col-12 btn btn-custom-delete"
                                onClick={() =>
                                  this.handleDeleteArchivoClick(archivo)
                                }
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </button>
                            </div>
                          }
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
            <SideBarComments
              comments={this.state}
              sections={this.state}
              origin={"approval"}
              apartado={"Project_Situation"}
              id={this.props.id}
              botones={false}
            />

            {this.state.commentsSWPFlag === true && (
              <div className="row">
                <div className="col-10 offset-1">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <label className="col-12">Comentarios del Admin</label>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="commentsSWP"
                            value={this.state.commentsSWP}
                            onChange={this.onChangehandler}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </>
    );

    seccion7 = (
      <>
        <div className="row title-section" disabled={VisualizarTodos}>
          <div className="col-10">Consideraciones finales</div>
          {adminInRevision &&
            isResponsable &&
            this.state.attachments_approved === false && (
              <div className="col-2">
                <button
                  className="btn btn-success btn-circle"
                  onClick={() => this.setDoneByAdmin("attachments")}
                >
                  <FontAwesomeIcon icon={faCheck} />
                </button>
                <button
                  className="btn btn-orange btn-circle"
                  onClick={this.AddCommentAP}
                >
                  <div className="icon material-icons">comment</div>
                </button>
              </div>
            )}
          {adminInRevision &&
            isResponsable &&
            this.state.attachments_approved === true && (
              <div className="col-2">
                <button
                  className="btn btn-black btn-circle"
                  onClick={() => this.setUndoByAdmin("attachments")}
                >
                  <FontAwesomeIcon icon={faUndo} />
                </button>
              </div>
            )}
          {!adminInRevision && this.state.attachments_approved === true && (
            <div className="col-2">
              <span className="section-approved">Aprobada</span>
            </div>
          )}
        </div>
        <div className="row wrap-section">
          <div className="col-12 not-padding">
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row d-flex" style={{ textAlign: "left" }}>
                  <div className="col-12">
                    {this.state.fecha ? (
                      <div>
                        <div>
                          <div className="nota-nueva">
                            <strong>
                            La dependencia solicitante es responsable de la propuesta económica y financiera, así como también contar con los estudios técnicos, ambientales, legales, permisos o documentos que garanticen la factibilidad de ejecución del PPI.
                            </strong>
                          </div>
                        </div>
                      </div>
                    ) : null}

                    <br></br>
                    <div className="row">
                      <div className="col-4">
                        <label>Nombre del solicitante</label>
                        <Form.Control
                          size="sm"
                          as="select"
                          name="name_applicant"
                          onChange={this.onChangehandler}
                          disabled={true}
                          value={this.state.name_applicant}
                        >
                          <option value="0">Seleccionar</option>
                          {this.state.users.map((type, idx) => (
                            <option
                              key={idx}
                              name="name_applicant"
                              value={type.value}
                            >
                              {type.name}
                            </option>
                          ))}
                        </Form.Control>
                      </div>

                      <div className="col-4">
                        <label>Cargo</label>
                        <input
                          type="text"
                          className="form-control"
                          name="puesto"
                          disabled={true}
                          value={this.state.puesto}
                          placeholder="Cargo"
                          onChange={this.onChangehandler}
                        />
                      </div>

                      <div className="col-4">
                        <label>NIP</label>
                        <input
                          type="password"
                          className="form-control"
                          name="applicant_pin"
                          disabled={
                            (isAdmin && da_disabled) ||
                            isAdmin ||
                            (!isAdmin && da_disabled)
                          }
                          value={this.state.applicant_pin}
                          onChange={this.onChangehandler}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <p
                          style={{ textAlign: "left", "margin-bottom": "0px" }}
                        >
                          <br />
                          {/* <b style={{ 'color': '#2C70B9' }}>(Solicitante)</b> */}
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row d-flex" style={{ textAlign: "left" }}>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-4">
                        <label>Nombre del ejecutor</label>
                        {!isAdmin && !da_disabled ? (
                          <input
                            type="text"
                            className="form-control"
                            placeholder="Escribe aquí"
                            name="executor_name"
                            value={this.state.executor_name}
                            onChange={this.onChangehandler}
                          />
                        ) : (
                          <div style={{ textAlign: "left" }}>
                            {this.state.executor_name}
                          </div>
                        )}
                      </div>

                      <div className="col-4">
                        <label>Cargo</label>
                        {!isAdmin && !da_disabled ? (
                          <input
                            type="text"
                            disabled={
                              (isAdmin && da_disabled) ||
                              isAdmin ||
                              (!isAdmin && da_disabled)
                            }
                            className="form-control"
                            placeholder="Escribe aquí"
                            name="executor_title"
                            value={this.state.executor_title}
                            onChange={this.onChangehandler}
                          />
                        ) : (
                          <div style={{ textAlign: "left" }}>
                            {this.state.executor_title}
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="row">
                      <div className="col-12">
                        <p
                          style={{ textAlign: "left", "margin-bottom": "0px" }}
                        >
                          <br />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row subtitle-section">
                  <div className="col-12">
                    <small className="note">Solo se permite PDF </small>
                  </div>

                  <label className="col-12">
                    Oficio de responsabilidad de ejecución del PPI
                  </label>
                </div>

                {!isAdmin && !da_disabled && (
                  <div className="row">
                    <div className="col-3">
                      <Form.File
                        accept=".pdf, application/pdf,application/msword"
                        id="custom-file"
                        ref={(input) => (this.fileOficioInput = input)}
                        onChange={this.handleChangeOficioFileInput}
                      />
                      <button
                        className="col-12 btn btn-primary"
                        onClick={this.handleInputFileOficioClick}
                      >
                        <FontAwesomeIcon icon={faPaperclip} /> Agregar
                      </button>
                    </div>
                  </div>
                )}

                {this.state.showOficioFile ? (
                  <div className="row row-file">
                    <div className="col-1 file-icon">
                      <FontAwesomeIcon icon={faFileAlt} />
                    </div>
                    <div
                      className="col-8 text-collapsed file-name"
                      style={{ textAlign: "left" }}
                    >
                      <span>{this.state.responsable_document_name}</span>
                    </div>

                    <div
                      className="col-1 file-download"
                      style={{ textAlign: "center" }}
                    >
                      <a
                        href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.oficioFile}`}
                        download
                      >
                        <FontAwesomeIcon icon={faDownload} />
                      </a>
                    </div>
                    {!isAdmin && !da_disabled && (
                      <div className="col-1" style={{ textAlign: "center" }}>
                        <button
                          className="col-12 btn btn-custom-delete"
                          onClick={this.handleDeleteOficio}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} />
                        </button>
                      </div>
                    )}
                  </div>
                ) : null}
              </div>
            </div>
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row d-flex">
                  <div className="col-lg-12">
                    <div className="row">
                      <label className="col-12">Comentarios finales</label>
                    </div>
                  </div>
                </div>
                <div className="row d-flex">
                  <div className="col-lg-12">
                    {!isAdmin && !da_disabled ? (
                      <textarea
                        className="col-12"
                        placeholder="Escribe aquí"
                        name="final_comments"
                        value={this.state.final_comments}
                        onChange={this.onChangehandler}
                      ></textarea>
                    ) : (
                      <div
                        style={{
                          textAlign: "justify",
                          "white-space": "pre-line",
                        }}
                      >
                        {this.state.final_comments}
                      </div>
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row wrap-section">
          <div className="col-12 not-padding">
            <div className="row">
              <div className="col-10 offset-1">
                <div className="row d-flex">
                  <div className="col-lg-8">
                    <div className="row">
                      <small className="col-12 note text-left">
                        Documentos adicionales que respalden la factibilidad del
                        PPI
                      </small>
                      <label className="col-12">
                        Documentos adjuntos <span className="fileName"></span>
                      </label>
                    </div>
                  </div>
                  {!isAdmin && !da_disabled ? (
                    <div className="col-lg-4">
                      <Form.Control
                        type="file"
                        id="custom-file"
                        ref={(input) => (this.attachmentsInput = input)}
                        onChange={this.handleChangeAttachmentsFileInput}
                        multiple
                      />
                      <button
                        className="col-12 btn btn-primary"
                        onClick={this.handleInputAttachmentsClick}
                      >
                        <FontAwesomeIcon icon={faPaperclip} /> Agregar
                      </button>
                    </div>
                  ) : (
                    "0"
                  )}
                </div>
                <div className="row">
                  <div className="col-12">
                    {this.state.attachments_names.length > 0 &&
                      this.state.attachments_names.map((file, idx) => (
                        <div className="row row-file" key={"attachment_" + idx}>
                          <div className="col-1 file-icon">
                            <FontAwesomeIcon icon={faFileAlt} />
                          </div>
                          <div
                            className="col-8 text-collapsed file-name"
                            style={{ textAlign: "left" }}
                          >
                            <span>{file.name}</span>
                          </div>

                          <div
                            className="col-1 file-download"
                            style={{ textAlign: "center" }}
                          >
                            {file.path && (
                              <div>
                                <a
                                  href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${file.path}`}
                                  download
                                >
                                  <FontAwesomeIcon icon={faDownload} />
                                </a>
                              </div>
                            )}
                          </div>
                          {!isAdmin && !da_disabled && (
                            <div
                              className="col-1"
                              style={{ textAlign: "center" }}
                            >
                              <button
                                className="col-12 btn btn-custom-delete"
                                onClick={() =>
                                  this.handleDeleteAttachmentsClick(file)
                                }
                              >
                                <FontAwesomeIcon icon={faTrashAlt} />
                              </button>
                            </div>
                          )}
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>

            <SideBarComments
              comments={this.state}
              sections={this.state}
              origin={"approval"}
              apartado={"Attachments"}
              id={this.props.id}
              botones={false}
            />

            {this.state.commentsAPFlag === true && (
              <div className="row">
                <div className="col-10 offset-1">
                  <div className="row">
                    <div className="col-lg-12">
                      <div className="row">
                        <label className="col-12">Comentarios del Admin</label>
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="commentsAP"
                            value={this.state.commentsAP}
                            onChange={this.onChangehandler}
                          ></textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
            {botonesobservaciones}
          </div>
        </div>
      </>
    );

    if (user.fk_role === 1 && status === 6) {
      adminInRevision = true;
    }
    if (user.fk_role === 1) {
      isAdmin = true;
    }
    var isResponsable =
      this.state.id_responsable === user.pk_user ? true : false;
    var gi_disabled =
      !adminInRevision && this.state.general_information_approved === true
        ? true
        : false;

    var municipios = "col-lg-6 col-sm-12";
    if (gi_disabled) {
      municipios = "col-lg-7 col-sm-12";
    }

    var pd_disabled =
      !adminInRevision && this.state.problem_description_approved === true
        ? true
        : false;
    var gd_disabled =
      !adminInRevision && this.state.general_description_approved === true
        ? true
        : false;
    var ob_disabled =
      !adminInRevision && this.state.objective_approved === true ? true : false;
    var er_disabled =
      !adminInRevision && this.state.exesution_results_approved === true
        ? true
        : false;
    var sal_disabled =
      !adminInRevision && this.state.solution_alternatives_approved === true
        ? true
        : false;
    var fe_disabled =
      !adminInRevision && this.state.feasibilities_approved === true
        ? true
        : false;
    var cg_disabled =
      !adminInRevision && this.state.general_considerations_approved === true
        ? true
        : false;
    var da_disabled =
      !adminInRevision && this.state.attachments_approved === true
        ? true
        : false;

    var gi_disabled =
      !adminInRevision && this.state.general_information_approved === true
        ? true
        : false;
    var sw_disabled =
      !adminInRevision && this.state.situation_wppi_approved === true
        ? true
        : false;
    var cg_disabled =
      !adminInRevision &&
      this.state.general_considerations_protesta_approved === true
        ? true
        : false;
    var da_disabled =
      !adminInRevision && this.state.attachments_approved === true
        ? true
        : false;

    var psa_disabled =
      !adminInRevision && this.state.project_situation_approved === true
        ? true
        : false;

    return (
      <div
        className="col col-sm-12 col-md-11 NotaTecnica"
        style={{ "margin-left": "auto", "margin-right": "auto" }}
        onScroll={() => {
          this.handleScroll();
        }}
      >
        {message}

        {this.state.tipoFormato.map((tipo, index) => {
          // Agrega el console.log aquí

          return (
            Number(tipo) === 1 && (
              <div key={index}>
                {index === 0
                  ? seccion1
                  : index === 1
                  ? seccion2
                  : index === 2
                  ? seccion3
                  : index === 3
                  ? this.state.formato == 5 || this.state.formato == 6
                    ? seccion5
                    : seccion4
                  : index === 4
                  ? this.state.formato == 5 || this.state.formato == 6
                    ? seccion4
                    : seccion5
                  : index === 5
                  ? seccion6
                  : index === 6
                  ? seccion8
                  : index === 7
                  ? seccion7
                  : index === 8
                  ? seccion9
                  : null}
              </div>
            )
          );
        })}

        <Modal show={this.state.showModal} onHide={this.handleClose}>
          <Modal.Body>
            {this.state.icon === "warning" && (
              <FontAwesomeIcon
                style={{ color: "#E79B00" }}
                icon={faExclamationTriangle}
                size="2x"
              />
            )}
            {this.state.icon === "success" && (
              <FontAwesomeIcon
                style={{ color: "#4CAF50" }}
                icon={faCheckCircle}
                size="2x"
              />
            )}
            <div className="size-24 bold pb-2">{this.state.titleModal}</div>
            <p className="size-16 pb-4 col col-10 offset-1">
              {this.state.messageModal}
            </p>

            <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
              <div
                className="d-block btn btn-small btn-primary h-30 col col-12"
                onClick={this.handleClose}
              >
                Volver
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.showModalConfirm}
          onHide={this.handleCloseConfirm}
        >
          <Modal.Body>
            <FontAwesomeIcon
              style={{ color: "#E79B00" }}
              icon={faExclamationTriangle}
              size="2x"
            />
            <div className="size-24 bold pb-2">¡Advertencia!</div>
            <p className="size-16 pb-4 col col-10 offset-1">
              Estás a punto de borrar un documento adjunto, ¿deseas continuar?
            </p>

            <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
              <div
                className="d-block btn btn-small btn-secondary primary h-30 col col-6"
                onClick={this.handleCloseConfirm}
              >
                Cancelar
              </div>
              <div
                className="d-block btn btn-small btn-danger h-30 col col-6"
                onClick={this.ConfirmDeleteAttachment}
              >
                Si, Borrar
              </div>
            </div>
          </Modal.Body>
        </Modal>
        <Modal
          show={this.state.ShowModalArchivoConfirm}
          onHide={this.handleCloseConfirmArchivo}
        >
          <Modal.Body>
            <FontAwesomeIcon
              style={{ color: "#E79B00" }}
              icon={faExclamationTriangle}
              size="2x"
            />
            <div className="size-24 bold pb-2">¡Advertencia!</div>
            <p className="size-16 pb-4 col col-10 offset-1">
              Estás a punto de borrar un documento adjunto, ¿deseas continuar?
            </p>

            <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
              <div
                className="d-block btn btn-small btn-secondary primary h-30 col col-6"
                onClick={this.handleCloseConfirmArchivo}
              >
                Cancelar
              </div>
              <div
                className="d-block btn btn-small btn-danger h-30 col col-6"
                onClick={this.ConfirmDeleteArchivo}
              >
                Si, Borrar
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showModalDeleteImagen}
          onHide={this.handleCloseDeleteImage}
        >
          <Modal.Body>
            <FontAwesomeIcon
              style={{ color: "#4CAF50" }}
              icon={faCheckCircle}
              size="2x"
            />
            <div className="size-24 bold pb-2">¡Documento eliminado !</div>

            <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
              <div
                className="d-block btn btn-small btn-primary primary h-30 col col-12"
                onClick={this.handleCloseDeleteImage}
              >
                Aceptar
              </div>
            </div>
          </Modal.Body>
        </Modal>

        <Modal
          show={this.state.showModalImagesConfirm}
          onHide={this.handleCloseConfirmImage}
        >
          <Modal.Body>
            <FontAwesomeIcon
              style={{ color: "#E79B00" }}
              icon={faExclamationTriangle}
              size="2x"
            />
            <div className="size-24 bold pb-2">¡Advertencia!</div>
            <p className="size-16 pb-4 col col-10 offset-1">
              {" "}
              Estas apunto de borrar la plantilla de imágenes situacion actual,
              ¿deseas continuar?
            </p>

            <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
              <div
                className="d-block btn btn-small btn-secondary primary h-30 col col-6"
                onClick={this.handleCloseConfirmImage}
              >
                Cancelar
              </div>
              <div
                className="d-block btn btn-small btn-danger h-30 col col-6"
                onClick={this.handleDeletePicFile}
              >
                Si, Borrar
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/*Oficio*/}
        <Modal
          show={this.state.ShowModalOficioConfirm}
          onHide={this.handleCloseConfirmOficio}
        >
          <Modal.Body>
            <FontAwesomeIcon
              style={{ color: "#E79B00" }}
              icon={faExclamationTriangle}
              size="2x"
            />
            <div className="size-24 bold pb-2">¡Advertencia!</div>
            <p className="size-16 pb-4 col col-10 offset-1">
              {" "}
              Estas apunto de borrar el oficio de responsabilidad de ejecución
              del PPI, ¿deseas continuar?
            </p>

            <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
              <div
                className="d-block btn btn-small btn-secondary primary h-30 col col-6"
                onClick={this.handleCloseConfirmOficio}
              >
                Cancelar
              </div>
              <div
                className="d-block btn btn-small btn-danger h-30 col col-6"
                onClick={this.ConfirmDeleteOficio}
              >
                Si, Borrar
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {/** Plano arquitectonico 4*/}

        <Modal
          show={this.state.ShowModalPlansConfirm}
          onHide={this.handleCloseConfirmPlans}
        >
          <Modal.Body>
            <FontAwesomeIcon
              style={{ color: "#E79B00" }}
              icon={faExclamationTriangle}
              size="2x"
            />
            <div className="size-24 bold pb-2">¡Advertencia!</div>
            <p className="size-16 pb-4 col col-10 offset-1">
              {" "}
              Estas apunto de borrar la plantilla de imágenes situacion actual
              con PPI, ¿deseas continuar?
            </p>

            <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
              <div
                className="d-block btn btn-small btn-secondary primary h-30 col col-6"
                onClick={this.handleCloseConfirmPlans}
              >
                Cancelar
              </div>
              <div
                className="d-block btn btn-small btn-danger h-30 col col-6"
                onClick={this.ConfirmDeletePlans}
              >
                Si, Borrar
              </div>
            </div>
          </Modal.Body>
        </Modal>

        {this.props.sidebar ? (
          <div className="float-button" onClick={this.handleClickUp}>
            <div>
              <button className="btn btn-float">
                <FontAwesomeIcon icon={faChevronUp} />
              </button>
            </div>
          </div>
        ) : (
          <div className="float-button" onClick={this.handleClickUp}>
            <div>
              <button className="btn btn-float">
                <FontAwesomeIcon icon={faChevronUp} />
              </button>
            </div>
          </div>
        )}
        <NotificationContainer />
      </div>
    );
  }
}
