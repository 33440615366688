import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ReactDOM from "react-dom";
import axios from "axios";
import Menu from "../Menu/Menu";
import { Form } from "react-bootstrap";
import "./assets/index.scss";
import NumerFormat from "react-number-format";
import TopBarTitle from "../TopBars/TopBarTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFileExcel,
  faSearch,
  faPlus,
  faChartPie,
  faTimesCircle,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5.min";
import jszip from "jszip";

const $ = require("jquery");
$.Datatable = require("datatables.net-dt");
window.JSZip = jszip;

class ReportsOficios extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.api = window.$domain;
    this.data = {};
    this.state = {
      ppis: "",
      avatar: "",
      color_aleatorio: "",
      data: false,
      url: "",
      estimated_date_completion: "",
      second_estimated_date_completion: "",
      userRole: 0,
      redirect: false,
      status: null,
      dependencies: [],
      years: [],
      responsables: [],
      respon: 0,
      estatus: [],
      year: 0,
      Folio: "",
      proyectos_relevantes: 0,

      dependency: 0,
      datatable: [],
    };
  }

  clearFilters = () => {
    this.setState({
      estimated_date_completion: "",
      second_estimated_date_completion: "",
      year: 0,
      Folio: "",
      dependency: 0,
    });
  };

  componentDidMount() {
    //Aqui inicia la programación del datatable

    axios
      .get(`${this.api}/ppi/OficiosReports`)
      .then((response) => {
        let dependencies = [];
        let years = [];

        response.data.dependencies.map((dependency, idx) => {
          dependencies.push({
            value: dependency.pk_dependency,
            name: dependency.name,
          });
          return true;
        });

        response.data.years.map((year, idx) => {
          years.push({
            value: year.year,
          });
          return true;
        });

        this.setState({
          dependencies: dependencies,
          years: years,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    $("div.dataTables_filter input").addClass("mySeachClass");

    //axios.get(`${this.api}/users`)
    axios
      .get(`${this.api}/ppi/OficiosTable`)
      .then((response) => {
        this.setState({
          datatable: response.data,
        });

        this.createTable();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  onChangehandler = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
    console.log(`${name} updated:`, value);
    //this.onTrigger();
  };

  onChangehandlerProyectos = (event) => {
    const value = event.target.checked ? 1 : 0;
    // console.log("Proyectos relevantes:", value);
    this.setState({
      proyectos_relevantes: value,
    });
  };

  handleButtonClick() {
    console.log("respon", this);
    console.log("state", this.state);
    var serial = new URLSearchParams({
      dependencies: this.state.dependency,
      fecha_inicio: this.state.estimated_date_completion,
      fecha_fin: this.state.second_estimated_date_completion,
      folio: this.state.Folio,
      year: this.state.year,
      proyectos_relevantes: this.state.proyectos_relevantes,
    }).toString();
    axios
      .get(`${this.api}/ppi/OficiosTable?` + serial)
      .then((response) => {
        this.setState({
          datatable: response.data,
        });

        this.createTable();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createTable() {
    this.$el = $(this.el);

    let datatale = [];
    this.state.datatable.map((ppis) => {
      let tmp = [
        ppis.folio,
        ppis.fecha_clave_cartera,
        ppis.Nombre_proyecto,
        ppis.monto_inversion,
        ppis.financiamiento_estatal,
        ppis.financiamiento_federal,
        ppis.fondo_federal,
        ppis.Tipo_ppi,
        ppis.Dependencia_Solicitante,
        ppis.formato,
        ppis.clave_cartera,
        ppis.Numero_solicitud,
        ppis.Fecha_solicitud,
        // ppis.Nombre_documento_solicitud,
        ppis.Numero_CC,
        ppis.Fecha_CC,
        ppis.Trimestre,
        // ppis.Nombre_documento_CC,
        ppis.Numero_Pei,
        ppis.Fecha_Pei,
        // ppis.Nombre_documento_Pei,
        ppis.Observaciones,
        // ppis.Nombre_doc_PR,
        ppis.Responsable
      ];
      datatale.push(tmp);
      return true;
    });

    var table = this.$el.DataTable({
      bDestroy: true,
      data: datatale,
      columns: [
        { title: "Folio" },
        { title: "Fecha aprobación" },
        { title: "Nombre del proyecto" },
        { title: "Monto de inversión con (IVA)" },
        { title: "Financiamiento estatal" },
        { title: "Financiamiento federal" },
        { title: "Fondo federal" },
        { title: "Tipo de ppi" },
        { title: "Dependencia solicitante" },
        { title: "Formato de evaluación" },
        { title: "Clave cartera" },
        { title: "# Solicitud clave cartera" },
        { title: "Fecha solicitud clave cartera" },
 
        // { title: "Oficio solicitud clave cartera" },
        { title: "# Clave cartera" },
        { title: "Fecha clave cartera" },
        { title: "Trimestre" },
        // { title: "Oficio clave de cartera" },
        { title: "# Pei " },
        { title: "Fecha pei" },
        // { title: "Oficio Pei" },
        { title: "Observaciones" },
        // { title: "Evaluación socioeconomica firmada" },
        { title: "Responsable" }
      ],
      searching: false, // Aquí deshabilitas la opción de filtrar

      language: {
        lengthMenu: "Mostrando _MENU_ registros por página",
        info: "Mostrando página _PAGE_ de _PAGES_",
        infoEmpty: "Sin registros encontrados",
        infoFiltered: "(filtrados de _MAX_ registros totales)",
        zeroRecords: "No se encontraron registros",
        paginate: {
          first: "Primero",
          last: "Último",
          next: "Siguiente",
          previous: "Anterior",
        },
      },
      scrollX: true,
      columnDefs: [
        {
          targets: [0],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },

        {
          targets: [1],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [2],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [3],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [4],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div id={rowData.folio}>
                <NumerFormat
                  value={Number.parseFloat(cellData).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                  suffix={" MXN"}
                />
              </div>,
              td
            ),
        },
        {
          targets: [5],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div id={rowData.folio}>
                <NumerFormat
                  value={Number.parseFloat(cellData).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                  suffix={" MXN"}
                />
              </div>,
              td
            ),
        },
        {
          targets: [6],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div id={rowData.folio}>
                <NumerFormat
                  value={Number.parseFloat(cellData).toFixed(2)}
                  displayType={"text"}
                  thousandSeparator={true}
                  prefix={"$ "}
                  suffix={" MXN"}
                />
              </div>,
              td
            ),
        },
        {
          targets: [7],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [8],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [9],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [10],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        // {
        //   targets: [10],
        //   createdCell: (td, cellData, rowData) =>
        //     ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        // },
        {
          targets: [11],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [12],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        // {
        //   targets: [13],
        //   createdCell: (td, cellData, rowData) =>
        //     ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        // },
        {
          targets: [13],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [14],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [15],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [16],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        // {
        //   targets: [18],
        //   createdCell: (td, cellData, rowData) =>
        //     ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        // },
        {
          targets: [17],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        // {
        //   targets: [18],
        //   createdCell: (td, cellData, rowData) =>
        //     ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        // },
        {
          targets: [18],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
      ],
      dom: "Bfrtip", // Habilita la opción de botones

      buttons: [
        {
          extend: "excelHtml5",
          text: "Descargar excel",
          className: "btn-export-excel",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
    });

    table.on("search.dt", function () {
      localStorage.setItem("SeachFilter", table.search());
    });

    if (
      localStorage.getItem("SeachFilter") != null &&
      localStorage.getItem("SeachFilter") != ""
    )
      table.search(localStorage.getItem("SeachFilter")).draw();
  }

  render() {
    return (
      <div className="main-wrap Catalogs">
        <Menu />
        <div className="wrapper col col-10">
          <div className="data-content col-12">
            <TopBarTitle
              title={"PPI'S APROBADOS"}
              subtitle={""}
              catalog={"on"}
              path={"users"}
              security={true}
              back={"/projects"}
            />
            <div style={{
              background: "#ffff", // Cambiar el color de fondo
              height: "20rem",
            }}>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  background: "#ffff", // Cambiar el color de fondo
                  alignItems: "end",
                  // marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    width: "15%",
                  }}
                >
                  <label>Año</label>
                  <Form.Control
                    size="sm"
                    as="select"
                    name="year"
                    key={"year"}
                    onChange={this.onChangehandler}
                    value={this.state.year}
                  >
                    <option value="0">Seleccionar</option>
                    {this.state.years.map((type, idx) => (
                      <option name="year" value={type.value}>
                        {type.value}
                      </option>
                    ))}
                  </Form.Control>
                </div>

                <div
                  style={{
                    width: "15%",
                  }}
                >
                  <label>Folio</label>

                  <input
                    type="text"
                    className="form-control"
                    name="Folio"
                    value={this.state.Folio}
                    // data-idx={index}
                    placeholder="0"
                    onChange={this.onChangehandler}
                  // disabled={this.state.Oficio_solicitud[index].id !== 0 }
                  />
                </div>

                <div
                  style={{
                    width: "20%",

                    // justifyContent:"end",
                    // alignItems: "end", // Centrar verticalmente
                    // height:"4rem",
                  }}
                >
                  <button
                    style={{
                      alignItems: "center", // Centrar verticalmente
                      height: "2rem",
                      width: "55%",
                      // position: "relative", // Asegúrate de que la posición sea 'relative' para manejar 'top' y 'right'
                      // top: "20px", // Ajusta esta propiedad para la distancia desde la parte superior
                      // right: "-100px", // Ajusta esta propiedad para la distancia desde la derecha

                      background: "#28b463", // Cambiar el color de fondo

                      color: "#fff", // Cambiar el color del texto
                      border: "none", // Eliminar el borde
                      borderRadius: "5px", // Agregar bordes redondeados
                      cursor: "pointer", // Cambiar el cursor al pasar el mouse
                      fontSize: "16px", // Cambiar el tamaño del texto
                      //display: "flex", // Usar flexbox para alinear contenido
                    }}
                    onClick={() => this.handleButtonClick()} // Reemplaza esta función con la acción deseada
                  >
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="1x"
                      style={{ marginRight: "10px" }}
                    />
                    Filtrar
                  </button>
                </div>

                <div
                  style={{
                    width: "20%",

                    // justifyContent:"end",
                    // alignItems: "end", // Centrar verticalmente
                    // height:"4rem",
                  }}
                >
                  <button
                    style={{
                      // position: "relative",
                      // top: "20px",
                      // right: "-100px",
                      width: "55%",
                      height: "2rem",
                      background: "#D9534F",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "16px",

                      alignItems: "center",
                    }}
                    onClick={() => this.clearFilters()}
                  >
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size="1x"
                      style={{ marginRight: "10px" }}
                    />
                    Limpiar
                  </button>
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  background: "#ffff", // Cambiar el color de fondo
                  marginTop: "2rem",
                  alignItems: "end",
                }}
              >
                <div
                  style={{
                    width: "15%",
                  }}
                >
                  <label>Dependencia</label>
                  <Form.Control
                    size="sm"
                    as="select"
                    name="dependency"
                    key={"dependency"}
                    onChange={this.onChangehandler}
                    value={this.state.dependency}
                  >
                    <option value="0">Seleccionar</option>
                    {this.state.dependencies.map((type, idx) => (
                      <option name="dependency" value={type.value}>
                        {type.name}
                      </option>
                    ))}
                  </Form.Control>
                </div>
                <div
                  style={{
                    width: "15%",
                  }}
                >
                  <label>PPI´s relevantes</label>
                  <br></br>
                  <input
                    type="checkbox"
                    name="proyectos_relevantes"
                    key="proyectos_relevantes"
                    onChange={this.onChangehandlerProyectos}
                    checked={this.state.proyectos_relevantes === 1}
                  />
                </div>
                <div
                  style={{
                    width: "20%",
                  }}
                >
                  {" "}
                </div>{" "}
                <div
                  style={{
                    width: "20%",
                  }}
                >
                  {" "}
                </div>
              </div>
              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  background: "#ffff", // Cambiar el color de fondo
                  marginTop: "2rem",
                  // marginBottom:"4rem",
                  alignItems: "end",
                }}
              >
                <div
                  style={{
                    width: "15%",
                  }}
                >
                  <label>Fecha de inicio</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Escribe aquí"
                    name="estimated_date_completion"
                    value={this.state.estimated_date_completion}
                    onChange={this.onChangehandler}
                  />
                </div>
                <div
                  style={{
                    width: "15%",
                  }}
                >
                  <label>Fecha de fin</label>
                  <input
                    type="date"
                    className="form-control"
                    placeholder="Escribe aquí"
                    name="second_estimated_date_completion"
                    value={this.state.second_estimated_date_completion}
                    onChange={this.onChangehandler}
                  />
                </div>
                <div
                  style={{
                    width: "20%",
                  }}
                >
                  {" "}
                </div>{" "}
                <div
                  style={{
                    width: "20%",
                  }}
                >
                  {" "}
                </div>
              </div>
            </div>

            <br></br>

            { 
              <table
                style={{ width: "250px" }}
                className="display col-12"
                ref={(el) => (this.el = el)}
              ></table>
            }
          </div>
        </div>
      </div>
    );
  }
}

export default ReportsOficios;
