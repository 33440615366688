import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import axios from "axios";
import Menu from "../Menu/Menu";
import "./assets/index.scss";
import validator from "validator";
import { Form, Modal } from "react-bootstrap";
import TopBarTitle from "../TopBars/TopBarTitle";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faPaperclip,
  faPlusCircle,
  faTimesCircle,
  faDownload,
  faSave,
} from "@fortawesome/free-solid-svg-icons";

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
const $ = require("jquery");
$.Datatable = require("datatables.net-dt");

class OficiosInfo extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.api = window.$domain;
    this.environment = window.$environment;
    this.addOficiosolicitud = this.addOficiosolicitud.bind(this);
    this.addClave = this.addClave.bind(this);
    this.addPEI = this.addPEI.bind(this);
    this.addProyectos = this.addProyectos.bind(this);
    this.removeClave = this.removeClave.bind(this);
    this.removeRowOficiosolicitud = this.removeRowOficiosolicitud.bind(this);
    this.removePEI = this.removePEI.bind(this);
    this.removeProyectos = this.removeProyectos.bind(this);

    this.SoliInput = [];
    this.ClaveInput = [];
    this.InputPei = [];
    this.InputProyectos = [];

    this.data = {};

    this.state = {
      user: "",
      avatar: "",
      color_aleatorio: "",
      data: false,
      url: "",
      pin: "",
      userRole: 0,
      name: "",
      observaciones: "",
      profile: "",
      id: "",
      description: "",
      active: 0,
      pass: "",
      deshabilitar: true,
      textolabel: true,
      color: true,
      puesto: "",
      Oficio_solicitud: [
        { numero: "", Fecha: "", document: "", name: "", id: 0, es_sincronizacion_siga: "" },
      ],

      Oficio_clave: [{ numero: "", fecha: "", document: "", name: "", id: 0, es_sincronizacion_siga: "" }],

      Numero_PEI: [{ numero: "", fecha: "", document: "", name: "", id: 0, es_sincronizacion_siga: "" }],

      Proyectos_relevantes: [{ document: "", name: "", id: 0 }],

      //: false,
      help: false,
      catalogs: false,
      security: false,
      kardex: false,
      dependency: "",
      profiles: [],
      dependencies: [],
      estatuses: [],
      rowsOficio: [0],
      rowsClave: [0],
      rowsPEI: [0],
      rowsProyectos: [0],
      proyectosRelevantes: null,
      redirect: false,
      status: null,
      showModalError: false,
      showModalPIN: false,
      showModalPassword: false,
      datatable: [],
    };
  }

  async componentDidMount() {
    let id = this.props.match.params.id;
    //console.log("id",id)
    $("div.dataTables_filter input").addClass("mySeachClass");

    //Crear el axios para cargar la información de oficios

    try {
      const response = await axios.get(`${this.api}/ppi/info_oficios/${id}`);

      console.log("Response", response.data.proyectos_relevantes);

      let SolicitudCC = [];
      let oficios_cartera = [];
      let info_pei = [];
      let info_observaciones = [];

      let proyectos = [];


      if (response.data.SolicitudCC.length > 0) {
        response.data.SolicitudCC.map((soli) => {
          SolicitudCC.push({
            numero: soli.numero,
            Fecha: soli.Fecha,
            name: soli.document,
            id: soli.pk_soli,
            es_sincronizacion_siga: soli.es_sincronizacion_siga,
          });
          return true;
        });
        setTimeout(() => {
          // this.onTrigger();
        }, 200);
      }





      if (response.data.oficios_cartera.length > 0) {
        response.data.oficios_cartera.map((clave) => {
          oficios_cartera.push({
            numero: clave.numero,
            fecha: clave.fecha,
            name: clave.document,
            id: clave.pk_cartera_oficio,
            es_sincronizacion_siga: clave.es_sincronizacion_siga,
          });
          return true;
        });
        setTimeout(() => {
          // this.onTrigger();
        }, 200);
      }

      if (response.data.info_pei.length > 0) {
        response.data.info_pei.map((pei) => {
          info_pei.push({
            numero: pei.numero,
            fecha: pei.fecha,
            name: pei.document,
            id: pei.pk_infopei,
            es_sincronizacion_siga: pei.es_sincronizacion_siga,
          });
          return true;
        });
        setTimeout(() => {
          // this.onTrigger();
        }, 200);
      }

      if (response.data.info_observaciones.length > 0) {
        this.state.observaciones =
          response.data.info_observaciones[0].observaciones;
      }

      if (response.data.proyectos.length > 0) {
        response.data.proyectos.map((pr) => {
          proyectos.push({
            name: pr.document,
            id: pr.pk_proyectos_rel,
          });
          return true;
        });
        setTimeout(() => {
          // this.onTrigger();
        }, 200);
      }


      setTimeout(() => {
        this.setState({
          Oficio_solicitud: SolicitudCC,
          Oficio_clave: oficios_cartera,
          Numero_PEI: info_pei,
          Proyectos_relevantes: proyectos,

          info_observaciones: info_observaciones,
          proyectosRelevantes: response.data.proyectos_relevantes,
        });
      }, 100);
    } catch (error) {
      console.log(error);
    }

    const { proyectosRelevantes } = this.state;

    let filter = JSON.parse(localStorage.getItem("filter"));

    //   if (this.state.Oficio_solicitud.length === 0) {

    //     this.addOficiosolicitud();
    // }
  }

  SaveInfoOficio = () => {
    let id = this.props.match.params.id;

    let data = {
      SolicitudCC: this.state.Oficio_solicitud.map((oficio) => ({
        numero: oficio.numero,
        Fecha: oficio.Fecha,
        pk_soli: oficio.id,
        document: oficio.name,
        es_sincronizacion_siga: oficio.es_sincronizacion_siga,
      })),

      oficios_cartera: this.state.Oficio_clave.map((clave) => ({
        numero: clave.numero,
        fecha: clave.fecha,
        pk_cartera_oficio: clave.id,
        document: clave.name,
        es_sincronizacion_siga: clave.es_sincronizacion_siga,
      })),
      info_pei: this.state.Numero_PEI.map((pei) => ({
        numero: pei.numero,
        fecha: pei.fecha,
        pk_infopei: pei.id,
        document: pei.name,
        es_sincronizacion_siga: pei.es_sincronizacion_siga,
      })),
      info_observaciones: this.state.observaciones,

      proyectos: this.state.Proyectos_relevantes.map((proyectos) => ({

        pk_proyectos_rel: proyectos.id,
        document: proyectos.name,
      }))
    };

    axios
      .post(`${this.api}/ppi/info_oficios/${id}`, data, {
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
      })
      .then((response) => {
        console.log("Data saved successfully", response);
        window.location.reload();

      })
      .catch((error) => {
        if (error.response) {
          console.error("Error response:", error.response.data);
        } else if (error.request) {
          console.error("No response:", error.request);
        } else {
          console.error("Error", error.message);
        }
      });
  };

  handleChangeOficioFileInput = (e) => {
    const nextState = { ...this.state };
    const value = e.target.files[0];
    const idx = e.target.dataset.idx;
    console.log("index", idx);

    if (!this.isValidPDFFileType(value)) {
      // Mostrar un mensaje de error aquí
      NotificationManager.error(
        "SOLO SE PERMITE PDF",
        "¡ERROR AL CARGAR ARCHIVO !"
      );
      setTimeout(() => {
        $(".loader").hide("slow", () => { });
      }, 1000);
      console.error(
        "Tipo de archivo no admitido. Por favor, seleccione un archivo PDF válido."
      );
      return;
    }

    $(".loader").show("fast", () => { });
    nextState.Oficio_solicitud[idx].document = value;

    if (value.name.length > 20) {
      nextState.Oficio_solicitud[idx].name = value.name.substr(0, 14) + "...";
    } else {
      nextState.Oficio_solicitud[idx].name = value.name;
    }

    this.setState(nextState);
    //this.onTrigger();

    const oficioData = new FormData();
    if (nextState.Oficio_solicitud[idx].document) {
      oficioData.append("oficio", nextState.Oficio_solicitud[idx].document);
      oficioData.append("pk_soli", this.state.Oficio_solicitud[idx].id);

      console.log(
        "this.state.Oficio_solicitud",
        this.state.Oficio_solicitud[idx].document
      );

      axios
        .post(
          `${this.api}/ppi/oficio_archivo/${this.props.match.params.id}`,
          oficioData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          NotificationManager.success(
            "Oficio guardado correctamente",
            "¡Archivo guardado!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => { });
          }, 1000);

          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          NotificationManager.error(
            "El oficio no se guardó correctamente",
            "¡Error al guardar el Oficio!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => { });
          }, 1000);
        });
    }
  };

  handleChangeClaveCartera = (e) => {
    const nextState = { ...this.state };
    const value = e.target.files[0];
    const idx = e.target.dataset.idx;
    console.log("index", idx);

    if (!this.isValidPDFFileType(value)) {
      // Mostrar un mensaje de error aquí
      NotificationManager.error(
        "SOLO SE PERMITE PDF",
        "¡ERROR AL CARGAR ARCHIVO !"
      );
      setTimeout(() => {
        $(".loader").hide("slow", () => { });
      }, 1000);
      console.error(
        "Tipo de archivo no admitido. Por favor, seleccione un archivo PDF válido."
      );
      return;
    }

    $(".loader").show("fast", () => { });
    nextState.Oficio_clave[idx].document = value;

    if (value.name.length > 20) {
      nextState.Oficio_clave[idx].name = value.name.substr(0, 14) + "...";
    } else {
      nextState.Oficio_clave[idx].name = value.name;
    }

    this.setState(nextState);
    //this.onTrigger();

    const oficioData = new FormData();
    if (nextState.Oficio_clave[idx].document) {
      oficioData.append("oficio", nextState.Oficio_clave[idx].document);
      oficioData.append("pk_cartera_oficio", this.state.Oficio_clave[idx].id);

      console.log("this.state.Oficio_clave", this.state.Oficio_clave);

      axios
        .post(
          `${this.api}/ppi/oficio_cartera/${this.props.match.params.id}`,
          oficioData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          NotificationManager.success(
            "Oficio guardado correctamente",
            "¡Archivo guardado!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => { });
          }, 1000);

          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          NotificationManager.error(
            "El oficio no se guardó correctamente",
            "¡Error al guardar el Oficio!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => { });
          }, 1000);
        });
    }
  };

  handleChangePei = (e) => {
    const nextState = { ...this.state };
    const value = e.target.files[0];
    const idx = e.target.dataset.idx;
    console.log("index", idx);

    if (!this.isValidPDFFileType(value)) {
      // Mostrar un mensaje de error aquí
      NotificationManager.error(
        "SOLO SE PERMITE PDF",
        "¡ERROR AL CARGAR ARCHIVO !"
      );
      setTimeout(() => {
        $(".loader").hide("slow", () => { });
      }, 1000);
      console.error(
        "Tipo de archivo no admitido. Por favor, seleccione un archivo PDF válido."
      );
      return;
    }

    $(".loader").show("fast", () => { });
    nextState.Numero_PEI[idx].document = value;

    if (value.name.length > 20) {
      nextState.Numero_PEI[idx].name = value.name.substr(0, 14) + "...";
    } else {
      nextState.Numero_PEI[idx].name = value.name;
    }

    this.setState(nextState);
    //this.onTrigger();

    const oficioData = new FormData();
    if (nextState.Numero_PEI[idx].document) {
      oficioData.append("oficio", nextState.Numero_PEI[idx].document);
      oficioData.append("pk_infopei", this.state.Numero_PEI[idx].id);

      console.log("this.state.Numero_PEI", this.state.Numero_PEI);

      axios
        .post(
          `${this.api}/ppi/oficio_pei/${this.props.match.params.id}`,
          oficioData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          NotificationManager.success(
            "Oficio guardado correctamente",
            "¡Archivo guardado!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => { });
          }, 1000);

          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          NotificationManager.error(
            "El oficio no se guardó correctamente",
            "¡Error al guardar el Oficio!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => { });
          }, 1000);
        });
    }
  };

  handleChangeProyectos = (e) => {
    const nextState = { ...this.state };
    const value = e.target.files[0];
    const idx = e.target.dataset.idx;
    console.log("index", idx);

    if (!this.isValidPDFFileType(value)) {
      // Mostrar un mensaje de error aquí
      NotificationManager.error(
        "SOLO SE PERMITE PDF",
        "¡ERROR AL CARGAR ARCHIVO !"
      );
      setTimeout(() => {
        $(".loader").hide("slow", () => { });
      }, 1000);
      console.error(
        "Tipo de archivo no admitido. Por favor, seleccione un archivo PDF válido."
      );
      return;
    }

    $(".loader").show("fast", () => { });
    nextState.Proyectos_relevantes[idx].document = value;

    if (value.name.length > 20) {
      nextState.Proyectos_relevantes[idx].name = value.name.substr(0, 14) + "...";
    } else {
      nextState.Proyectos_relevantes[idx].name = value.name;
    }

    this.setState(nextState);
    //this.onTrigger();

    const oficioData = new FormData();
    if (nextState.Proyectos_relevantes[idx].document) {
      oficioData.append("oficio", nextState.Proyectos_relevantes[idx].document);
      oficioData.append("pk_proyectos_rel", this.state.Proyectos_relevantes[idx].id);

      console.log("this.state.Proyectos_relevantes", this.state.Proyectos_relevantes);

      axios
        .post(
          `${this.api}/ppi/proyectos_relevantes/${this.props.match.params.id}`,
          oficioData,
          {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          }
        )
        .then((response) => {
          NotificationManager.success(
            "Oficio guardado correctamente",
            "¡Archivo guardado!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => { });
          }, 1000);

          window.location.reload();
        })
        .catch((error) => {
          console.error(error);
          NotificationManager.error(
            "El oficio no se guardó correctamente",
            "¡Error al guardar el Oficio!"
          );
          setTimeout(() => {
            $(".loader").hide("slow", () => { });
          }, 1000);
        });
    }
  };



  isValidPDFFileType = (file) => {
    const allowedTypes = [".pdf"];
    const fileType = file.type || "";
    const fileName = file.name || "";

    return allowedTypes.some(
      (type) => fileType.toLowerCase().includes(type) || fileName.toLowerCase().endsWith(type)
    );
  };

  addOficiosolicitud() {
    var nextState = this.state;
    let newOficio = { numero: "", Fecha: "", document: "", id: 0 };
    nextState.Oficio_solicitud.push(newOficio);
    this.setState(nextState);
  }

  removeRowOficiosolicitud(index) {
    this.setState(
      (prevState) => {
        const updatedRowsOficio = prevState.rowsOficio.filter(
          (row) => row !== index
        );
        const updatedComponent = prevState.Oficio_solicitud.filter(
          (component) => component !== index
        );

        return {
          rowsOficio: updatedRowsOficio,
          Oficio_solicitud: updatedComponent,
        };
      },
      () => {
        // this.onTrigger();
      }
    );
  }

  addClave() {
    var nextState = this.state;
    let newClave = { numero: "", fecha: "", document: "", id: 0 };
    nextState.Oficio_clave.push(newClave);
    this.setState(nextState);
  }

  removeClave(index) {
    this.setState(
      (prevState) => {
        const updatedRowsClave = prevState.rowsClave.filter(
          (row) => row !== index
        );
        const updatedComponent = prevState.Oficio_clave.filter(
          (component) => component !== index
        );

        return {
          rowsClave: updatedRowsClave,
          Oficio_clave: updatedComponent,
        };
      },
      () => {
        // this.onTrigger();
      }
    );
  }

  addPEI() {
    var nextState = this.state;
    let newPEI = { numero: "", fecha: "", document: "", name: "", id: 0 };
    nextState.Numero_PEI.push(newPEI);
    this.setState(nextState);
  }

  removePEI(index) {
    this.setState(
      (prevState) => {
        const updatedRowsPEI = prevState.rowsPEI.filter((row) => row !== index);
        const updatedComponent = prevState.Numero_PEI.filter(
          (component) => component !== index
        );

        return {
          rowsPEI: updatedRowsPEI,
          Numero_PEI: updatedComponent,
        };
      },
      () => {
        // this.onTrigger();
      }
    );
  }

  addProyectos() {
    var nextState = this.state;
    let newproyectos = { document: "", name: "", id: 0 };
    nextState.Proyectos_relevantes.push(newproyectos);
    this.setState(nextState);
  }

  removeProyectos(index) {
    this.setState(
      (prevState) => {
        const updatedRowsProyectos = prevState.rowsProyectos.filter((row) => row !== index);
        const updatedComponent = prevState.Proyectos_relevantes.filter(
          (component) => component !== index
        );

        return {
          rowsProyectos: updatedRowsProyectos,
          Proyectos_relevantes: updatedComponent,
        };
      },
      () => {
        // this.onTrigger();
      }
    );
  }

  handleInputSoliClick = (e) => {
    const idx = e.target.dataset.idx;
    this.SoliInput[idx].click();
  };

  handleInputClaveClick = (e) => {
    const idx = e.target.dataset.idx;
    this.ClaveInput[idx].click();
  };

  handleInputPeiClick = (e) => {
    const idx = e.target.dataset.idx;
    this.InputPei[idx].click();
  };

  handleInputProyectosClick = (e) => {
    const idx = e.target.dataset.idx;
    this.InputProyectos[idx].click();
  };


  onChangehandler = (e) => {
    const idx = e.target.dataset.idx;
    const property = e.target.name;
    const value = e.target.value;
    console.log("valor", value);

    this.setState(
      (prevState) => {
        const updatedOficio = [...prevState.Oficio_solicitud];
        const updatedItem = { ...updatedOficio[idx], [property]: value };
        updatedOficio[idx] = updatedItem;

        return {
          Oficio_solicitud: updatedOficio,
        };
      },
      () => { }
    );
  };

  onChangehandler_CC = (e) => {
    const idx = e.target.dataset.idx;
    const property = e.target.name;
    const value = e.target.value;

    this.setState(
      (prevState) => {
        const updatedCC = [...prevState.Oficio_clave];
        const updatedItem = { ...updatedCC[idx], [property]: value };
        updatedCC[idx] = updatedItem;

        return {
          Oficio_clave: updatedCC,
        };
      },
      () => { }
    );
  };

  onChangehandler_PEI = (e) => {
    const idx = e.target.dataset.idx;
    const property = e.target.name;
    const value = e.target.value;

    this.setState(
      (prevState) => {
        const updatedPei = [...prevState.Numero_PEI];
        const updatedItem = { ...updatedPei[idx], [property]: value };
        updatedPei[idx] = updatedItem;

        return {
          Numero_PEI: updatedPei,
        };
      },
      () => { }
    );
  };

  onChangehandler_observations = (e) => {
    let name = e.target.name;
    let value = e.target.value;
    let data = {};
    data[name] = value;
    this.setState(data);
    //this.onTrigger();
  };

  /* EVENTO PARA CERRAR MODALS
   */

  componentWillUnmount() {
    this._isMounted = false;
  }

  render() {
    console.log(
      "Valor de proyectosRelevantes:",
      this.state.proyectosRelevantes
    );

    if (this.state.redirect) {
      return <Redirect to={this.state.url} />;
    }
    return (
      <div className="main-wrap Catalogs">
        <Menu />
        <div className="wrapper col col-10">
          <div className="data-content">
            <TopBarTitle
              title={"Información de oficios"}
              catalog={"on"}
              path={"users"}
              security={true}
              // button={"Descargar excel"}
              back={"/projects"}
            />
            <div className="col-7 offset-3 wrap-dependency">
              <div className="row">
                <div className="col-12" style={{ textAlign: "center" }}>
                  <h3>Información de oficios</h3>
                </div>
              </div>
              <br></br>

              <div className="row">
                <div className="col-12" style={{ textAlign: "left" }}>
                  <h5>Solicitud de clave de cartera</h5>
                </div>
              </div>
              <div className="col-12 text-right">
                <button
                  className="btn btn-link"
                  onClick={this.addOficiosolicitud}
                >
                  <FontAwesomeIcon icon={faPlusCircle} size={"1x"} />
                </button>
              </div>
              {this.state.Oficio_solicitud.map((row, index) => (
                <div className="row" key={index}>
                  {console.log("id", this.state.Oficio_solicitud[index].id)}

                  {console.log(
                    "NUMERO",
                    this.state.Oficio_solicitud[index].numero
                  )}
                  {console.log(
                    "FECHA",
                    this.state.Oficio_solicitud[index].Fecha
                  )}
                  {console.log(
                    "DOCUMENT",
                    this.state.Oficio_solicitud[index].name
                  )}

                  {console.log(
                    "es_sincronizacion_siga",
                    this.state.Oficio_solicitud[index].es_sincronizacion_siga
                  )}

                  <div className="col-12 col-lg-3">
                    <label className="col-lg-12">Número</label>

                    <div className="col-lg-12">
                      <input
                        type="text"
                        className="form-control"
                        name="numero"
                        value={this.state.Oficio_solicitud[index].numero}
                        data-idx={index}
                        placeholder="0"
                        onChange={this.onChangehandler}
                        disabled={this.state.Oficio_solicitud[index].id !== 0}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <label className="col-lg-12">Fecha</label>
                    <div className="col-lg-12">
                      <input
                        type="date"
                        className="form-control"
                        name="Fecha"
                        value={this.state.Oficio_solicitud[index].Fecha}
                        data-idx={index}
                        onChange={this.onChangehandler}
                        disabled={this.state.Oficio_solicitud[index].id !== 0}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-1">
                    <label className="col-lg-12"></label>

                    {!this.state.Oficio_solicitud[index].id && (
                      <button
                        className="btn btn-success mx-2"
                        onClick={this.SaveInfoOficio}
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </button>
                    )}
                  </div>
                  <div className="col-12 col-lg-4">
                    <label className="col-lg-12">Oficio</label>
                    {!this.state.Oficio_solicitud[index].name && (
                      <div className="col-lg-12">
                        <Form.File
                          accept=".pdf, application/pdf,application/msword"
                          enctype="multipart/form-data"
                          id={"custom-file"}
                          data-idx={index}
                          ref={(input) => (this.SoliInput[index] = input)}
                          onChange={this.handleChangeOficioFileInput}
                        />
                        <button
                          className="col-12 btn btn-primary"
                          value={this.state.Oficio_solicitud[index].document}
                          data-idx={index}
                          onClick={this.handleInputSoliClick}
                        >
                          <FontAwesomeIcon icon={faPaperclip} /> Agregar
                        </button>
                      </div>
                    )}

                    <div
                      className="col-12 text-collapsed file-name"
                      style={{
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        overflow: "visible"
                      }}
                    >
                      <span>
                        {this.state.Oficio_solicitud[index].es_sincronizacion_siga == 1
                          ? this.state.Oficio_solicitud[index].name.substring(
                            this.state.Oficio_solicitud[index].name.lastIndexOf('/') + 1)
                          : this.state.Oficio_solicitud[index]?.name?.split("_").pop()
                        }
                      </span>
                    </div>

                    <div
                      className="col-1 file-download"
                      style={{ textAlign: "center" }}
                    >
                      {this.state.Oficio_solicitud[index].name && (
                        <a
                          href={
                            this.state.Oficio_solicitud[index].es_sincronizacion_siga == 1
                              ? this.state.Oficio_solicitud[index].name
                              : `https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.Oficio_solicitud[index].name}`
                          }
                          download
                        >
                          {this.state.Oficio_solicitud[index].es_sincronizacion_siga == 1
                            ? <FontAwesomeIcon icon={faDownload} />
                            : <FontAwesomeIcon icon={faDownload} />}
                        </a>
                      )}
                    </div>
                  </div>

                  <div className="col-12 text-right">
                    <button
                      className="btn btn-link delete-row text-danger"
                      onClick={() => this.removeRowOficiosolicitud(row)}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} size={"1x"} />
                    </button>
                  </div>
                </div>
              ))}

              <div className="row">
                <div className="col-12" style={{ textAlign: "left" }}>
                  <h5>Clave de cartera</h5>
                </div>
              </div>

              <div className="col-12 text-right">
                <button className="btn btn-link" onClick={this.addClave}>
                  <FontAwesomeIcon icon={faPlusCircle} size={"1x"} />
                </button>
              </div>

              {this.state.Oficio_clave.map((row, index) => (
                <div className="row" key={index}>
                  {console.log("id cc", this.state.Oficio_clave[index].id)
                  }
                  {console.log(
                    "DOCUMENT",
                    this.state.Oficio_clave[index].name
                  )}
                  <div className="col-12 col-lg-3">
                    <label className="col-lg-12">Número</label>
                    <div className="col-lg-12">
                      <input
                        type="text"
                        className="form-control"
                        name="numero"
                        value={this.state.Oficio_clave[index].numero}
                        placeholder="0"
                        onChange={this.onChangehandler_CC}
                        data-idx={index}
                        disabled={this.state.Oficio_clave[index].id !== 0}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <label className="col-lg-12">Fecha</label>
                    <div className="col-lg-12">
                      <input
                        type="date"
                        className="form-control"
                        name="fecha"
                        value={this.state.Oficio_clave[index].fecha}
                        placeholder="Correo"
                        onChange={this.onChangehandler_CC}
                        data-idx={index}
                        disabled={this.state.Oficio_clave[index].id !== 0}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-1">
                    <label className="col-lg-12"></label>

                    {!this.state.Oficio_clave[index].id && (
                      <button
                        className="btn btn-success mx-2"
                        onClick={this.SaveInfoOficio}
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </button>
                    )}
                  </div>

                  <div className="col-12 col-lg-4">
                    <label className="col-lg-12">Oficio</label>
                    {!this.state.Oficio_clave[index].name && (
                      <div className="col-lg-12">
                        <Form.File
                          accept=".pdf, application/pdf,application/msword"
                          enctype="multipart/form-data"
                          id={"custom-file"}
                          data-idx={index}
                          ref={(input) => (this.ClaveInput[index] = input)}
                          onChange={this.handleChangeClaveCartera}
                        />
                        <button
                          className="col-12 btn btn-primary"
                          value={this.state.Oficio_clave[index].document}
                          data-idx={index}
                          onClick={this.handleInputClaveClick}
                        >
                          <FontAwesomeIcon icon={faPaperclip} /> Agregar
                        </button>
                      </div>
                    )}

                    <div className="col-lg-12">
                      <div
                        className="col-8 text-collapsed file-name"
                        style={{
                          textAlign: "left",
                          whiteSpace: "nowrap",
                          overflow: "visible"
                        }}
                      >
                        <span>
                          {/* {this.state.Oficio_clave[index]?.name
                            ?.split("_")
                            .pop()
                            
                            } */}

                          {this.state.Oficio_clave[index].es_sincronizacion_siga == 1
                            ? this.state.Oficio_clave[index].name.substring(
                              this.state.Oficio_clave[index].name.lastIndexOf('/') + 1)
                            : this.state.Oficio_clave[index]?.name?.split("_").pop()
                          }
                        </span>
                      </div>
                      <div
                        className="col-1 file-download"
                        style={{ textAlign: "center" }}
                      >
                        {this.state.Oficio_clave[index].name && (

                          <a
                            href={
                              this.state.Oficio_clave[index].es_sincronizacion_siga == 1
                                ? this.state.Oficio_clave[index].name
                                : `https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.Oficio_clave[index].name}`
                            }
                            download
                          >
                            {this.state.Oficio_clave[index].es_sincronizacion_siga == 1
                              ? <FontAwesomeIcon icon={faDownload} />
                              : <FontAwesomeIcon icon={faDownload} />}
                          </a>
                          // <a
                          //   href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.Oficio_clave[index].name}`}
                          //   download
                          // >
                          //   <FontAwesomeIcon icon={faDownload} />
                          // </a>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-right">
                    <button
                      className="btn btn-link delete-row text-danger"
                      onClick={() => this.removeClave(row)}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} size={"1x"} />
                    </button>
                  </div>
                </div>
              ))}

              <div className="row">
                <div className="col-12" style={{ textAlign: "left" }}>
                  <h5>PEI</h5>
                </div>
              </div>

              <div className="col-12 text-right">
                <button className="btn btn-link" onClick={this.addPEI}>
                  <FontAwesomeIcon icon={faPlusCircle} size={"1x"} />
                </button>
              </div>

              {this.state.Numero_PEI.map((row, index) => (
                <div className="row" key={index}>
                  <div className="col-12 col-lg-3">
                    <label className="col-lg-12">Número</label>
                    <div className="col-lg-12">
                      <input
                        type="text"
                        className="form-control"
                        name="numero"
                        value={this.state.Numero_PEI[index].numero}
                        placeholder="0"
                        data-idx={index}
                        onChange={this.onChangehandler_PEI}
                        disabled={this.state.Numero_PEI[index].id !== 0}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-3">
                    <label className="col-lg-12">Fecha</label>
                    <div className="col-lg-12">
                      <input
                        type="date"
                        className="form-control"
                        name="fecha"
                        data-idx={index}
                        value={this.state.Numero_PEI[index].fecha}
                        onChange={this.onChangehandler_PEI}
                        disabled={this.state.Numero_PEI[index].id !== 0}
                      />
                    </div>
                  </div>
                  <div className="col-12 col-lg-1">
                    <label className="col-lg-12"></label>

                    {!this.state.Numero_PEI[index].id && (
                      <button
                        className="btn btn-success mx-2"
                        onClick={this.SaveInfoOficio}
                      >
                        <FontAwesomeIcon icon={faSave} />
                      </button>
                    )}
                  </div>

                  <div className="col-12 col-lg-4">
                    <label className="col-lg-12">Oficio</label>
                    {!this.state.Numero_PEI[index].name && (
                      <div className="col-lg-12">
                        <Form.File
                          accept=".pdf, application/pdf,application/msword"
                          enctype="multipart/form-data"
                          id={"custom-file"}
                          data-idx={index}
                          ref={(input) => (this.InputPei[index] = input)}
                          onChange={this.handleChangePei}
                        />

                        <button
                          className="col-12 btn btn-primary"
                          value={this.state.Numero_PEI[index].document}
                          data-idx={index}
                          onClick={this.handleInputPeiClick}
                        >
                          <FontAwesomeIcon icon={faPaperclip} /> Agregar
                        </button>
                      </div>
                    )}

                    <div
                      className="col-8 text-collapsed file-name"
                      style={{
                        textAlign: "left",
                        whiteSpace: "nowrap",
                        overflow: "visible"
                      }}
                    >
                      <span>
                        {/* {this.state.Numero_PEI[index]?.name?.split("_").pop()} */}

                        {this.state.Numero_PEI[index].es_sincronizacion_siga == 1
                          ? this.state.Numero_PEI[index].name.substring(
                            this.state.Numero_PEI[index].name.lastIndexOf('/') + 1)
                          : this.state.Numero_PEI[index]?.name?.split("_").pop()
                        }

                        
                      </span>
                    </div>
                    <div
                      className="col-1 file-download"
                      style={{ textAlign: "center" }}
                    >
                      {this.state.Numero_PEI[index].name && (

                        <a
                          href={
                            this.state.Numero_PEI[index].es_sincronizacion_siga == 1
                              ? this.state.Numero_PEI[index].name
                              : `https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.Numero_PEI[index].name}`
                          }
                          download
                        >
                          {this.state.Numero_PEI[index].es_sincronizacion_siga == 1
                            ? <FontAwesomeIcon icon={faDownload} />
                            : <FontAwesomeIcon icon={faDownload} />}
                        </a>


                        // <a
                        //   href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.Numero_PEI[index].name}`}
                        //   download
                        // >
                        //   <FontAwesomeIcon icon={faDownload} />
                        // </a>
                      )}
                    </div>
                  </div>
                  <div className="col-12 text-right">
                    <button
                      className="btn btn-link delete-row text-danger"
                      onClick={() => this.removePEI(row)}
                    >
                      <FontAwesomeIcon icon={faTimesCircle} size={"1x"} />
                    </button>
                  </div>
                </div>
              ))}
              <br></br>



              {this.state.proyectosRelevantes === 1 && (
                <div className="row">

                  <div className="col-12" style={{ textAlign: "left" }}>
                    <h5>Evaluación socioeconomica firmada</h5>
                  </div>



                  <div className="col-12">
                    <div className="col-12 text-right">
                      <button className="btn btn-link" onClick={this.addProyectos}>
                        <FontAwesomeIcon icon={faPlusCircle} size={"1x"} />
                      </button>
                    </div>
                  </div>

                  {this.state.Proyectos_relevantes.map((row, index) => (

                    <div className="row" key={index}>


                      <div className="col-12 ">

                        {!this.state.Proyectos_relevantes[index].name && (

                          <div className="col-lg-12">
                            <Form.File
                              accept=".pdf, application/pdf,application/msword"
                              enctype="multipart/form-data"
                              id={"custom-file"}
                              data-idx={index}
                              ref={(input) => (this.InputProyectos[index] = input)}
                              onChange={this.handleChangeProyectos}
                            />

                            <button
                              className="col-12 btn btn-primary"
                              value={this.state.Proyectos_relevantes[index].document}
                              data-idx={index}
                              onClick={this.handleInputProyectosClick}
                            >
                              <FontAwesomeIcon icon={faPaperclip} /> Agregar
                            </button>
                          </div>
                        )}

                        <div
                          className="col-8 text-collapsed file-name"
                          style={{
                            textAlign: "left",
                            whiteSpace: "nowrap",
                            overflow: "visible"
                          }}
                        >
                          <span>
                            {this.state.Proyectos_relevantes[index]?.name?.split("_").pop()}
                          </span>
                        </div>
                        <div
                          className="col-1 file-download"
                          style={{ textAlign: "center" }}
                        >
                          {this.state.Proyectos_relevantes[index].name && (
                            <a
                              href={`https://sepirepo.s3.us-west-2.amazonaws.com/${this.environment}/${this.state.Proyectos_relevantes[index].name}`}
                              download
                            >
                              <FontAwesomeIcon icon={faDownload} />
                            </a>
                          )}
                        </div>
                      </div>
                      <div className="col-12 text-right">
                        <button
                          className="btn btn-link delete-row text-danger"
                          onClick={() => this.removeProyectos(row)}
                        >
                          <FontAwesomeIcon icon={faTimesCircle} size={"1x"} />
                        </button>
                      </div>
                    </div>
                  ))}


                </div>
              )}



              <div className="row">
                <div className="col-lg-12">
                  <div className="col-lg-12">
                    <br></br>
                    <label className="col-lg-12">Observaciones</label>

                    <div className="row">
                      <div className="col-12">
                        {
                          <textarea
                            className="col-12"
                            placeholder="Escribe aquí"
                            name="observaciones"
                            style={{ height: "85px" }}
                            onChange={this.onChangehandler_observations}
                            value={this.state.observaciones}
                          ></textarea>
                        }
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="row justify-content-center">
                <div className="col-12 d-flex justify-content-center">
                  <button
                    className="btn btn-primary mx-2"
                    onClick={this.SaveInfoOficio}
                  >
                    Guardar
                  </button>
                  <button className="btn btn-danger mx-2" onClick={null}>
                    Cancelar
                  </button>
                </div>
              </div>

              <div className="row">
                <div className="col-12 col-lg-6">
                  <div className="col-lg-12"></div>
                </div>

                <div className="col-12 col-lg-6">
                  <label className="col-lg-12 col-lg-6"></label>
                  <div className="col-lg-12"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <NotificationContainer />
      </div>
    );
  }
}
export default OficiosInfo;
