import React, { Component } from "react";
import { Redirect } from "react-router-dom";
import ReactDOM from "react-dom";
import axios from "axios";
import Menu from "../Menu/Menu";
import { Form,Modal } from "react-bootstrap";
import "./assets/index.scss";
import NumerFormat from "react-number-format";
import TopBarTitle from "../TopBars/TopBarTitle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowLeft,
  faFileExcel,
  faSearch,
  faPaperclip,
  faCheckCircle,
  faTimesCircle,
  faDownload,
  faChevronUp,
  faExclamationTriangle,
  faCheck,
  faUndo,
  faFileAlt,
  faTrashAlt,
  faPlus,
  faChartPie,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons";
import "react-notifications/lib/notifications.css";
import "datatables.net-buttons-dt/css/buttons.dataTables.css";
import "datatables.net";
import "datatables.net-buttons";
import "datatables.net-buttons/js/buttons.html5.min";
import jszip from "jszip";

const $ = require("jquery");
$.Datatable = require("datatables.net-dt");
window.JSZip = jszip;

class RegistroPorcentaje extends Component {
  _isMounted = false;
  constructor(props) {
    super(props);
    this.api = window.$domain;
    this.data = {};
    this.state = {
      ppis: "",
      avatar: "",
      color_aleatorio: "",
      messageModal: "",
      titleModal: "",
      showModal: false,
      data: false,
      url: "",
      yearsR: [],
      year: 0,
      userRole: 0,
      dataFiltered: false, // Nuevo estado para controlar la visibilidad del DataTable
      redirect: false,
      status: null,
      datatable: [],
    };
  }



  clearFilters = () => {
    this.setState({
      year: 0,
      // datatable: [],
      // dataFiltered: false, // Ocultar la tabla cuando se limpia el filtro
    });
  };

  handleClose = () => {
    this.setState({
      showModal: false,
    });
  };

  componentDidMount() {
    //Aqui inicia la programación del datatable

    axios
      .get(`${this.api}/ppi/registrosPor`)
      .then((response) => {

        let yearsR = [];


        response.data.yearsR.map((year, idx) => {
          yearsR.push({
            value: year.year,
          });
          return true;
        });

        this.setState({

          yearsR: yearsR,
        });
      })
      .catch((error) => {
        console.log(error);
      });

    $("div.dataTables_filter input").addClass("mySeachClass");

    axios
      .get(`${this.api}/ppi/registrosView`)
      .then((response) => {
        this.setState({
          datatable: response.data,
        });

        this.createTable();
      })
      .catch((error) => {
        console.log(error);
      });




    //axios.get(`${this.api}/users`)

  }

  // onChangehandler = (e) => {
  //   let name = e.target.name;
  //   let value = e.target.value;
  //   let data = {};
  //   data[name] = value;
  //   this.setState(data);
    
  //   console.log(`${name} updated:`, value);
  //   //this.onTrigger();
  // };

  onChangehandler = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  handleButtonClick() {
    console.log("respon", this);
    console.log("state", this.state);
    
    if (this.state.year === 0) {
      // alert("Por favor, selecciona un año antes de filtrar.");
      this.setState({
        showModal: true,
        icon: "warning",
        titleModal: "¡Atención!",
        messageModal: "Para visualizar los registros, primero seleccione un año en el filtro superior.",
      });
      return;
    }

    var serial = new URLSearchParams({
      year: this.state.year,
    }).toString();
    axios
      .get(`${this.api}/ppi/registrosView?` + serial)
      .then((response) => {
        this.setState({  
          datatable: response.data,
          dataFiltered: true, // Mostrar la tabla solo después de filtrar
        });

        this.createTable();
      })
      .catch((error) => {
        console.log(error);
      });
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  createTable() {
    this.$el = $(this.el);

    let datatale = [];
    this.state.datatable.map((ppis) => {
      let tmp = [
        ppis.anio,
        ppis.total_registros,
        ppis.registros_pendientes,
        ppis.registros_atendidos,
        ppis.porcentaje_atencion,
        //  ppis.pk_ppis_pendientes,
      ];
      datatale.push(tmp);
      return true;
    });

    var table = this.$el.DataTable({
      bDestroy: true,
      data: datatale,
      columns: [
        { title: "Año de busqueda de registros(PPI'S)" },
        { title: "Registros totales (PPI'S)" },
        { title: "Registros pendientes (PPI'S)" },
        { title: "Registros atendidos (PPI'S)" },
        { title: "Porcentaje de registros (PPI'S)" },
        // { title: "Nombre del registro pendientes(pre-solicitud)    " },
     

      ],
      searching: false, // Aquí deshabilitas la opción de filtrar

      language: {
        lengthMenu: "Mostrando _MENU_ registros por página",
        info: "Mostrando página _PAGE_ de _PAGES_",
        infoEmpty: "Sin registros encontrados",
        infoFiltered: "(filtrados de _MAX_ registros totales)",
        zeroRecords: "No se encontraron registros",
        paginate: {
          first: "Primero",
          last: "Último",
          next: "Siguiente",
          previous: "Anterior",
        },
      },
      scrollX: true,
      columnDefs: [
        {
          targets: [0],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },

        {
          targets: [1],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [2],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>{cellData}</div>, td),
        },
        {
          targets: [3],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(<div id={rowData.folio}>
              {cellData}</div>, td),
        },
        {
          targets: [4],
          createdCell: (td, cellData, rowData) =>
            ReactDOM.render(
              <div id={rowData.folio}>
                <NumerFormat
                  value={Number.parseFloat(cellData)}
                  displayType={"text"}
                  suffix={"%"} // Coloca el % al final en lugar de al inicio
                />
              </div>,
              td
            ),
        },
        
      ],
      dom: "Bfrtip", // Habilita la opción de botones

      buttons: [
        {
          extend: "excelHtml5",
          text: "Descargar excel",
          className: "btn-export-excel",
          exportOptions: {
            columns: ":visible",
          },
        },
      ],
    });

    table.on("search.dt", function () {
      localStorage.setItem("SeachFilter", table.search());
    });

    if (
      localStorage.getItem("SeachFilter") != null &&
      localStorage.getItem("SeachFilter") != ""
    )
      table.search(localStorage.getItem("SeachFilter")).draw();
  }

  render() {
    return (
      <div className="main-wrap Catalogs">
        <Menu />
        <div className="wrapper col col-10">

          <div
            className="data-content col-12"
          >
            <TopBarTitle
              title={"Registros totales"}
              subtitle={""}
              catalog={"on"}
              path={"users"}
              security={true}
              back={"/projects"}
            />
            <div style={{
              background: "#ffff", // Cambiar el color de fondo
              height: "10rem",
            }}>

              <div
                style={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "space-evenly",
                  background: "#ffff", // Cambiar el color de fondo
                  alignItems: "end",
                  // marginBottom: "20px",
                }}
              >
                <div
                  style={{
                    width: "15%",
                  }}
                >
                  <label>Año</label>
                  <Form.Control
                    size="sm"
                    as="select"
                    name="year"
                    key={"year"}
                    onChange={this.onChangehandler}
                    value={this.state.year}
                  >
                    <option value="0">Seleccionar</option>
                    {this.state.yearsR.map((type, idx) => (
                      <option name="year" value={type.value}>
                        {type.value}
                      </option>
                    ))}
                  </Form.Control>
                </div>



                <div
                  style={{
                    width: "20%",

                    // justifyContent:"end",
                    // alignItems: "end", // Centrar verticalmente
                    // height:"4rem",
                  }}
                >
                  <button
                    style={{
                      alignItems: "center", // Centrar verticalmente
                      height: "2rem",
                      width: "55%",
                      // position: "relative", // Asegúrate de que la posición sea 'relative' para manejar 'top' y 'right'
                      // top: "20px", // Ajusta esta propiedad para la distancia desde la parte superior
                      // right: "-100px", // Ajusta esta propiedad para la distancia desde la derecha

                      background: "#28b463", // Cambiar el color de fondo

                      color: "#fff", // Cambiar el color del texto
                      border: "none", // Eliminar el borde
                      borderRadius: "5px", // Agregar bordes redondeados
                      cursor: "pointer", // Cambiar el cursor al pasar el mouse
                      fontSize: "16px", // Cambiar el tamaño del texto
                      //display: "flex", // Usar flexbox para alinear contenido
                    }}
                    onClick={() => this.handleButtonClick()} // Reemplaza esta función con la acción deseada
                  >
                    <FontAwesomeIcon
                      icon={faSearch}
                      size="1x"
                      style={{ marginRight: "10px" }}
                    />
                    Filtrar
                  </button>
                </div>

                <div
                  style={{
                    width: "20%",

                    // justifyContent:"end",
                    // alignItems: "end", // Centrar verticalmente
                    // height:"4rem",
                  }}
                >


                  <button
                    style={{
                      // position: "relative",
                      // top: "20px",
                      // right: "-100px",
                      width: "55%",
                      height: "2rem",
                      background: "#D9534F",
                      color: "#fff",
                      border: "none",
                      borderRadius: "5px",
                      cursor: "pointer",
                      fontSize: "16px",

                      alignItems: "center",
                    }}
                    onClick={() => this.clearFilters()}
                  >
                    <FontAwesomeIcon
                      icon={faTimesCircle}
                      size="1x"
                      style={{ marginRight: "10px" }}
                    />
                    Limpiar
                  </button>
                </div>
              </div>

            </div>
            <div>
              {this.state.dataFiltered && (<table
                  style={{ width: "250px" }}
                  className="display col-12"
                  ref={(el) => (this.el = el)}
                ></table>

              )
                
              }

            </div>
              <Modal show={this.state.showModal} onHide={this.handleClose}>
                      <Modal.Body>
                        {this.state.icon === "warning" && (
                          <FontAwesomeIcon
                            style={{ color: "#E79B00" }}
                            icon={faExclamationTriangle}
                            size="2x"
                          />
                        )}
                        {this.state.icon === "success" && (
                          <FontAwesomeIcon
                            style={{ color: "#4CAF50" }}
                            icon={faCheckCircle}
                            size="2x"
                          />
                        )}
                        <div className="size-24 bold pb-2">{this.state.titleModal}</div>
                        <p className="size-16 pb-4 col col-10 offset-1">
                          {this.state.messageModal}
                        </p>
            
                        <div className="w-100 d-flex justify-content-between px-4 pt-4 btn-content">
                          <div
                            className="d-block btn btn-small btn-primary h-30 col col-12"
                            onClick={this.handleClose}
                          >
                            Volver
                          </div>
                        </div>
                      </Modal.Body>
                    </Modal>

          </div>


        </div>

      </div>
    );
  }
  
}

export default RegistroPorcentaje;